import type { ViewLegendComponentProps } from '@ardoq/timeline2024';
import { getLegendRows } from '@ardoq/timeline2024';
import { ViewLegendContainer } from '@ardoq/graph';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
} from '@ardoq/view-legend';

import { getActiveDiffMode } from 'scope/activeDiffMode$';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import { isInScopeDiffMode } from '../../scope/scopeDiff';

/**
 * This components depends on the rendering data state that is internal to package,
 * but it is located in ardoq-front. So this ugly hack is a way to move forward for now.
 */
export const ViewLegendComponent = ({
  legendComponentTypes,
  isLegendActive,
  orderedSelectedMilestoneFields,
  orderedSelectedPhaseFields,
}: ViewLegendComponentProps) => (
  <HeightOffset>
    {heightOffset => (
      <ViewLegendContainer heightOffset={heightOffset} visible={isLegendActive}>
        <ViewLegend
          componentTypes={legendComponentTypes}
          activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
          additionalRows={getLegendRows({
            orderedSelectedMilestoneFields,
            orderedSelectedPhaseFields,
          })}
          activeDiffMode={isInScopeDiffMode() ? getActiveDiffMode() : null}
        />
      </ViewLegendContainer>
    )}
  </HeightOffset>
);
