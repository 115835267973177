import { APIEntityType, ArdoqId, ViewIds } from '@ardoq/api-types';
import {
  getAddChildComponentMenuItem,
  getAddSelectionToScenarioMenuItems,
  getComponentNavigateToMenuItem,
  getCreateReferenceMenuItem,
  getCreateScenarioFromSelectionMenuItems,
  getDeleteComponentMenuItems,
  getDropdownDivider,
  getEditPropertiesMenuItem,
  getEditStyleMenuItem,
  getFilterMenuItem,
  getHistoryMenuItem,
  getTraversalMenuItems,
  getMergeEntitiesMenuItem,
  getToggleLockMenuItem,
  seeDetailsDrawer,
  getTraversalInferenceMenuItems,
  getLinkToLucidChartMenuItem,
} from './menuItems';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { TrackedContextMenus, getTrackingFunction } from './tracking';
import { GetContextMenuOptionsArguments } from '@ardoq/context-menu';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { checkHasWriteAccess } from './utils';
import { logError } from '@ardoq/logging';
import { hasFeature } from 'modelInterface/features/featureInterface';
import { Features } from '@ardoq/features';
import { getScenarioContextComponentContextMenu } from './scenarioContextComponentContextMenu';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { subdivisionsMenu } from './subdivisionsMenu';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { contextInterface } from 'modelInterface/contextInterface';
import activeView$ from 'streams/views/mainContent/activeView$';

const readOnlyComponentMenu = (
  componentIds: ArdoqId[],
  isViewpointMode: boolean,
  menuComponentTrackingFunction: (optionTitle: string) => void
) => {
  const componentNavigateToMenuItem = getComponentNavigateToMenuItem(
    componentIds[0],
    menuComponentTrackingFunction
  );
  const menu = isViewpointMode
    ? [
        ...getTraversalInferenceMenuItems(componentIds),
        seeDetailsDrawer(componentIds),
        ...getTraversalMenuItems(componentIds),
      ]
    : [
        ...getTraversalInferenceMenuItems(componentIds),
        ...(componentNavigateToMenuItem
          ? [componentNavigateToMenuItem, getDropdownDivider()]
          : []),
        getFilterMenuItem({
          componentId: componentIds[0],
          trackingFunction: menuComponentTrackingFunction,
        }),
      ];

  return menu.length ? menu : null;
};

type GetComponentMenuArgs = {
  componentIds: ArdoqId[];
  eventTargetModelId?: string | null;
  isViewpointMode: boolean;
  isStartInNavigator?: boolean;
  getNextTreeSelectionCandidate?: () => ArdoqId | null;
} & GetContextMenuOptionsArguments;
export const getComponentMenu = ({
  componentIds,
  eventTargetModelId,
  event,
  target,
  x,
  y,
  isViewpointMode,
  isStartInNavigator,
  getNextTreeSelectionCandidate = () => null,
}: GetComponentMenuArgs): DropdownItem[] | null => {
  if (
    eventTargetModelId &&
    !componentInterface.isComponent(eventTargetModelId)
  ) {
    logError(
      new Error('Invalid target model for component context menu'),
      'Component context menu is called on something that is not a component.'
    );
    return null;
  }

  const isScenarioContext = componentIds.some(
    componentInterface.isScenarioContextComponent
  );

  if (isScenarioContext) {
    return getScenarioContextComponentContextMenu({
      componentIds,
      target,
      isViewpointMode,
    });
  }

  const menuComponentTrackingFunction = getTrackingFunction(
    target,
    TrackedContextMenus.COMPONENT_CONTEXT_MENU
  );
  const isSingleComponent = componentIds.length === 1;
  const scenarioId = getActiveScenarioId();
  const workspaceIds = [
    ...new Set(
      [...componentIds.map(componentInterface.getWorkspaceId)].filter(
        ExcludeFalsy
      )
    ),
  ];
  const hasWriteAccess = checkHasWriteAccess({
    workspaceIds,
    componentIds,
  });
  if (!hasWriteAccess && !isSingleComponent) {
    // no context menu for multiselect with no write access except for viewpoint features
    return getTraversalMenuItems(componentIds);
  }

  if (!hasWriteAccess) {
    return readOnlyComponentMenu(
      componentIds,
      isViewpointMode,
      menuComponentTrackingFunction
    );
  }

  const hasLockedComponents = componentIds.some(componentInterface.isLocked);

  const createReferenceMenuItem = getCreateReferenceMenuItem(
    componentIds,
    event,
    x,
    y,
    menuComponentTrackingFunction,
    eventTargetModelId ?? undefined,
    isStartInNavigator
  );

  const deleteMenuItems = getDeleteComponentMenuItems(
    componentIds,
    menuComponentTrackingFunction,
    getNextTreeSelectionCandidate
  );

  const historyItem =
    isSingleComponent && hasWriteAccess
      ? getHistoryMenuItem(
          componentIds[0],
          APIEntityType.COMPONENT,
          menuComponentTrackingFunction
        )
      : null;

  const toggleLockItem = getToggleLockMenuItem(
    componentIds,
    [],
    menuComponentTrackingFunction
  );

  const scenarioMenuItems =
    hasFeature(Features.SCENARIOS_BETA) &&
    activeView$.state.mainViewId !== ViewIds.BLOCKS
      ? [
          ...getAddSelectionToScenarioMenuItems(
            componentIds,
            contextInterface.getCurrentContext()
          ),
          ...(scenarioAccessControlInterface.canCreateScenario(
            currentUserInterface.getPermissionContext()
          )
            ? getCreateScenarioFromSelectionMenuItems(
                componentIds,
                contextInterface.getCurrentContext()
              )
            : []),
          getDropdownDivider(),
        ]
      : [];

  const editPropertiesMenuItem =
    !hasLockedComponents &&
    getEditPropertiesMenuItem(componentIds, [], menuComponentTrackingFunction);

  const editStyleMenuItem = getEditStyleMenuItem(
    componentIds,
    [],
    menuComponentTrackingFunction
  );

  const addChildComponentMenuItem =
    !hasLockedComponents &&
    isSingleComponent &&
    getAddChildComponentMenuItem(
      componentIds[0],
      menuComponentTrackingFunction,
      isViewpointMode
    );
  if (isViewpointMode) {
    const menu = (
      hasLockedComponents
        ? [
            ...getTraversalInferenceMenuItems(componentIds),
            seeDetailsDrawer(componentIds),
            historyItem,
            toggleLockItem,
            (historyItem || toggleLockItem) && getDropdownDivider(),
            createReferenceMenuItem,
            scenarioMenuItems.length && getDropdownDivider(),
            ...scenarioMenuItems,
            ...getTraversalMenuItems(componentIds),
          ]
        : [
            ...getTraversalInferenceMenuItems(componentIds),
            seeDetailsDrawer(componentIds),
            editPropertiesMenuItem,
            editStyleMenuItem,
            createReferenceMenuItem,
            isSingleComponent && addChildComponentMenuItem,
            ...deleteMenuItems,
            historyItem && getDropdownDivider(),
            historyItem,
            toggleLockItem,
            scenarioMenuItems.length && getDropdownDivider(),
            ...scenarioMenuItems,
            ...getTraversalMenuItems(componentIds),
            hasFeature(Features.SUPPORT_LARGE_DATASETS) && getDropdownDivider(),
          ]
    ).filter(ExcludeFalsy);
    return menu.length > 0 ? menu : null;
  }
  const componentNavigateToMenuItem = getComponentNavigateToMenuItem(
    componentIds[0],
    menuComponentTrackingFunction
  );
  const menu = [
    ...(hasLockedComponents
      ? [
          historyItem,
          toggleLockItem,
          getDropdownDivider(),

          ...(createReferenceMenuItem
            ? [createReferenceMenuItem, getDropdownDivider()]
            : []),
        ]
      : [
          editPropertiesMenuItem,
          editStyleMenuItem,
          createReferenceMenuItem,
          addChildComponentMenuItem,
          ...(!scenarioId ? deleteMenuItems : []),
          getDropdownDivider(),

          ...(historyItem || toggleLockItem
            ? [historyItem, toggleLockItem, getDropdownDivider()]
            : []),
        ]),
    ...(scenarioId ? deleteMenuItems : scenarioMenuItems),
    ...getLinkToLucidChartMenuItem(componentIds[0]),
    ...subdivisionsMenu(componentIds, scenarioId),
    ...(isSingleComponent
      ? [
          ...(componentNavigateToMenuItem
            ? [componentNavigateToMenuItem, getDropdownDivider()]
            : []),
          getFilterMenuItem({
            componentId: componentIds[0],
            trackingFunction: menuComponentTrackingFunction,
          }),
        ]
      : [
          !hasLockedComponents &&
            getMergeEntitiesMenuItem(
              componentIds,
              APIEntityType.COMPONENT,
              menuComponentTrackingFunction
            ),
        ]),
  ].filter(ExcludeFalsy);

  return menu.length > 0 ? menu : null;
};
