import { Bounds as Rectangle, Vector } from '@ardoq/graph';
import { interp } from './math';
import type { RectangleSides } from '../types';

export const rectContains = (rect: Rectangle, pos: Vector) =>
  pos[0] >= rect[0] &&
  pos[1] >= rect[1] &&
  pos[0] <= rect[2] &&
  pos[1] <= rect[3];
export const rectIntersects = (a: Rectangle, b: Rectangle) =>
  a[0] <= b[2] && a[1] <= b[3] && a[2] >= b[0] && a[3] >= b[1];

export const rectWidth = (rect: RectangleSides) => rect[2] - rect[0];
export const rectHeight = (rect: RectangleSides) => rect[3] - rect[1];
export const rectCenter = (rect: RectangleSides): Vector => [
  (rect[0] + rect[2]) / 2,
  (rect[1] + rect[3]) / 2,
];
export const rectBloat = (
  rect: RectangleSides,
  amount: number
): RectangleSides => [
  rect[0] - amount,
  rect[1] - amount,
  rect[2] + amount,
  rect[3] + amount,
];
export const rectEnclose = (...args: Vector[]) => {
  if (args.length === 0) {
    return rectEmpty();
  }

  const ret: Rectangle = [...args[0], ...args[0]];

  for (let i = 1; i < args.length; ++i) {
    const pt = args[i];

    if (pt[0] < ret[0]) {
      ret[0] = pt[0];
    } else if (pt[0] > ret[2]) {
      ret[2] = pt[0];
    }

    if (pt[1] < ret[1]) {
      ret[1] = pt[1];
    } else if (pt[1] > ret[3]) {
      ret[3] = pt[1];
    }
  }

  return ret;
};

export const rectEncloseRects = (args: Iterable<Rectangle>) => {
  const ret: Rectangle = [+Infinity, +Infinity, -Infinity, -Infinity];

  for (const rc of args) {
    ret[0] = Math.min(ret[0], rc[0], rc[2]);
    ret[1] = Math.min(ret[1], rc[1], rc[3]);
    ret[2] = Math.max(ret[2], rc[0], rc[2]);
    ret[3] = Math.max(ret[3], rc[1], rc[3]);
  }

  return ret;
};
export const rectEmpty = (): Rectangle => [
  Number.NaN,
  Number.NaN,
  Number.NaN,
  Number.NaN,
];

export const vectorMag = (a: Vector): number => Math.hypot(...a);

export const vectorDot = (a: Vector, b: Vector): number =>
  a[0] * b[0] + a[1] * b[1];

export const rectInterp = (
  p: number,
  from: Rectangle,
  to: Rectangle,
  target: Rectangle | null
): Rectangle => {
  const ret = target ?? [0, 0, 0, 0];

  ret[0] = interp(p, from[0], to[0]);
  ret[1] = interp(p, from[1], to[1]);
  ret[2] = interp(p, from[2], to[2]);
  ret[3] = interp(p, from[3], to[3]);

  return ret;
};

/* to go in utils somewhere */
export const vectorsInterp = (
  p: number,
  from: Vector[],
  to: Vector[],
  target: Vector[] | null
): Vector[] => {
  const retLength = Math.max(from.length, to.length);
  const ret = target ?? new Array<Vector>(retLength);

  if (!target) {
    for (let i = 0; i < retLength; ++i) {
      ret[i] = [NaN, NaN];
    }
  }

  while (ret.length < retLength) {
    ret.push([NaN, NaN]);
  }

  if (ret.length > retLength) {
    ret.length = retLength;
  }

  for (let i = 0; i < Math.min(from.length, to.length); ++i) {
    ret[i][0] = interp(p, from[i][0], to[i][0]);
    ret[i][1] = interp(p, from[i][1], to[i][1]);
  }

  for (let i = from.length; i < to.length; ++i) {
    ret[i][0] = interp(p, from[from.length - 1][0], to[i][0]);
    ret[i][1] = interp(p, from[from.length - 1][1], to[i][1]);
  }

  for (let i = to.length; i < from.length; ++i) {
    ret[i][0] = interp(p, from[i][0], to[to.length - 1][0]);
    ret[i][1] = interp(p, from[i][1], to[to.length - 1][1]);
  }

  return ret;
};
