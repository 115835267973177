import { withNamespaceOrNullNamespace } from 'streams/utils/streamOperators';
import { action$, combineReducers, streamReducer } from '@ardoq/rxbeach';
import { context$ } from 'streams/context/context$';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import { combineLatest, debounceTime, switchMap } from 'rxjs';
import {
  bubbleChartXYDomains,
  getComponents,
  getDataRange,
  resolveIncludeAllDescendants,
  sortDataPointsByCluster,
} from './util';
import {
  BubbleChartDataPointModel,
  BubbleChartViewProperties,
  BubbleChartViewSettings,
  HighlightState,
} from './types';
import type { ContextShape } from '@ardoq/data-model';
import { getComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';
import {
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import {
  DEFAULT_WINDOW_CENTER,
  DEFAULT_CHART_WINDOW_STATE,
} from '@ardoq/timeline';
import { chartComponentReducers } from 'utils/charting/reducers';
import { componentInterface } from '@ardoq/component-interface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { getCurrencySymbol, isCurrencyFormat } from 'utils/numberUtils';
import { getFilteredViewSettings$ } from 'views/filteredViewSettings$';
import { ExcludeFalsy, returnZero } from '@ardoq/common-helpers';
import bubbleChartModelUpdateNotification$ from './viewModel/bubbleChartModelUpdateNotification$';
import {
  defaultBubbleChartViewSettings,
  DISPLAYED_LABELS_LIMIT,
  EMPTY_HIGHLIGHT_STATE,
} from './consts';
import resetReferenceBubbleChart from './referenceBubbleChart/resetReferenceBubbleChart';
import { ExtractStreamShape } from 'tabview/types';
import graphModel$ from 'modelInterface/graphModel$';
import { uniq } from 'lodash';
import { linkedComponentToReferenceInfo } from './referenceBubbleChart/viewModel$';
import allDescendantsReducer from 'modelInterface/components/allDescendantsReducer';
import { DEFAULT_DEBOUNCE_TIME } from 'modelInterface/consts';
import { Features, hasFeature } from '@ardoq/features';
import { getHoverStateStream } from './streams/hoverState$';
import { getFocusedStateStream } from './streams/focusState$';

const emptyState: Omit<BubbleChartViewProperties, 'viewInstanceId'> = {
  numericFields: [],
  availableIncomingReferenceTypeNames: [],
  availableOutgoingReferenceTypeNames: [],
  availableReferenceFields: [],
  availableReferencedComponentFields: [],
  isLoadingWorkspace: true,
  viewSettings: defaultBubbleChartViewSettings,
  xAxisTitle: '',
  yAxisTitle: '',
  domain: {
    x: [0, 0],
    y: [0, 0],
    radius: [0, 0],
    labeledBubblesRadius: [0, 0],
  },
  data: [],
  hoverState: EMPTY_HIGHLIGHT_STATE,
  focusState: EMPTY_HIGHLIGHT_STATE,
  ...DEFAULT_CHART_WINDOW_STATE,
};

const getFieldLabel = (fields: APIFieldAttributes[], fieldName: string) => {
  const field = fields.find(field => field.name === fieldName);

  return field && isCurrencyFormat(field.numberFormatOptions)
    ? `${field.label} (${getCurrencySymbol(field.numberFormatOptions)})`
    : field?.label || '';
};

const toViewModel = (
  context: ContextShape,
  componentId: ArdoqId,
  selectedFieldNameX: string,
  selectedFieldNameY: string,
  selectedFieldNameRadius: string
): BubbleChartDataPointModel => {
  const selectedXValue = componentInterface.getFieldValue(
    componentId,
    selectedFieldNameX
  );
  const selectedYValue = componentInterface.getFieldValue(
    componentId,
    selectedFieldNameY
  );
  const selectedRadiusValue = componentInterface.getFieldValue(
    componentId,
    selectedFieldNameRadius
  );
  return {
    cid: componentId,
    uniqueId: componentId,
    x: typeof selectedXValue === 'number' ? selectedXValue : 0,
    y: typeof selectedYValue === 'number' ? selectedYValue : 0,
    radius: typeof selectedRadiusValue === 'number' ? selectedRadiusValue : 0,
    class: componentInterface.getCssClassNames(componentId) ?? '',
    label: truncateComponentLabel({
      ...getComponentLabelParts(componentId),
      measure: returnZero,
      width: Infinity,
    }),
    isContext: context.componentId
      ? context.componentId === componentId
      : false,
  };
};
type ResetArgs = Pick<
  BubbleChartViewProperties,
  | 'numericFields'
  | 'availableIncomingReferenceTypeNames'
  | 'availableOutgoingReferenceTypeNames'
> & {
  context: ContextShape;
  viewSettings: BubbleChartViewSettings;
};
const reset = (
  previousState: BubbleChartViewProperties,
  {
    context,
    viewSettings,
    numericFields,
    availableIncomingReferenceTypeNames,
    availableOutgoingReferenceTypeNames,
  }: ResetArgs
): BubbleChartViewProperties => {
  const {
    selectedFieldNameX,
    selectedFieldNameY,
    selectedFieldNameRadius,
    showBubblesWithZeroValue: viewSettingsShowBubblesWithZeroValue,
  } = viewSettings;

  const showBubblesWithZeroValue =
    !hasFeature(Features.SIZE_ZERO_BUBBLES) ||
    viewSettingsShowBubblesWithZeroValue === undefined ||
    viewSettingsShowBubblesWithZeroValue;

  const [xField, yField, radiusField] = [
    selectedFieldNameX,
    selectedFieldNameY,
    selectedFieldNameRadius,
  ].map(field => (typeof field === 'string' ? field : ''));
  const { components } = getComponents(
    context,
    xField,
    yField,
    viewSettings.includeAllDescendants
  );
  const unsortedData = components
    .map(componentId => {
      const bubble = toViewModel(
        context,
        componentId,
        xField,
        yField,
        radiusField
      );
      const { radius } = bubble;
      return (showBubblesWithZeroValue || radius) && bubble;
    })
    .filter(ExcludeFalsy);

  const data = sortDataPointsByCluster(unsortedData);

  return {
    ...previousState,
    windowCenter: DEFAULT_WINDOW_CENTER,
    windowScale: 1.0,
    viewSettings,
    numericFields,
    availableIncomingReferenceTypeNames,
    availableOutgoingReferenceTypeNames,
    isLoadingWorkspace: !context.workspaceId,
    xAxisTitle: getFieldLabel(numericFields, xField),
    yAxisTitle: getFieldLabel(numericFields, yField),
    domain: {
      ...bubbleChartXYDomains(viewSettings, data),
      radius: getDataRange(data, 'radius'),
      labeledBubblesRadius: getDataRange(
        data.slice(0, DISPLAYED_LABELS_LIMIT),
        'radius'
      ),
    },
    data,
  };
};

const updateHoverState = (
  previousState: BubbleChartViewProperties,
  hoverState: HighlightState
): BubbleChartViewProperties => {
  return { ...previousState, hoverState };
};

const updateFocusState = (
  previousState: BubbleChartViewProperties,
  focusState: HighlightState
): BubbleChartViewProperties => {
  return { ...previousState, focusState };
};

const viewSettings$ = getViewSettingsStreamWithChanges<BubbleChartViewSettings>(
  ViewIds.BUBBLE
);

export const viewModel$ = (viewInstanceId: string) => {
  const reset$ = combineLatest([
    context$,
    getFilteredViewSettings$(viewSettings$),
    graphModel$,
    bubbleChartModelUpdateNotification$,
  ]).pipe(debounceTime(DEFAULT_DEBOUNCE_TIME));
  const resetState = (
    previousState: BubbleChartViewProperties,
    [context, viewSettings, graph]: ExtractStreamShape<typeof reset$>
  ) => {
    const numericFields =
      (context.workspaceId &&
        fieldInterface.getFieldsOfWorkspace(context.workspaceId, [
          APIFieldType.NUMBER,
        ])) ||
      [];

    const {
      currentSettings: { includeAllDescendants },
    } = viewSettings;

    const { sourceMap, targetMap } = graph;
    const { componentId, workspaceId } = context;

    const contextComponents = componentId
      ? [componentId]
      : componentInterface.getRootComponents(workspaceId);

    const resolvedIncludeAllDescendants = resolveIncludeAllDescendants(
      includeAllDescendants
    );
    const referenceBubbleChartStartSet =
      resolvedIncludeAllDescendants === 'none'
        ? contextComponents
        : resolvedIncludeAllDescendants === 'all'
          ? contextComponents.reduce(allDescendantsReducer, [])
          : contextComponents.flatMap(contextComponentId => [
              contextComponentId,
              ...componentInterface.getChildren(contextComponentId),
            ]);

    const [incomingLinkedComponents, outgoingLinkedComponents] = [
      targetMap,
      sourceMap,
    ].map(referenceMap =>
      referenceBubbleChartStartSet.flatMap(
        componentId => referenceMap.get(componentId) ?? []
      )
    );

    const [incomingReferences, outgoingReferences] = [
      incomingLinkedComponents,
      outgoingLinkedComponents,
    ].map(linkedComponents =>
      linkedComponents.map(linkedComponentToReferenceInfo).filter(ExcludeFalsy)
    );

    const [
      availableIncomingReferenceTypeNames,
      availableOutgoingReferenceTypeNames,
    ] = [incomingReferences, outgoingReferences].map(references =>
      uniq(references?.map(({ referenceTypeName }) => referenceTypeName))
    );

    return viewSettings.currentSettings.fieldSource === 'byComponent'
      ? reset(previousState, {
          context,
          viewSettings: viewSettings.currentSettings,
          numericFields,
          availableIncomingReferenceTypeNames,
          availableOutgoingReferenceTypeNames,
        })
      : resetReferenceBubbleChart(previousState, {
          viewSettings: viewSettings.currentSettings,
          numericFields,
          availableIncomingReferenceTypeNames,
          availableOutgoingReferenceTypeNames,
          incomingReferences,
          outgoingReferences,
        });
  };
  const resetReducer = streamReducer(reset$, resetState);
  const hoverStateReducer = streamReducer(
    reset$.pipe(switchMap(() => getHoverStateStream(viewInstanceId))),
    updateHoverState
  );

  const focusStateReducer = streamReducer(
    reset$.pipe(switchMap(() => getFocusedStateStream(viewInstanceId))),
    updateFocusState
  );

  return action$.pipe(
    withNamespaceOrNullNamespace(viewInstanceId),
    combineReducers<BubbleChartViewProperties>(
      { ...emptyState, viewInstanceId },
      [
        ...chartComponentReducers<BubbleChartViewProperties>(),
        resetReducer,
        hoverStateReducer,
        focusStateReducer,
      ]
    )
  );
};
