import { range } from 'lodash';
import {
  BodyCellModel,
  ComponentMatrixGroup,
  ComponentMatrixItem,
  ComponentMatrixViewModel,
  HeaderCellModel,
  RowElementModel,
} from '../types';
import { countLeaves } from '../util';
import { logError } from '@ardoq/logging';
import { COMPONENT_HIERARCHY_PADDING } from '../consts';
import { componentInterface } from '@ardoq/component-interface';
import { truncateComponentLabel, getComponentLabelParts } from '@ardoq/graph';
import { returnZero } from '@ardoq/common-helpers';
import { getIconColor } from '@ardoq/dependency-map';

const isFirstChildOfCollapsedGroup = (
  group: ComponentMatrixGroup,
  rootGroups: Map<string | null, ComponentMatrixGroup>
) => {
  if (!group.isHidden) {
    return false;
  }
  if (group.address.length === 1) {
    return true;
  }
  let currentChildren = rootGroups;
  const remainingAddress = [...group.address];
  let isCollapsedGroup = false;
  while (remainingAddress.length) {
    if (
      isCollapsedGroup &&
      currentChildren.size &&
      [...currentChildren.keys()][0] !== remainingAddress[0]
    ) {
      return false;
    }
    const currentGroupKey = remainingAddress.shift() || null;
    const currentGroup = currentChildren.get(currentGroupKey);
    if (!currentGroup) {
      logError(
        new Error(
          'Invalid Component Matrix view model: child group not found at given address.'
        ),
        null,
        {
          address: group.address,
          currentGroupKey,
        }
      );
      return false;
    }
    currentChildren = currentGroup.children;
    isCollapsedGroup = isCollapsedGroup || !currentGroup.isExpanded;
  }
  return true;
};
const getCellSpan = (
  group: ComponentMatrixGroup,
  rootGroups: Map<string | null, ComponentMatrixGroup>
) => {
  if (!group) {
    logError(Error('Invalid ViewModel in ComponentMatrix body.'));
    return 0;
  }
  if (!group.isHidden) {
    return 1;
  }
  if (!isFirstChildOfCollapsedGroup(group, rootGroups)) {
    // only the first child of a collapsed group has a cell span. second, third, and nth children are fully hidden and thus have a zero cell span.
    return 0;
  }
  let currentChildren = rootGroups;

  // traverse address until you find the collapsed parent.
  for (let ii = 0; ii < group.address.length; ii++) {
    const currentAddressPart = group.address[ii];
    const currentGroup = currentChildren.get(currentAddressPart)!;
    if (!currentGroup.isExpanded) {
      return countLeaves(0, currentGroup);
    }
    currentChildren = currentGroup.children;
  }
  return 1;
};

interface RowsModelReducerState {
  viewModel: ComponentMatrixViewModel;
  leafColumnCount: number;
  itemMap: Map<string, ComponentMatrixItem[]>;
  rows: RowElementModel[];
  pendingHeaderCells: HeaderCellModel[];
  parentIndexAddress: number[];
}

const rowsModelReducer = (
  state: RowsModelReducerState,
  currentRow: [string | null, ComponentMatrixGroup],
  currentRowIndex: number,
  currentEntries: [string | null, ComponentMatrixGroup][]
): RowsModelReducerState => {
  const [currentKey, currentGroup] = currentRow;
  const indexAddressKey =
    currentKey === COMPONENT_HIERARCHY_PADDING ? NaN : currentRowIndex;
  const indexAddress = [...state.parentIndexAddress, indexAddressKey];
  if (currentGroup.children.size) {
    state.pendingHeaderCells.push({
      value: currentKey,
      span: [...currentGroup.children.values()].reduce(countLeaves, 0),
      group: currentGroup,
      indexAddress,
      siblingCount: currentEntries.length,
    });
    const parentIndexAddress = state.parentIndexAddress;
    const newState = [...currentGroup.children.entries()].reduce(
      rowsModelReducer,
      {
        ...state,
        parentIndexAddress: indexAddress,
      }
    );
    newState.parentIndexAddress = parentIndexAddress;
    return newState;
  }
  // leaf level
  const rowIndex = state.rows.length;
  const headerCells: HeaderCellModel[] = [
    ...state.pendingHeaderCells,
    {
      value: currentKey,
      span: 1,
      group: currentGroup,
      indexAddress,
      siblingCount: currentEntries.length,
    },
  ];

  const bodyCells: BodyCellModel[] = [];
  let columnIndex = 0;
  const leafColumns = [
    ...state.viewModel.columnsByDepth[
      state.viewModel.columnsByDepth.length - 1
    ].values(),
  ];
  const leafRows = [
    ...state.viewModel.rowsByDepth[
      state.viewModel.rowsByDepth.length - 1
    ].values(),
  ];
  const row = leafRows[rowIndex];
  const rowSpan = getCellSpan(row, state.viewModel.rows);
  while (columnIndex < state.leafColumnCount) {
    const column = leafColumns[columnIndex];
    const columnSpan = getCellSpan(column, state.viewModel.columns);

    const componentIds = [
      ...new Set(
        range(columnSpan)
          .flatMap(mergedColumnIndex =>
            range(rowSpan).flatMap(
              mergedRowIndex =>
                state.itemMap.get(
                  `${columnIndex + mergedColumnIndex},${
                    rowIndex + mergedRowIndex
                  }`
                ) || []
            )
          )
          .map(item => item.componentId)
      ),
    ];
    if (rowSpan && columnSpan) {
      bodyCells.push({
        items: componentIds.map(componentId => ({
          componentId,
          className:
            componentInterface.getCssClassNames(componentId, {
              useAsBackgroundStyle: true,
            }) ?? '',
          iconColor: getIconColor(componentId, componentId, false),
          componentLabel: truncateComponentLabel({
            ...getComponentLabelParts(componentId),
            width: Infinity,
            measure: returnZero,
          }),
        })),
        columnIndex,
        columnSpan,
        rowSpan,
      });
    }
    columnIndex += columnSpan;
  }
  state.rows.push({ headerCells, bodyCells });

  if (state.pendingHeaderCells.length) {
    state.pendingHeaderCells = [];
  }

  return state;
};
const getItemMap = (viewModel: ComponentMatrixViewModel) => {
  const entries: [string, ComponentMatrixItem][] = viewModel.items.map(item => [
    `${itemGroupIndex(
      item.address.columnValues,
      viewModel.columns
    )},${itemGroupIndex(item.address.rowValues, viewModel.rows)}`,
    item,
  ]);
  const result = new Map<string, ComponentMatrixItem[]>();
  entries.forEach(([address, item]) => {
    const mapEntry =
      result.get(address) || result.set(address, []).get(address)!;
    mapEntry.push(item);
  });
  return result;
};

const getGroupIndex = (
  groups: Map<string | null, ComponentMatrixGroup>,
  groupValue: string | null
) =>
  [...groups.values()]
    .slice(0, [...groups.keys()].indexOf(groupValue))
    .reduce(countLeaves, 0);

const itemGroupIndex = (
  address: (string | null)[],
  groups: Map<string | null, ComponentMatrixGroup>
) => {
  let groupValue = address[0];
  let groupIndex = getGroupIndex(groups, groupValue);
  let currentGroup = groups.get(groupValue)!;
  let remainingAddress = address.slice(1);
  while (remainingAddress.length) {
    groupValue = remainingAddress[0];
    groupIndex += getGroupIndex(currentGroup.children, groupValue);
    currentGroup = currentGroup.children.get(groupValue)!;
    remainingAddress = remainingAddress.slice(1);
  }
  return groupIndex;
};

const getRowModels = (viewModel: ComponentMatrixViewModel) =>
  viewModel.columnsByDepth.length === 0
    ? []
    : [...viewModel.rows.entries()].reduce(rowsModelReducer, {
        viewModel: viewModel,
        leafColumnCount:
          viewModel.columnsByDepth[viewModel.columnsByDepth.length - 1].length,
        itemMap: getItemMap(viewModel),
        rows: [],
        pendingHeaderCells: [],
        parentIndexAddress: [],
      }).rows;
export default getRowModels;
