import { APIOrganizationUser } from '@ardoq/api-types';
import { DoqType } from '@ardoq/doq';
import { Link } from '@ardoq/typography';
import listOrgAdmins from 'components/AppMainSidebar/ListOrgAdmins';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

interface NoWorkspacePermissionDialogBodyProps {
  workspaceName: string | null;
  orgAdmins: APIOrganizationUser[];
}

const NoWorkspacePermissionDialogBody = ({
  workspaceName,
  orgAdmins,
}: NoWorkspacePermissionDialogBodyProps) => {
  return (
    <>
      <div>Workspace: {workspaceName}</div>
      <div>
        <Link
          typography="text2"
          hideIcon
          onClick={() => listOrgAdmins(orgAdmins)}
        >
          Contact your admin
        </Link>{' '}
        to get Write access.
      </div>
    </>
  );
};

export const noWorkspacePermissionDialogConfig = (
  workspaceId: string | null
) => {
  const workspaceName = workspaceId
    ? workspaceInterface.getWorkspaceName(workspaceId)
    : '';
  const orgAdmins = orgUsersInterface.getOrgAdmins();
  return {
    title: 'You don’t have workspace permission',
    subtitle:
      'You don’t have Write access to the workspace this component belongs to.',
    text: (
      <NoWorkspacePermissionDialogBody
        workspaceName={workspaceName}
        orgAdmins={orgAdmins}
      />
    ),
    doqType: DoqType.LOCKED,
  };
};
