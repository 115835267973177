import { APIViewpointAttributes } from '@ardoq/api-types';
import { Observable, combineLatest, map } from 'rxjs';
import { traversalState$ } from 'streams/traversals$';
import { loadedState$ } from 'loadedState/loadedState$';
import { isSingleTraversalState } from './isSingleTraversalState';
import { startWith, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';

export type OpenedViewpointData = {
  openedViewpoint: APIViewpointAttributes | null;
  isViewpointSavable: boolean;
};
export const openedViewpointData$: Observable<OpenedViewpointData> =
  combineLatest({
    traversalState: traversalState$,
    loadedState: loadedState$,
  }).pipe(
    map(({ traversalState, loadedState }) => {
      if (!isSingleTraversalState(loadedState)) {
        return {
          openedViewpoint: null,
          isViewpointSavable: false,
        };
      }

      const traversalId = loadedState[0].traversalId;
      const traversal = traversalId ? traversalState.byId[traversalId] : null;

      return {
        openedViewpoint: traversal || null,
        isViewpointSavable: true,
      };
    }),
    startWith({
      openedViewpoint: null,
      isViewpointSavable: false,
    }),
    distinctUntilChanged(isEqual)
  );
