import type { Rectangle, Vector } from '@ardoq/graph';
import {
  BlocksViewGraph,
  BlocksViewLink,
  BlocksViewNode,
  Controller,
  InteractionMode,
  Interactions,
} from '../types';
import { BLOCKS_VIEW_FIT_PADDING } from '../consts';
import { rectCenter, rectHeight, rectWidth } from '../misc/geometry';
import { isGroup } from '../viewModel$/util';
import { LayoutType, applyLayout, saveLayout } from '../layout/layout';
import { createProjection } from '../misc/projection';
import { debounce, noop } from 'lodash';
import {
  showPopoverAt,
  SimpleTextPopover,
  PopoverPlacement,
} from '@ardoq/popovers';
import {
  editCanUndo,
  editFlipHorz,
  editFlipVert,
  editReset,
  editRotate,
  editUndo,
} from '../layout/edit';
import createNodeSelection from './createNodeSelection';
import { renderGraph } from '../visual/visual';
import { colors } from '@ardoq/design-tokens';
import UrlFieldValueList from 'tabview/graphViews/UrlFieldValueList';

export const createController = (
  canvas: HTMLCanvasElement,
  graph: BlocksViewGraph,
  viewInstanceId: string,
  appRefresh: () => void
): Controller => {
  const projection = createProjection();

  projection.adjustToViewport(
    [
      canvas.offsetLeft,
      canvas.offsetTop,
      canvas.offsetLeft + canvas.offsetWidth,
      canvas.offsetTop + canvas.offsetHeight,
    ],
    window.devicePixelRatio
  );

  /* interaction mode */

  const setInteractionMode = (value: InteractionMode) => {
    if (value !== interactionMode) {
      interactionMode = value;
      appRefresh();
    }
  };
  let interactionMode = InteractionMode.None;

  /* interactions */

  const attach = (value: Interactions) => {
    if (interactions && interactions !== value) {
      interactions.detach();
    }

    interactions = value;
    interactions.attach();
  };

  const detach = () => {
    if (interactions) {
      interactions.detach();
      interactions = undefined;
    }
  };

  const viewActionsConfigs = () => {
    return interactions ? interactions.viewActionsConfigs() : [];
  };

  let interactions: Interactions | undefined; // = undefined;

  /* actions */

  const fit = (rc: Rectangle) => {
    projection.adjustToRect(rc, BLOCKS_VIEW_FIT_PADDING);
    renderCanvas();
  };

  const zoomIn = () => zoomTo(rectCenter(projection.window()), 1.1);
  const zoomOut = () => zoomTo(rectCenter(projection.window()), 0.9);
  const zoomTo = (wd: Vector, value: number) => {
    projection.zoomToPoint(wd, projection.scale() * value);
    renderCanvas();
  };

  const expandAll = () => setOpenAll(true);
  const collapseAll = () => setOpenAll(false);
  const setOpenAll = (value: boolean) => {
    const doit = (node: BlocksViewNode) => {
      if (isGroup(node)) {
        if (node.parent) {
          node.open = value;
        }

        for (const child of node.children!) {
          doit(child);
        }
      }
    };

    const wd = rectCenter(projection.window());
    const vp = rectCenter(projection.viewport());

    doit(graph.root);
    applyLayout(graph);
    projection.adjustToPoint(wd, vp);
    renderCanvas();
  };

  const expand = (node: BlocksViewNode) => setOpen(node, true);
  const collapse = (node: BlocksViewNode) => setOpen(node, false);
  const setOpen = (node: BlocksViewNode, value: boolean) => {
    const vp = projection.toViewport([node.bounds[0], node.bounds[1]]);

    node.open = value;
    applyLayout(graph);
    projection.adjustToPoint([node.bounds[0], node.bounds[1]], vp);
    renderCanvas();
  };

  const rotate = (groups: Iterable<BlocksViewNode>) => {
    editRotate(groups);
    saveLayout(graph.root);
    applyLayout(graph);
    renderCanvas();
  };

  const flipHorizontal = (groups: Iterable<BlocksViewNode>) => {
    editFlipHorz(groups);
    saveLayout(graph.root);
    applyLayout(graph);
    renderCanvas();
  };

  const flipVertical = (groups: Iterable<BlocksViewNode>) => {
    editFlipVert(groups);
    saveLayout(graph.root);
    applyLayout(graph);
    renderCanvas();
  };

  const resetLayout = (
    groups: Iterable<BlocksViewNode>,
    layoutType: LayoutType
  ) => {
    editReset(groups, layoutType);
    saveLayout(graph.root);
    applyLayout(graph);
    renderCanvas();
  };

  const undo = () => {
    if (editCanUndo()) {
      editUndo();
      saveLayout(graph.root);
      applyLayout(graph);
      renderCanvas();
    }
  };

  const renderCanvas = () => {
    if (interactions && animationFrameId === 0) {
      animationFrameId = window.requestAnimationFrame(() => {
        animationFrameId = 0;

        // ideally, I'd do this
        projection.adjustToViewport(
          [
            canvas.offsetLeft,
            canvas.offsetTop,
            canvas.offsetLeft + canvas.offsetWidth,
            canvas.offsetTop + canvas.offsetHeight,
          ],
          window.devicePixelRatio
        );

        const viewport = projection.viewport();
        canvas.width = rectWidth(viewport);
        canvas.height = rectHeight(viewport);

        // using an opaque rendering context is faster, but means that we
        // must clear the invalid region before each render

        const ctx = canvas.getContext('2d', { alpha: false })!;
        ctx.resetTransform();
        ctx.fillStyle = colors.white; // document.activeElement === canvas ? 'white' : '#f8f8f8';
        ctx.fillRect(
          viewport[0],
          viewport[1],
          rectWidth(viewport),
          rectHeight(viewport)
        );

        if (interactions)
          if (interactions && !interactions.renderCanvas()) {
            renderCanvas();
          }
      });
    }
  };

  const createExportCanvas = () => {
    const { width, height } = canvas;
    const result = new OffscreenCanvas(width, height);
    const exportContext = result.getContext('2d', { alpha: false });
    if (!exportContext) {
      return null;
    }
    const [left, top] = projection.viewport();
    exportContext.fillStyle = colors.white;
    exportContext.fillRect(left, top, width, height);
    exportContext.setTransform(projection.matrix());
    renderGraph(graph, projection.window(), 'export', null, exportContext);
    return result;
  };

  let animationFrameId = 0;

  const showTooltip = debounce((tooltip: string, wd: Vector) => {
    if (tooltip) {
      const { left, top } = canvas.getBoundingClientRect()!;

      hideTooltip = showPopoverAt({
        content: <SimpleTextPopover text={tooltip} />,
        position: [wd[0] + left, wd[1] + top],
        placement: PopoverPlacement.BOTTOM,
      });
    }
  }, 1000);
  let hideTooltip = noop;

  const cancelTooltip = () => {
    showTooltip.cancel(); // cancel any debounces
    hideTooltip(); // hide any open tooltip
    hideTooltip = noop; // make sure that hiding again doesn't do anything
  };

  const showNodeTooltip = (node: BlocksViewNode, wd: Vector) => {
    showTooltip(node.labels[0], projection.toViewport(wd));
  };

  const showUrlPopover = ({ urlFieldValues }: BlocksViewNode, wd: Vector) => {
    if (!urlFieldValues?.length) {
      return;
    }
    const [viewportX, viewportY] = projection.toViewport(wd);
    const { left, top } = canvas.getBoundingClientRect()!;
    hideTooltip = showPopoverAt({
      content: (
        <UrlFieldValueList urlFieldValues={urlFieldValues} element={canvas} />
      ),
      position: [viewportX + left, viewportY + top],
      placement: PopoverPlacement.BOTTOM,
      acceptsMouse: true,
    });
  };
  const showLinkTooltip = (link: BlocksViewLink, wd: Vector) => {
    if (!link.labels) {
      return;
    }

    let tooltip = '';

    for (const label of link.labels) {
      tooltip = `${tooltip} ${label}\n`;
    }

    showTooltip(tooltip, projection.toViewport(wd));
  };

  return {
    setInteractionMode,
    getInteractionMode: () => interactionMode,
    viewActionsConfigs,

    attach,
    detach,

    fit,
    zoomTo,
    zoomIn,
    zoomOut,

    expandAll,
    collapseAll,
    setOpenAll,

    expand,
    collapse,
    setOpen,

    rotate,
    flipHorizontal,
    flipVertical,
    resetLayout,

    undo,

    renderCanvas,
    createExportCanvas,

    showNodeTooltip,
    showLinkTooltip,
    showUrlPopover,
    cancelTooltip,

    selection: createNodeSelection(),
    canvas,
    projection,
    graph,
    viewInstanceId,
  };
};
