import styled, { css } from 'styled-components';
import { horizontalSpacing } from 'tabview/pagesView/constants';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import { ArdoqId } from '@ardoq/api-types';
import { ComponentRepresentation } from '@ardoq/renderers';
import { componentInterface } from '@ardoq/component-interface';
import { useRef } from 'react';
import { scrollToViewComponent } from 'tabview/pagesView/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { useOnMount } from '@ardoq/hooks';
import { classes } from '@ardoq/common-helpers';
import { colors } from '@ardoq/design-tokens';

const INDENTATION = parseInt(horizontalSpacing.MEDIUM, 10);

const ComponentRepresentationContainer = styled.div`
  margin-right: ${horizontalSpacing.SMALL};
  margin-left: ${horizontalSpacing.SMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 36px;
  width: 18px;
`;

const NavigatorItemContainer = styled.div<{
  $indentationLevel: number;
  $shouldHighlight: boolean;
}>`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  line-height: 36px;
  align-items: center;
  padding-left: ${props => props.$indentationLevel * INDENTATION}px;
  &:hover {
    background-color: ${colors.grey92};
    cursor: pointer;
  }
  ${props =>
    props.$shouldHighlight &&
    css`
      background-color: ${colors.grey92};
      cursor: pointer;
      transition: 0.2s;
    `}
`;

const ComponentName = styled.div`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  color: ${colors.grey15};
  overflow-wrap: break-word;
  max-width: 95%;
`;

interface NavigatorItemProps {
  componentId: ArdoqId;
  shouldHighlight: boolean;
  shouldDim: boolean;
  observeItem: (item: HTMLDivElement) => void;
  unobserveItem: (item: HTMLDivElement) => void;
}

const NavigatorItem = ({
  componentId,
  shouldHighlight,
  shouldDim,
  observeItem,
  unobserveItem,
}: NavigatorItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useOnMount(() => {
    if (ref.current) {
      observeItem(ref.current);
    }
    return () => {
      if (ref.current) {
        unobserveItem(ref.current);
      }
    };
  });
  const treeDepth = componentInterface.getTreeDepth(componentId);
  const componentRepresentationData = componentInterface.getRepresentationData(
    componentId
  ) ?? {
    isImage: false,
    value: null,
  };
  return (
    <NavigatorItemContainer
      ref={ref}
      $indentationLevel={treeDepth}
      $shouldHighlight={shouldHighlight}
      id={componentId}
      onClick={
        shouldDim
          ? undefined
          : () => dispatchAction(scrollToViewComponent({ componentId }))
      }
      style={shouldDim ? { opacity: 0.3 } : undefined}
    >
      <ComponentRepresentationContainer
        className={classes(
          componentInterface.getCssClassNames(componentId),
          'skipContextUpdate'
        )}
      >
        <ComponentRepresentation
          isImage={componentRepresentationData.isImage}
          value={componentRepresentationData.value}
          icon={componentRepresentationData.icon}
        />
      </ComponentRepresentationContainer>
      <ComponentName
        className={classes(
          'component',
          'component-name',
          'skipContextUpdate',
          componentInterface.getFilterColorClassNames(componentId)
        )}
      >
        {componentInterface.getAttribute(componentId, 'name')}
      </ComponentName>
    </NavigatorItemContainer>
  );
};

export default NavigatorItem;
