import { darken } from 'polished';
import { clamp } from 'lodash';
import { ConditionalFormattingState } from '@ardoq/data-model';
import { vizFilterGray } from '@ardoq/design-tokens';
import { Rectangle, Vector } from '@ardoq/graph';
import { ArrowType } from '@ardoq/api-types';
import { currentTimestamp } from '@ardoq/date-time';
import { BlocksViewLink, BlocksViewVisualState } from '../types';
import {
  BLOCKS_VIEW_LINK_MARKER_SIZE,
  BLOCKS_VIEW_HIGHLIGHT_COLOR,
  BLOCKS_VIEW_LINK_LABEL_MAXLENGTH,
  BLOCKS_VIEW_LINK_LABEL_PADDING,
  BLOCKS_VIEW_LINK_LABEL_HEIGHT,
  BLOCKS_VIEW_LINK_LABEL_COLOR,
  BLOCKS_VIEW_LINK_LABEL_FONT,
  BLOCKS_VIEW_LINK_LABEL_FILLCOLOR,
  BLOCKS_VIEW_LINK_MARKER_DARKEN,
  BLOCKS_VIEW_LINK_CORNER_RADIUS,
  BLOCKS_VIEW_LOWLIGHT,
} from '../consts';
import { CanvasRenderingContext } from './visual';
import { rectEnclose, rectIntersects } from '../misc/geometry';
import { truncateString } from '../misc/string';
import { getHoverColor } from '../misc/color';

export const setLinkRoute = (link: BlocksViewLink, route: Vector[]) => {
  if (link.route) {
    link.animation = {
      begin: currentTimestamp(),
      duration: 500,
      from: link.route,
    };
  }

  link.route = route;
  link.bounds = rectEnclose(...route);
};

export const isLinkHitVisible = (link: BlocksViewLink) => {
  return link.globalTypeId !== null;
};

export const hitTestLink = (
  link: BlocksViewLink,
  pos: Vector,
  slop: number
) => {
  if (!link.bounds || !link.route || !isLinkHitVisible(link)) {
    return false;
  }

  if (
    pos[0] < link.bounds[0] - slop ||
    pos[1] < link.bounds[1] - slop ||
    pos[0] > link.bounds[2] + slop ||
    pos[1] > link.bounds[3] + slop
  ) {
    return false;
  }

  const hitTestSegment = (a: Vector, b: Vector) => {
    /* trivial exclusion */
    if (pos[0] < Math.min(a[0], b[0]) - slop) return false;
    if (pos[0] > Math.max(a[0], b[0]) + slop) return false;
    if (pos[1] < Math.min(a[1], b[1]) - slop) return false;
    if (pos[1] > Math.max(a[1], b[1]) + slop) return false;

    if (Math.abs(a[0] - b[0]) < 1 || Math.abs(a[1] - b[1]) < 1) {
      /* not trivially excluded from a manhattan segment is an inclusion */
      return true;
    }

    /* pull out the big guns for non manhattan segment */
    const sqr = (a: number) => a * a;
    const dist2 = (v: Vector, w: Vector) => sqr(v[0] - w[0]) + sqr(v[1] - w[1]);

    const l2 = dist2(a, b);
    if (l2 === 0) {
      return dist2(pos, a); // distance to end of zero length segment
    }

    const t = clamp(
      ((pos[0] - a[0]) * (b[0] - a[0]) + (pos[1] - a[1]) * (b[1] - a[1])) / l2,
      0,
      1
    );
    const d2 = dist2(pos, [a[0] + t * (b[0] - a[0]), a[1] + t * (b[1] - a[1])]);

    return d2 < slop || d2 < sqr(slop);
  };

  let a = link.route[0];

  for (let i = 1; i < link.route.length; ++i) {
    const b = link.route[i];

    if (hitTestSegment(a, b)) {
      return true;
    }

    a = b;
  }

  return false;
};

const linkColor = ({ color, conditionalFormattingState }: BlocksViewLink) =>
  conditionalFormattingState === ConditionalFormattingState.DIM
    ? vizFilterGray
    : color;

export const drawMarker = (
  link: BlocksViewLink,
  prev: Vector,
  end: Vector,
  size: number,
  arrowType: ArrowType,
  context: CanvasRenderingContext
) => {
  const triangle = (x: number, y: number) => {
    context.moveTo(x - size, y - size);
    context.lineTo(x, y);
    context.lineTo(x - size, y + size);
  };

  const diamond = (x: number, y: number) => {
    context.moveTo(x - 2 * size, y - size);
    context.lineTo(x, y);
    context.lineTo(x - 2 * size, y + size);
    context.lineTo(x - 4 * size, y);
  };

  if (arrowType === ArrowType.NONE || size < context.lineWidth) {
    return;
  }

  context.strokeStyle = linkColor(link);

  const x = end[0];
  const y = end[1];

  context.save();
  context.setLineDash([]);
  context.translate(end[0], end[1]);
  context.rotate(Math.atan2(end[1] - prev[1], end[0] - prev[0]));
  context.translate(-end[0], -end[1]);

  switch (arrowType) {
    case ArrowType.CIRCLE:
    case ArrowType.CIRCLE_START:
      context.beginPath();
      context.ellipse(x - size / 2, y, size, size, 0, 0, 2 * Math.PI);
      context.fillStyle = darken(
        BLOCKS_VIEW_LINK_MARKER_DARKEN,
        linkColor(link)
      );
      context.fill();
      context.stroke();
      break;

    case ArrowType.BOTH:
    case ArrowType.BOTH_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x, y);
      context.lineTo(x - size, y + size);
      context.stroke();
      break;

    case ArrowType.BOTH_FILLED:
    case ArrowType.BOTH_FILLED_START:
      context.beginPath();
      triangle(x, y);
      context.closePath();
      context.fillStyle = darken(
        BLOCKS_VIEW_LINK_MARKER_DARKEN,
        linkColor(link)
      );
      context.fill();
      context.stroke();
      break;

    case ArrowType.BOTH_OUTLINED:
    case ArrowType.BOTH_OUTLINED_START:
      context.beginPath();
      triangle(x, y);
      context.closePath();
      context.fillStyle = darken(
        BLOCKS_VIEW_LINK_MARKER_DARKEN,
        linkColor(link)
      );
      context.fill();
      context.stroke();
      break;

    case ArrowType.DIAMOND:
    case ArrowType.DIAMOND_START:
      context.beginPath();
      diamond(x, y);
      context.closePath();
      context.fillStyle = darken(
        BLOCKS_VIEW_LINK_MARKER_DARKEN,
        linkColor(link)
      );
      context.fill();
      context.stroke();
      break;

    case ArrowType.DIAMOND_FILLED:
    case ArrowType.DIAMOND_FILLED_START:
      context.beginPath();
      diamond(x, y);
      context.closePath();
      context.fillStyle = darken(
        BLOCKS_VIEW_LINK_MARKER_DARKEN,
        linkColor(link)
      );
      context.fill();
      context.stroke();
      break;

    case ArrowType.TOP:
    case ArrowType.BOTTOM_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x, y);
      context.stroke();
      break;

    case ArrowType.TOP_START:
    case ArrowType.BOTTOM:
      context.beginPath();
      context.moveTo(x - size, y + size);
      context.lineTo(x, y);
      context.stroke();
      break;

    case ArrowType.ONE:
    case ArrowType.ONE_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x - size, y + size);
      context.stroke();
      break;

    case ArrowType.ONE_MANDATORY:
    case ArrowType.ONE_MANDATORY_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x - size, y + size);
      context.moveTo(x - 2 * size, y - size);
      context.lineTo(x - 2 * size, y + size);
      context.stroke();
      break;

    case ArrowType.MANY:
    case ArrowType.MANY_START:
      context.beginPath();
      context.moveTo(x - size, y);
      context.lineTo(x, y - size);
      context.moveTo(x - size, y);
      context.lineTo(x, y + size);
      context.stroke();
      break;

    case ArrowType.ONE_OR_MANY:
    case ArrowType.ONE_OR_MANY_START:
      context.beginPath();
      context.moveTo(x - size, y - size);
      context.lineTo(x - size, y + size);
      context.moveTo(x - size, y);
      context.lineTo(x, y - size);
      context.moveTo(x - size, y);
      context.lineTo(x, y + size);
      context.stroke();
      break;

    case ArrowType.ZERO_OR_ONE:
    case ArrowType.ZERO_OR_ONE_START:
      context.beginPath();
      context.ellipse(x - size / 2, y, size, size, 0, 0, 2 * Math.PI);
      context.fillStyle = darken(
        BLOCKS_VIEW_LINK_MARKER_DARKEN,
        linkColor(link)
      );
      context.fill();
      context.stroke();
      context.beginPath();
      context.moveTo(x - size * 2, y - size);
      context.lineTo(x - size * 2, y + size);
      context.stroke();
      break;

    case ArrowType.ZERO_OR_MANY:
    case ArrowType.ZERO_OR_MANY_START:
      context.beginPath();
      context.ellipse(x - size * 2, y, size, size, 0, 0, 2 * Math.PI);
      context.fillStyle = darken(
        BLOCKS_VIEW_LINK_MARKER_DARKEN,
        linkColor(link)
      );
      context.fill();
      context.stroke();
      context.beginPath();
      context.moveTo(x - size, y);
      context.lineTo(x, y - size);
      context.moveTo(x - size, y);
      context.lineTo(x, y + size);
      context.stroke();
      break;

    case ArrowType.DEFAULT_START:
      context.beginPath();
      context.moveTo(x, y - size);
      context.lineTo(x - size, y + size);
      context.stroke();
      break;

    default:
      // logWarn(Error('unrecognized arrow type'), null, { arrow });
      break;
  }

  context.restore();
};

const path = (
  route: Vector[],
  lineWidth: number,
  ctx: CanvasRenderingContext
) => {
  const square = () => {
    ctx.moveTo(...route[0]);

    for (let i = 1; i < route.length; ++i) {
      ctx.lineTo(route[i][0], route[i][1]);
    }
  };

  const mitred = () => {
    ctx.moveTo(...route[0]);

    let j = 0;

    for (let i = 1; i < route.length; ++i) {
      const v = [route[i][0] - route[j][0], route[i][1] - route[j][1]];

      if (v[0] === 0 && v[1] === 0) {
        continue;
      }

      const len = Math.hypot(v[0], v[1]);
      const r = Math.min(len / 3, BLOCKS_VIEW_LINK_CORNER_RADIUS);
      v[0] = (r * v[0]) / len;
      v[1] = (r * v[1]) / len;

      ctx.lineTo(route[j][0] + v[0], route[j][1] + v[1]);
      ctx.lineTo(route[i][0] - v[0], route[i][1] - v[1]);
      j = i;
    }

    ctx.lineTo(...route[route.length - 1]);
  };

  const rounded = () => {
    ctx.moveTo(...route[0]);

    let i0 = 0;
    let r0 = 0;

    for (let i = 1; i < route.length; ++i) {
      const v = [route[i][0] - route[i0][0], route[i][1] - route[i0][1]];

      if (v[0] === 0 && v[1] === 0) {
        continue;
      }

      const len = Math.hypot(v[0], v[1]);
      const r = Math.min(len / 3, BLOCKS_VIEW_LINK_CORNER_RADIUS);
      v[0] = (r * v[0]) / len;
      v[1] = (r * v[1]) / len;

      ctx.arcTo(
        route[i0][0],
        route[i0][1],
        route[i0][0] + v[0],
        route[i0][1] + v[1],
        Math.min(r, r0)
      );

      ctx.lineTo(route[i][0] - v[0], route[i][1] - v[1]);
      i0 = i;
      r0 = r;
    }

    ctx.lineTo(...route[route.length - 1]);
  };

  if (BLOCKS_VIEW_LINK_CORNER_RADIUS < lineWidth) {
    square();
    return;
  }

  if (BLOCKS_VIEW_LINK_CORNER_RADIUS < 4 * lineWidth) {
    mitred();
    return;
  }

  rounded();
};

export const drawLink = (
  link: BlocksViewLink,
  route: Vector[],
  lowlight: boolean,
  ctx: CanvasRenderingContext
) => {
  if (!route || route.length < 2) {
    return;
  }

  const isHover = link.visualState & BlocksViewVisualState.Hover;
  // const isSelect = link.visualState & BlocksViewVisualState.Select;
  const isHighlight = link.visualState & BlocksViewVisualState.Highlight;

  const ctxLineWidth = ctx.lineWidth;
  const lineWidth = ctx.lineWidth * (isHover ? 2 : 1);
  const markerSize = BLOCKS_VIEW_LINK_MARKER_SIZE * (isHover ? 1.5 : 1);

  ctx.beginPath();
  path(route, ctxLineWidth, ctx);

  /* possibly draw the highlight */

  const globalAlpha = lowlight ? (isHighlight ? 1 : BLOCKS_VIEW_LOWLIGHT) : 1;
  ctx.globalAlpha = globalAlpha;

  if (!lowlight && isHighlight) {
    ctx.lineWidth = 4 * lineWidth;
    ctx.strokeStyle = BLOCKS_VIEW_HIGHLIGHT_COLOR;
    ctx.setLineDash(link.dashArray);
    ctx.stroke();

    ctx.lineWidth = 4 * lineWidth;
    ctx.strokeStyle = BLOCKS_VIEW_HIGHLIGHT_COLOR;
    ctx.setLineDash([]);
    drawMarker(
      link,
      route[1],
      route[0],
      4 * markerSize,
      link.lineBeginning,
      ctx
    );
    drawMarker(
      link,
      route[route.length - 2],
      route[route.length - 1],
      4 * markerSize,
      link.lineEnding,
      ctx
    );
  }

  /* draw the line */

  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = linkColor(link);
  ctx.setLineDash(link.dashArray);
  ctx.stroke();

  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = linkColor(link);
  ctx.setLineDash([]);
  drawMarker(link, route[1], route[0], markerSize, link.lineBeginning, ctx);
  drawMarker(
    link,
    route[route.length - 2],
    route[route.length - 1],
    markerSize,
    link.lineEnding,
    ctx
  );

  /* restore the context */

  ctx.lineWidth = ctxLineWidth;
};

export const drawLinkLabel = (
  link: BlocksViewLink,
  route: Vector[],
  window: Rectangle,
  lowlight: boolean,
  ctx: CanvasRenderingContext
) => {
  const { labels } = link;
  if (!labels || !route || route.length < 2) {
    return;
  }
  const [linkLabel] = labels;
  if (!linkLabel) {
    return;
  }

  const isHover = link.visualState & BlocksViewVisualState.Hover;
  // const isSelect = link.visualState & BlocksViewVisualState.Select;
  const isHighlight = link.visualState & BlocksViewVisualState.Highlight;
  const ctxLineWidth = ctx.lineWidth;

  const drawLabel = (
    label: string,
    c: Vector,
    maxLen: number,
    horz: boolean
  ) => {
    // if the center is way too far outside the window, there's no point continuing

    ctx.save();

    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    ctx.font = BLOCKS_VIEW_LINK_LABEL_FONT;

    const text = truncateString(
      maxLen - 2 * BLOCKS_VIEW_LINK_LABEL_PADDING,
      label,
      str => ctx.measureText(str).width
    );
    const textWidth = ctx.measureText(text).width;
    const textHeight = BLOCKS_VIEW_LINK_LABEL_HEIGHT;

    // label border rect size

    const sz = [
      2 * BLOCKS_VIEW_LINK_LABEL_PADDING + (horz ? textWidth : textHeight),
      2 * BLOCKS_VIEW_LINK_LABEL_PADDING + (horz ? textHeight : textWidth),
    ];

    const rc: Rectangle = [
      c[0] - 0.5 * sz[0],
      c[1] - 0.5 * sz[1],
      c[0] + 0.5 * sz[0],
      c[1] + 0.5 * sz[1],
    ];

    if (!rectIntersects(window, rc)) {
      ctx.restore();
      return;
    }

    {
      const fill = BLOCKS_VIEW_LINK_LABEL_FILLCOLOR;

      /* border */
      const r = 0.5 * Math.min(sz[0], sz[1]);

      ctx.fillStyle = isHover ? getHoverColor(fill) : fill;

      ctx.beginPath();
      ctx.roundRect(c[0] - 0.5 * sz[0], c[1] - 0.5 * sz[1], sz[0], sz[1], r);
      ctx.fill();

      if (!lowlight && isHighlight) {
        ctx.lineWidth = 4 * (isHover ? 2 : 1) * ctxLineWidth;
        ctx.strokeStyle = BLOCKS_VIEW_HIGHLIGHT_COLOR;
        ctx.stroke();
      }

      ctx.lineWidth = 0.75 * (isHover ? 2 : 1) * ctxLineWidth;
      ctx.strokeStyle = linkColor(link);
      //     ctx.stroke();
    }

    /* if the text is less that three pels or something, don't bother */

    {
      /* label */

      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.font = BLOCKS_VIEW_LINK_LABEL_FONT;

      if (horz) {
        ctx.fillStyle = BLOCKS_VIEW_LINK_LABEL_COLOR;
        ctx.fillText(text, c[0] - 0.5 * textWidth, c[1]);
      } else {
        ctx.translate(c[0], c[1]);
        ctx.rotate(0.5 * Math.PI);
        ctx.fillStyle = BLOCKS_VIEW_LINK_LABEL_COLOR;
        ctx.fillText(text, -0.5 * textWidth, 0);
      }
    }

    ctx.restore();
  };

  /* find the longest horizontal segment */
  const mh = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];
  const mi = [0, 0];

  for (let i = 1; i < route.length; ++i) {
    const segment = [
      Math.abs(route[i][0] - route[i - 1][0]),
      Math.abs(route[i][1] - route[i - 1][1]),
    ];

    if (segment[0] > mh[0] && segment[1] === 0) {
      mh[0] = segment[0];
      mi[0] = i;
    }

    if (segment[1] > mh[1] && segment[0] === 0) {
      mh[1] = segment[1];
      mi[1] = i;
    }
  }

  if (mi[0] === 0 && mi[1] === 0) {
    return;
  }

  /* create a rect for the label */
  const horz = mh[0] > mh[1];
  const i = horz ? mi[0] : mi[1];
  const c: Vector = [
    0.5 * (route[i - 1][0] + route[i][0]),
    0.5 * (route[i - 1][1] + route[i][1]),
  ];
  const maxLen = Math.abs(route[i - 1][horz ? 0 : 1] - route[i][horz ? 0 : 1]);

  const globalAlpha = lowlight ? (isHighlight ? 1 : BLOCKS_VIEW_LOWLIGHT) : 1;
  ctx.globalAlpha = globalAlpha;
  drawLabel(
    linkLabel,
    c,
    Math.min(maxLen, BLOCKS_VIEW_LINK_LABEL_MAXLENGTH),
    horz
  );
};
