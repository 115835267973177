import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import styled from 'styled-components';
import { componentInterface } from '@ardoq/component-interface';
import { RichTextReader } from '@ardoq/rich-text-editor';
import { popoverRegistry } from '@ardoq/popovers';

const PopoverContainer = styled.div`
  max-width: 240px;
  max-height: 240px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DescriptionPopover = (el: Element | null) => {
  if (!el) {
    return null;
  }

  const componentId = el.getAttribute(COMPONENT_ID_ATTRIBUTE);

  if (!componentId) {
    return null;
  }
  const description = componentInterface.getDescription(componentId);
  if (!description) {
    return null;
  }
  return (
    <PopoverContainer>
      <RichTextReader content={description} />
    </PopoverContainer>
  );
};
export const DESCRIPTION_POPOVER_ID = 'description-popover';
popoverRegistry.set(DESCRIPTION_POPOVER_ID, DescriptionPopover);
