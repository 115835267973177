import { colors } from '@ardoq/design-tokens';
import { CreateRelationshipsLink } from '../../relationshipDiagrams/types';
import {
  BlocksViewLink,
  BlocksViewNode,
  BlocksViewVisualState,
} from '../types';
import { PARENT_CHILD_LINE_TYPE } from '@ardoq/graph';
import { referenceInterface } from '@ardoq/reference-interface';
import { lineTypeDashArrays } from 'tabview/canvasRendering/consts';

const createLink: CreateRelationshipsLink<BlocksViewLink, BlocksViewNode> = (
  graphEdge,
  nodeMap,
  referenceType
) => {
  const { modelId } = graphEdge;
  const dashArray =
    lineTypeDashArrays[referenceType?.line ?? PARENT_CHILD_LINE_TYPE];

  /*
  const linkLabel = getLabelWithFormattingForViewsNotSupportingMultilabeling({
    modelId,
    entityLabel: graphItemLabel(graphEdge) || '',
    entityType: APIEntityType.REFERENCE,
  });
*/
  const itemLabels = graphEdge.getItemLabels();
  const labels = [];

  labels.push(itemLabels?.mainLabel ?? '');

  if (itemLabels?.otherLabels) {
    for (const otherLabel of itemLabels.otherLabels) {
      const str = otherLabel[2]
        ? otherLabel[0].concat(' ', otherLabel[1])
        : otherLabel[1];

      labels.push(str.trim());
    }
  }

  const link: BlocksViewLink = {
    modelId,
    source: nodeMap.get(graphEdge.source)!,
    target: nodeMap.get(graphEdge.target)!,
    labels: labels,
    lineBeginning: graphEdge.getLineBeginning(),
    lineEnding: graphEdge.getLineEnding(),
    visualState: BlocksViewVisualState.None,
    globalTypeId: referenceInterface.getGlobalTypeId(modelId),
    dashArray,
    color:
      referenceInterface.getCssColors(modelId, {
        useAsBackgroundStyle: false,
      })?.stroke ?? colors.black,
    animation: null,
    conditionalFormattingState:
      referenceInterface.getConditionalFormattingState(modelId),
  };

  return link;
};
export default createLink;
