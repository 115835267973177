import { ModalSize, info } from '@ardoq/modal';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';
import { Link, Text } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';
import { APIOrganizationUser } from '@ardoq/api-types';

const StandardInfoDialogContentContainerStyles = styled.div`
  padding: ${s32};
`;

export const UsersWithEmails = (orgUsers: APIOrganizationUser[]) => (
  <StandardInfoDialogContentContainerStyles>
    <Stack gap="xsmall">
      {orgUsers.map(user => (
        <Text key={user.email} variant="text1">
          {user.name} (
          <Link href={`mailto:${user.email}`} hideIcon>
            {user.email}
          </Link>
          )
        </Text>
      ))}
    </Stack>
  </StandardInfoDialogContentContainerStyles>
);

const listOrgAdmins = (orgAdmins: APIOrganizationUser[]) => {
  info({
    title: 'Organization administrators',
    body: UsersWithEmails(orgAdmins),
    modalSize: ModalSize.S,
  });
};

export default listOrgAdmins;
