import styled from 'styled-components';
import type { TreemapHierarchyNode } from '../../types';
import { HIERARCHICAL_TREEMAP_FONT_SIZE } from './consts';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { colors, shadowM } from '@ardoq/design-tokens';
import { ComponentRepresentation } from '@ardoq/renderers';
import { componentInterface } from '@ardoq/component-interface';
import { IconSize } from '@ardoq/icons';

const LabelContainer = styled.div`
  display: flex;
  height: ${HIERARCHICAL_TREEMAP_FONT_SIZE + 5}px;
`;
const IconContainer = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NodeElement = styled.div`
  position: absolute;
  border: solid 1px black;
  box-shadow: ${shadowM};
  overflow: hidden;
`;
const Label = styled.label`
  margin: 0;
  font: ${HIERARCHICAL_TREEMAP_FONT_SIZE}px ${ARDOQ_DEFAULT_FONT_FAMILY};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.grey25};
`;
type HierarchicalTreemapViewNodeProperties = {
  node: TreemapHierarchyNode;
};

const HierarchicalTreemapViewNode = (
  props: HierarchicalTreemapViewNodeProperties
) => {
  const {
    x0,
    x1,
    y0,
    y1,
    value,
    children,
    data: { label, className, modelId },
  } = props.node;
  return (
    <NodeElement
      style={{ left: x0, width: x1 - x0, top: y0, height: y1 - y0 }}
      className={className ?? undefined}
    >
      <LabelContainer>
        {modelId && (
          <IconContainer>
            <ComponentRepresentation
              iconSize={HIERARCHICAL_TREEMAP_FONT_SIZE as IconSize}
              {...componentInterface.getRepresentationData(modelId)!}
            />
          </IconContainer>
        )}
        {children?.length ? (
          <>
            <Label style={{ flex: '0 1 auto' }}>{`${label}`}</Label>
            <Label style={{ flex: '1 0 auto', marginLeft: 3 }}>{`(${
              value ?? 'none'
            })`}</Label>
          </>
        ) : (
          <Label
            style={{
              whiteSpace: 'normal',
              wordBreak: 'break-all',
              overflow: 'visible',
            }}
          >{`${label} (${value ?? 'none'})`}</Label>
        )}
      </LabelContainer>
    </NodeElement>
  );
};

export default HierarchicalTreemapViewNode;
