import {
  type ILabel,
  IOrientedRectangle,
  type IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { createSvgElement } from '@ardoq/dom-utils';
import { GraphItem, ParentChildGraphEdge } from '@ardoq/graph';
import {
  visualDiffModes,
  LABEL_CLASS,
  LABEL_VERTICAL_PADDING,
  DATA_RENDER_HASH,
  LABEL_HORIZONTAL_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
} from 'yfilesExtensions/styles/consts';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { componentInterface } from '@ardoq/component-interface';
import { classes } from '@ardoq/common-helpers';
import { DiffType } from '@ardoq/data-model';
import { referenceInterface } from '@ardoq/reference-interface';
import {
  createAndAppendLabels,
  getEdgeMarkersInset,
  getMultiLabelHash,
  getOtherLabelsHeight,
  getOtherLabelsHeightModernized,
  getOtherLabelSizes,
  getOtherLabelsWidth,
} from './labelUtils';
import { OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING } from './consts';
import { measureLabelElement } from 'yfilesExtensions/styles/measureLabels';
import {
  COMPONENT_ID_ATTRIBUTE,
  REFERENCE_ID_ATTRIBUTE,
} from '@ardoq/global-consts';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import {
  LEGACY_FORMATTING_VALUE_HEIGHT_NODE,
  measureNodeLabelWidth,
  measureNodeLabelWidthModernized,
} from './legacyLabelUtils';
import {
  MODERNIZED_BLOCK_DIAGRAM_REFERENCE_LABEL_HORIZONTAL_PADDING,
  MODERNIZED_BLOCK_DIAGRAM_REFERENCE_LABEL_VERTICAL_PADDING,
} from '../modernized/consts';

type LabelMetaProperties = {
  modelId: string;
  isComponent: boolean;
  isVisualDiffMode: boolean;
  isPlaceholder: boolean;
  isReference: boolean;
  isParentChildReference: boolean;
  isGroup: boolean;
};
const getLabelMetaProperties = (graphItem: GraphItem): LabelMetaProperties => {
  const modelId = dataModelId(graphItem);
  const isComponent = componentInterface.isComponent(modelId);
  const diffType = graphItem.getVisualDiffType();
  const isVisualDiffMode = visualDiffModes.has(diffType);
  const isPlaceholder = diffType === DiffType.PLACEHOLDER;
  const isReference = referenceInterface.isReference(modelId);
  const isParentChildReference = graphItem instanceof ParentChildGraphEdge;
  const isGroup = graphItem.isGroup();
  return {
    modelId,
    isComponent,
    isVisualDiffMode,
    isPlaceholder,
    isReference,
    isParentChildReference,
    isGroup,
  };
};

type GetLabelContainerArgs = {
  container: SVGGElement;
  layout: IOrientedRectangle;
  isParentChildReference: boolean;
  modelId: string;
  isComponent: boolean;
  isReference: boolean;
  isGroup: boolean;
  isVisualDiffMode: boolean;
  isPlaceholder: boolean;
};
const enhanceLabelContainer = ({
  container,
  layout,
  isParentChildReference,
  modelId,
  isComponent,
  isGroup,
  isVisualDiffMode,
  isPlaceholder,
  isReference,
}: GetLabelContainerArgs) => {
  container.setAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE, modelId);
  const classNames = classes(
    LABEL_CLASS,
    isComponent
      ? 'component'
      : isGroup
        ? 'edgeGroup'
        : isParentChildReference
          ? 'parent-child-reference'
          : 'integration',
    isVisualDiffMode && 'label-visual-diff-mode',
    isPlaceholder && 'visual-diff-placeholder-label'
  );
  container.setAttribute('class', classNames);

  const entityIdAttribute = isComponent
    ? COMPONENT_ID_ATTRIBUTE
    : isReference
      ? REFERENCE_ID_ATTRIBUTE
      : null;

  if (entityIdAttribute) {
    container.setAttribute(entityIdAttribute, modelId);
  }

  container.style.pointerEvents = modelId ? '' : 'none';

  // set transform origin
  const centerTransformOrigin = `${layout.width / 2}px ${
    layout.height / 4
  }px 0px`;
  container.setAttribute('transform-origin', centerTransformOrigin);
};

const renderLabels = (
  container: SVGGElement,
  label: ILabel,
  hash: string,
  isModern: boolean
) => {
  container.innerHTML = '';
  container.setAttribute(DATA_RENDER_HASH, hash);

  const graphItem: GraphItem | null = label.owner?.tag;
  if (!graphItem) {
    return null;
  }

  const layout = label.layout;

  const {
    modelId,
    isComponent,
    isVisualDiffMode,
    isPlaceholder,
    isReference,
    isParentChildReference,
    isGroup,
  } = getLabelMetaProperties(graphItem);

  // set attributes and classes on label container
  enhanceLabelContainer({
    container,
    layout,
    isParentChildReference,
    modelId,
    isComponent,
    isReference,
    isGroup,
    isVisualDiffMode,
    isPlaceholder,
  });

  // we measure the markers and add "layout padding" to the label. The layout
  // accounts for the padding in getPreferredSize
  const edgeMarkersInset = getEdgeMarkersInset(
    modelId,
    isReference,
    isParentChildReference
  );
  const mainLabelFont =
    isReference || isParentChildReference
      ? isModern
        ? MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT
        : BLOCK_DIAGRAM_EDGE_LABEL_FONT
      : isModern
        ? MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT
        : BLOCK_DIAGRAM_NODE_LABEL_FONT;

  createAndAppendLabels({
    graphItem,
    container,
    layout,
    edgeMarkersInset,
    isPlaceholder,
    isTransparentized: graphItem.isTransparentized,
    mainLabelFont,
    isModern,
  });
};

class MultiLabelStyle extends LabelStyleBase {
  static Modern = new MultiLabelStyle(true);
  static Classic = new MultiLabelStyle(false);
  private constructor(private isModern: boolean) {
    super();
  }
  override createVisual(context: IRenderContext, label: ILabel) {
    const hash = getMultiLabelHash(label);

    const g = createSvgElement('g');
    renderLabels(g, label, hash, this.isModern);

    const transform = LabelStyleBase.createLayoutTransform(
      context,
      label.layout,
      true
    );
    transform.applyTo(g);

    return new SvgVisual(g);
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ) {
    const container = (oldVisual as SvgVisual).svgElement as SVGGElement;
    const oldHash = container.getAttribute(DATA_RENDER_HASH);
    const newHash = getMultiLabelHash(label);

    if (oldHash !== newHash) {
      renderLabels(container, label, newHash, this.isModern);
    }

    const transform = LabelStyleBase.createLayoutTransform(
      context,
      label.layout,
      true
    );
    transform.applyTo(container);
    return oldVisual;
  }

  protected override getPreferredSize(label: ILabel): Size {
    const graphNode: GraphItem | null = label.owner?.tag;
    if (!graphNode) {
      return Size.EMPTY;
    }

    const modelId = dataModelId(graphNode);
    const isReference = referenceInterface.isReference(modelId);
    const isParentChildReference = graphNode instanceof ParentChildGraphEdge;

    const edgeMarkersInsets = this.isModern
      ? 0
      : getEdgeMarkersInset(modelId, isReference, isParentChildReference) * 2;
    const isEdge = isReference || isParentChildReference;
    const addedLayoutWidth = this.isModern
      ? isEdge
        ? MODERNIZED_BLOCK_DIAGRAM_REFERENCE_LABEL_HORIZONTAL_PADDING
        : 0
      : LABEL_HORIZONTAL_PADDING +
        OTHER_LABEL_TEXT_ELEMENT_HORIZONTAL_PADDING * 2 +
        edgeMarkersInsets;
    const nameLabelFont = isEdge
      ? this.isModern
        ? MODERNIZED_BLOCK_DIAGRAM_EDGE_LABEL_FONT
        : BLOCK_DIAGRAM_EDGE_LABEL_FONT
      : this.isModern
        ? MODERNIZED_BLOCK_DIAGRAM_NODE_LABEL_FONT
        : BLOCK_DIAGRAM_NODE_LABEL_FONT;
    const itemLabels = graphNode.getItemLabels();

    if (!itemLabels) {
      return Size.EMPTY;
    }

    const nameLabelText = itemLabels.mainLabel;

    const nameLabelSize = nameLabelText
      ? measureLabelElement(nameLabelText, nameLabelFont)
      : Size.ZERO;

    const totalNameLabelHeight =
      nameLabelSize.height +
      (this.isModern ? 0 : LABEL_VERTICAL_PADDING + edgeMarkersInsets);

    const { otherLabels, legacyTruncatedFieldValueAndLabelForNode } =
      itemLabels;

    const shouldRenderOtherLabels =
      otherLabels?.length && !(this.isModern && !isEdge);
    if (!shouldRenderOtherLabels) {
      const legacyFormattingHeight = legacyTruncatedFieldValueAndLabelForNode
        ? LEGACY_FORMATTING_VALUE_HEIGHT_NODE
        : 0;
      const legacyFormattingWidth = legacyTruncatedFieldValueAndLabelForNode
        ? this.isModern
          ? measureNodeLabelWidthModernized(
              legacyTruncatedFieldValueAndLabelForNode
            )
          : measureNodeLabelWidth(legacyTruncatedFieldValueAndLabelForNode)
        : 0;
      const height =
        totalNameLabelHeight +
        (this.isModern
          ? isEdge
            ? MODERNIZED_BLOCK_DIAGRAM_REFERENCE_LABEL_VERTICAL_PADDING
            : 0
          : legacyFormattingHeight);
      return new Size(
        Math.max(nameLabelSize.width, legacyFormattingWidth) + addedLayoutWidth,
        height
      );
    }

    const otherLabelSizes = otherLabels
      ? getOtherLabelSizes(otherLabels, this.isModern)
      : [];
    const height =
      totalNameLabelHeight +
      (this.isModern
        ? getOtherLabelsHeightModernized(otherLabelSizes)
        : getOtherLabelsHeight(otherLabelSizes));
    return new Size(
      Math.max(
        nameLabelSize.width + addedLayoutWidth,
        getOtherLabelsWidth(otherLabelSizes) + edgeMarkersInsets
      ),
      height
    );
  }
}

export default MultiLabelStyle;
