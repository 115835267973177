import { createElement } from 'react';
import styled, { css } from 'styled-components';
import {
  ILabel,
  INode,
  IRenderContext,
  LabelStyleBase,
  Size,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import { ExcludeFalsy, classes } from '@ardoq/common-helpers';
import { IconName } from '@ardoq/icons';
import { MaterialIconsText } from 'tabview/relationshipDiagrams/atoms';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  URL_BOX_ICON_CLASS_NAME,
  URL_ICON_MARGIN,
  URL_ICON_SIZE,
} from './consts';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { GRAPH_COMPONENT_URL_FIELD_VALUES_POPOVER_ID } from 'tabview/graphComponent/consts';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { NO_EXPORT_CLASS_NAME } from '@ardoq/global-consts';
import {
  CollapsibleGraphGroup,
  GraphGroup,
  GraphItem,
  estimateCountLabelWidth,
} from '@ardoq/graph';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { APIFieldAttributes, APIFieldType, ArdoqId } from '@ardoq/api-types';
import { openUrlInNewTab } from '@ardoq/common-helpers';
import { GRAPH_SKIP_CLICK_CLASSNAME } from 'tabview/graphComponent/yfilesHelper';
import { Node as OldGraphNode } from 'graph/node';
import { Edge as OldGraphEdge } from 'graph/edge';
import { ensureContrast } from '@ardoq/color-helpers';
import { isContextGraphNode } from './nodeDecorator';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { colors } from '@ardoq/design-tokens';
import { createRoot } from 'react-dom/client';
import trackUrlLinkClicked from 'tabview/graphViews/tracking';
import {
  folderStyleGroupLabelStartX,
  getElementOpacityIfTransparentized,
  getGroupLabelMaxWidth,
  measureGroupLabel,
} from 'tabview/blockDiagram/view/utils';
import { createSvgElement } from '@ardoq/dom-utils';
import { readableButtonColor } from 'tabview/blockDiagram/view/yFilesExtensions/modernized/utils';
import { MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE } from './modernized/consts';

const UrlIcon = styled(MaterialIconsText)<{
  $fontSize: number;
  $isModern: boolean;
}>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  ${({ $isModern }) =>
    $isModern
      ? css`
          dominant-baseline: central;
          text-anchor: middle;
          stroke: none;
        `
      : css`
          pointer-events: none;
        `}
`;

const isFolderStyleGroup = (group: any): group is GraphGroup => {
  if (!(group instanceof GraphGroup)) {
    return false;
  }
  if (group instanceof CollapsibleGraphGroup) {
    return !(group.isComponent() && group.collapsed);
  }
  return true;
};

const getComponentTypeFieldIds = (
  modelId: ArdoqId,
  workspaceModelId: ArdoqId
) => {
  const typeId = componentInterface.getTypeId(modelId);
  return typeId === null
    ? []
    : fieldInterface.getFieldIdsByComponentType(typeId, workspaceModelId);
};

const getReferenceTypeFieldIds = (
  modelId: ArdoqId,
  workspaceModelId: ArdoqId
) => {
  const typeId = referenceInterface.getTypeId(modelId);
  return typeId === null
    ? []
    : fieldInterface.getFieldIdsByReferenceType(typeId, workspaceModelId);
};

const getUrlFieldValues = (isComponent: boolean, modelId: ArdoqId) => {
  const workspaceId = isComponent
    ? (componentInterface.getWorkspaceId(modelId) ?? '')
    : (referenceInterface.getRootWorkspaceId(modelId) ?? '');
  const workspaceModelId = workspaceInterface.getWorkspaceModelId(workspaceId);

  if (workspaceModelId === null) return [];

  const urlFieldNames = (
    isComponent
      ? getComponentTypeFieldIds(modelId, workspaceModelId)
      : getReferenceTypeFieldIds(modelId, workspaceModelId)
  )
    .map(fieldInterface.getFieldData)
    .filter(
      (field): field is APIFieldAttributes => field?.type === APIFieldType.URL
    )
    .map(({ name }) => name);

  return urlFieldNames
    .map(fieldName =>
      isComponent
        ? componentInterface.getFieldValue(modelId, fieldName)
        : referenceInterface.getFieldValue(modelId, fieldName)
    )
    .filter(ExcludeFalsy) as string[];
};

const clickHandler =
  (container: Element, isComponent: boolean, modelId: string) => () => {
    const urlFieldValues = getUrlFieldValues(isComponent, modelId);
    if (urlFieldValues.length === 1) {
      trackUrlLinkClicked(container);
      openUrlInNewTab(urlFieldValues[0]);
    }
  };

const render = (label: ILabel) => {
  const outerG = createSvgElement('g', {
    class: NO_EXPORT_CLASS_NAME,
  });
  const g = createSvgElement('g');

  const graphNode = label.tag as GraphItem | OldGraphNode | OldGraphEdge; // performance critical: prefer as to instanceof
  const groupNode = label.owner as INode;
  const group = label.owner?.tag as CollapsibleGraphGroup; // using as and not instanceof for performance critical code
  const isFolder = isFolderStyleGroup(graphNode);
  const isContext = isContextGraphNode(graphNode);
  const modelId = dataModelId(graphNode);
  const isComponent = componentInterface.isComponent(modelId);
  const isCollapsedComponent =
    isComponent &&
    graphNode instanceof CollapsibleGraphGroup &&
    graphNode.collapsed;
  const padding = isContext && !isFolder ? CONTEXT_HIGHLIGHT_PADDING : 0;

  let labelWidth = 0;
  if (groupNode.layout) {
    labelWidth = Math.min(
      getGroupLabelMaxWidth(groupNode),
      measureGroupLabel(componentInterface.getDisplayName(group.modelId) || '')
    );
  }
  const xTranslate = isFolder
    ? folderStyleGroupLabelStartX(graphNode) + URL_ICON_MARGIN + labelWidth
    : (isCollapsedComponent
        ? estimateCountLabelWidth(graphNode.descendantCount) / 2
        : 0) - padding;

  g.setAttribute('transform', `translate(${xTranslate}, ${padding})`);
  outerG.appendChild(g);

  const isReference = !isComponent && referenceInterface.isReference(modelId);

  const urlRect = createSvgElement('rect', {
    width: URL_ICON_SIZE.toString(),
    height: URL_ICON_SIZE.toString(),
    rx: '6',
    ry: '6',
    class: classes(
      'yfiles-url-box',
      isComponent && 'component',
      isReference && 'integration',
      GRAPH_SKIP_CLICK_CLASSNAME
    ),
    [POPOVER_ID_ATTR]: GRAPH_COMPONENT_URL_FIELD_VALUES_POPOVER_ID,
    [GLOBAL_HANDLER_ID_ATTRIBUTE]: modelId,
    fill: 'white',
  });
  g.appendChild(urlRect);

  const backgroundColor = 'white';

  const nodeColor = isReference
    ? (referenceInterface.getCssColors(modelId, {
        useAsBackgroundStyle: false,
      })?.stroke ?? colors.black)
    : (getComponentCssColors(modelId, { useAsBackgroundStyle: false })?.fill ??
      (isFolder ? colors.grey90 : colors.black));

  const iconColor = ensureContrast(backgroundColor, nodeColor);
  const publicIcon = createSvgElement('g', {
    class: URL_BOX_ICON_CLASS_NAME,
    fill: iconColor,
    opacity: String(
      getElementOpacityIfTransparentized(group.isTransparentized)
    ),
  });

  g.appendChild(publicIcon);

  createRoot(publicIcon).render(
    createElement(
      UrlIcon,
      {
        x: 0,
        y: `${URL_ICON_SIZE}`,
        $fontSize: URL_ICON_SIZE,
        $isModern: false,
      },
      IconName.OPEN_IN_NEW
    )
  );
  outerG.addEventListener('click', clickHandler(outerG, isComponent, modelId));
  return outerG;
};
const renderModern = (label: ILabel) => {
  const graphNode = label.tag as GraphItem | OldGraphNode | OldGraphEdge; // performance critical: prefer as to instanceof
  const group = label.owner?.tag as CollapsibleGraphGroup; // using as and not instanceof for performance critical code

  const modelId = dataModelId(graphNode);
  const isComponent = componentInterface.isComponent(modelId);
  const isReference = !isComponent && referenceInterface.isReference(modelId);
  const container = createSvgElement('g', {
    class: classes(
      NO_EXPORT_CLASS_NAME,
      isComponent && 'component',
      isReference && 'integration',
      GRAPH_SKIP_CLICK_CLASSNAME
    ),
    cursor: 'pointer',
    opacity: `${getElementOpacityIfTransparentized(group.isTransparentized)}`,
    [POPOVER_ID_ATTR]: GRAPH_COMPONENT_URL_FIELD_VALUES_POPOVER_ID,
    [GLOBAL_HANDLER_ID_ATTRIBUTE]: modelId,
  });
  const isGroup = graphNode instanceof CollapsibleGraphGroup;
  const backgroundColor =
    isGroup && !(isComponent && graphNode.collapsed)
      ? modelId
        ? componentInterface.getComponentDisplayColorAsSVGAttributes(modelId, {
            useAsBackgroundStyle: false,
          })?.fill || colors.grey95
        : colors.grey95
      : colors.white;
  const urlIconColor = isGroup
    ? readableButtonColor(backgroundColor)
    : colors.iconStrong;

  createRoot(container).render(
    createElement(
      UrlIcon,
      {
        x: `${MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE / 2}`,
        y: `${MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE / 2}`,
        $fontSize: MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE,
        $isModern: true,
        fill: urlIconColor,
      },
      IconName.OPEN_IN_NEW
    )
  );
  container.addEventListener(
    'click',
    clickHandler(container, isComponent, modelId)
  );
  return container;
};

export class ArdoqURLLabelStyle extends LabelStyleBase {
  static Classic = new ArdoqURLLabelStyle(false);
  static Modern = new ArdoqURLLabelStyle(true);
  private constructor(private isModern: boolean) {
    super();
  }
  override createVisual(context: IRenderContext, label: ILabel) {
    const g = this.isModern ? renderModern(label) : render(label);
    const transform = LabelStyleBase.createLayoutTransform(
      context,
      label.layout,
      true
    );
    transform.applyTo(g);
    return new SvgVisual(g);
  }

  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ) {
    return this.createVisual(context, label);
  }

  override getPreferredSize() {
    const urlIconSize = this.isModern
      ? MODERNIZED_BLOCK_DIAGRAM_URL_ICON_SIZE
      : URL_ICON_SIZE;
    return new Size(urlIconSize, urlIconSize);
  }
}
