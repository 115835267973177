import { ModalSize, info } from '@ardoq/modal';
import { CodeEditor, EditorLanguage } from '@ardoq/code-editor';

type JsonModalProps = {
  title?: string;
  json: Record<string, unknown>;
};

export const jsonModal = ({ title = 'Details', json }: JsonModalProps) =>
  info({
    title,
    modalSize: ModalSize.L,
    body: (
      <CodeEditor
        language={EditorLanguage.JSON}
        value={JSON.stringify(json, null, '\t')}
        readOnly
        wrap
      />
    ),
  });
