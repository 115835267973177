import {
  ROOT_NODE_ID,
  addAllReferencedComponents,
  getIdealGridWidth,
  getRootNode,
} from './utils';
import { Observable, combineLatest } from 'rxjs';
import type { ContextShape, GlobalReferenceType } from '@ardoq/data-model';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { componentInterface } from '@ardoq/component-interface';
import {
  LayoutBoxType,
  LayoutBoxTypes,
  ListValuesMap,
  NameLabelDict,
  TemporaryDict,
  ViewSettingsShape,
  ViewStreamShape,
} from './types';
import { layoutRoot } from './layout';
import { debounceTime, map, tap } from 'rxjs/operators';
import { sum } from 'lodash';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { dispatchAction } from '@ardoq/rxbeach';
import { readViewComponentIds } from 'streams/viewComponentIds$';
import { getMaxTreeDepth } from '@ardoq/dependency-map';
import {
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import modelCss$ from 'utils/modelCssManager/modelCss$';
import { getFilteredViewSettings$ } from 'views/filteredViewSettings$';
import {
  getViewSettingsStreamWithChanges,
  ViewSettings,
} from 'viewSettings/viewSettingsStreams';
import { getComponentTypesInfo } from '@ardoq/graph';
import modelUpdateNotification$ from 'modelInterface/modelUpdateNotification$';
import { context$ } from 'streams/context/context$';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { getFocusedStream, getHoveredStream } from 'tabview/streams';

const VIEW_ID = ViewIds.CAPABILITY_MAP;

export type StreamedProps = {
  viewModel: ViewStreamShape;
  viewState: ViewSettingsShape;
};

const emptyState: ViewStreamShape = {
  listFields: [],
  listValues: [],
  componentTypes: [],
  legendComponentTypes: [],
  supportingValue: '',
  focusedComponentId: null,
  hoveredComponentId: null,
  rootLayoutBox: {
    layoutType: LayoutBoxTypes.COLUMN,
    layoutBoxes: [],
    targetGridWidth: 0,
    hasPriorityValue: false,
    calculatedGridWidth: 0,
    id: '',
    classNames: '',
    representationData: undefined,
    leavesCount: 0,
  },
  referenceTypes: {
    targetReferenceTypes: [],
    sourceReferenceTypes: [],
  },
  maxTreeDepth: 0,
  workspaceId: '',
};

const fieldProps = ({ name, label }: APIFieldAttributes): NameLabelDict => ({
  name,
  label,
});

const valueOption = (name: string): NameLabelDict => ({
  name,
  label: name,
});

const prioritySorter = (a: TemporaryDict, b: TemporaryDict) => {
  const isAFinite = Number.isFinite(a.priority as number);
  const isBFinite = Number.isFinite(b.priority as number);

  return isAFinite && isBFinite
    ? (a.priority as number) - (b.priority as number)
    : isAFinite
      ? -1
      : isBFinite
        ? 1
        : a.navigatorOrder - b.navigatorOrder;
};

const getFieldsAndSelectedField = (
  workspaceId: string,
  fieldType: APIFieldType,
  selectedFieldName: string
) => {
  const listFields = fieldInterface.getFieldsOfWorkspace(workspaceId, [
    fieldType,
  ]);
  return {
    listFields: [{ name: '', label: 'None' }, ...listFields.map(fieldProps)],
    selectedField:
      selectedFieldName &&
      listFields.find(field => field.name === selectedFieldName),
  };
};

const getListValues = (selectedField: APIFieldAttributes) => {
  const listValues = fieldInterface.getAllowedValues(selectedField._id);
  const listValuesMap = listValues.reduce<ListValuesMap>(
    (result: ListValuesMap, value: string, index: number) => {
      result[value] = index;
      return result;
    },
    {}
  );
  return {
    listValues: [{ name: '', label: 'None' }, ...listValues.map(valueOption)],
    listValuesMap,
  };
};

const setMinGridWidth = (supportingValue: string, node: TemporaryDict) => {
  node.minGridWidth = 1;
  node.children.forEach(child => setMinGridWidth(supportingValue, child));
  if (node.priorityRows.length > 0) {
    node.minGridWidth =
      node.supportingColumns.length +
      node.priorityRows
        .map(list => sum(list.map(child => child.minGridWidth)))
        .reduce((max, cand) => (cand > max ? cand : max));
  } else if (
    node.id === ROOT_NODE_ID &&
    node.supportingColumns.length === 0 &&
    node.priorityRows.length === 0
  ) {
    node.minGridWidth = getIdealGridWidth(node.leavesCount);
  }
};

const setDescendantsLeafCount = (node: TemporaryDict) => {
  if (node.children.length === 0) {
    node.leavesCount = 1;
    return node.leavesCount;
  }
  node.leavesCount = sum(node.children.map(setDescendantsLeafCount));
  return node.leavesCount;
};

const getIds = (tempDict: TemporaryDict): string[] => [
  tempDict.id,
  ...tempDict.children.flatMap(getIds),
];
const getSubtree = (node: TemporaryDict): TemporaryDict[] => [
  ...node.children,
  ...node.children.flatMap(child => getSubtree(child)),
];
const collapseTree = (node: TemporaryDict, maxDepth: number): void => {
  if (maxDepth === 0) {
    node.collapsedDescendants = getSubtree(node);
  }
  node.children.forEach(child => collapseTree(child, maxDepth - 1));
  if (maxDepth <= 0) {
    node.supportingColumns.length = 0;
    node.priorityRows.length = 0;
    node.anyRow.length = 0;
    node.children.length = 0;
    node.isCollapsed = true;
  }
};

const reset = (
  {
    selectedFieldName,
    supportingValue,
    groupingTypeId,
    isLayoutDirectionAnyVertical,
    includeIncomingReferenceTypes,
    includeOutgoingReferenceTypes,
    treeDepth,
  }: ViewSettingsShape,
  { workspaceId, componentId }: ContextShape,
  hovered: ArdoqId | null,
  focused: ArdoqId | null
) => {
  const newState = {
    ...emptyState,
    workspaceId,
    hoveredComponentId: hovered,
    focusedComponentId: focused,
  };
  if (!workspaceId) {
    return newState;
  }

  const { listFields, selectedField } = getFieldsAndSelectedField(
    workspaceId,
    APIFieldType.LIST,
    selectedFieldName
  );
  newState.listFields = listFields;

  const { listValues, listValuesMap } = selectedField
    ? getListValues(selectedField)
    : {
        listValues: [],
        listValuesMap: {},
      };
  newState.listValues = listValues;

  const componentIds = componentId
    ? [componentId]
    : componentInterface
        .getRootComponents(workspaceId)
        .filter(id => componentInterface.isIncludedInContextByFilter(id));

  const rootNode = getRootNode(
    selectedFieldName,
    groupingTypeId,
    prioritySorter,
    listValuesMap,
    supportingValue,
    includeIncomingReferenceTypes,
    includeOutgoingReferenceTypes,
    componentIds
  );

  const compIds = getIds(rootNode);

  // componentTypes is used to populate the 'Select hidden component type' picker in the settings bar. for this we use the ids from the tree before it has been collapsed, and before references have been added.
  const { componentTypes } = getComponentTypesInfo(compIds);
  newState.componentTypes = componentTypes;

  const compareReferencesByName = (
    a: GlobalReferenceType,
    b: GlobalReferenceType
  ) => localeCompare(a.name, b.name, getCurrentLocale());

  const { targetReferenceTypes, sourceReferenceTypes } =
    componentInterface.getGlobalReferenceTypes(compIds);

  newState.referenceTypes = {
    targetReferenceTypes: targetReferenceTypes.toSorted(
      compareReferencesByName
    ),
    sourceReferenceTypes: sourceReferenceTypes.toSorted(
      compareReferencesByName
    ),
  };
  // The root node is not a visible container, only expose the depth of
  // the visible tree.
  newState.maxTreeDepth = getMaxTreeDepth(rootNode) - 1;

  // Infinity gets cast to null when stored as view setting of a slide.
  if (Number.isInteger(treeDepth) && treeDepth < newState.maxTreeDepth) {
    collapseTree(rootNode, treeDepth);
  }

  rootNode.children.forEach(child =>
    addAllReferencedComponents(
      child,
      includeIncomingReferenceTypes,
      includeOutgoingReferenceTypes,
      selectedFieldName,
      groupingTypeId,
      prioritySorter,
      listValuesMap,
      supportingValue
    )
  );
  // legendComponentTypes should be everything that's actually visible. so we use the ids from the tree after it has been collapsed and after reference nodes have been
  newState.legendComponentTypes = getComponentTypesInfo(
    getIds(rootNode)
  ).legendComponentTypes;
  setDescendantsLeafCount(rootNode);
  setMinGridWidth(supportingValue, rootNode);

  newState.rootLayoutBox = layoutRoot(rootNode, isLayoutDirectionAnyVertical);

  return newState;
};

const getComponentIdsFromLayoutBox = (layoutBox: LayoutBoxType): ArdoqId[] => {
  return [
    layoutBox.id,
    ...layoutBox.layoutBoxes.flatMap(getComponentIdsFromLayoutBox),
  ].filter(ExcludeFalsy);
};

const getFilteredCapMapViewState$ = (
  viewSettings$: Observable<ViewSettings<ViewSettingsShape>>
) =>
  getFilteredViewSettings$(viewSettings$).pipe(
    map(({ currentSettings }) => currentSettings)
  );

const getViewModelStream = (
  modelInterfaceUpdate$: Observable<null>,
  viewSettings$: Observable<ViewSettings<ViewSettingsShape>>
): Observable<ViewStreamShape> =>
  combineLatest([
    getFilteredViewSettings$(viewSettings$),
    context$,
    getHoveredStream(VIEW_ID),
    getFocusedStream(VIEW_ID),
    modelInterfaceUpdate$,
    modelCss$, // since this viewModel$ resolves iconColors from the model css classes, we must reset and re-calculate the icon colors when modelCss$ is updated.
  ]).pipe(
    map(([viewstate, context, hovered, focused]) =>
      reset(viewstate.currentSettings, context, hovered, focused)
    ),
    tap(({ rootLayoutBox }) => {
      dispatchAction(
        readViewComponentIds({
          [ViewIds.CAPABILITY_MAP]: getComponentIdsFromLayoutBox(rootLayoutBox),
        })
      );
    })
  );

const viewState$ = getViewSettingsStreamWithChanges<ViewSettingsShape>(VIEW_ID);

export const viewModel$: Observable<StreamedProps> = combineLatest({
  viewModel: getViewModelStream(modelUpdateNotification$, viewState$),
  viewState: getFilteredCapMapViewState$(viewState$),
}).pipe(
  map(({ viewModel, viewState }) => ({
    viewModel,
    viewState,
  })),
  // avoiding repeated unnecessary values
  // due to interconnected viewModel, viewState, activeFilter streams
  // helps with fewer rerenderings of the connected React component
  debounceTime(100)
);
