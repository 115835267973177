import {
  streamReducer,
  action$,
  reduceState,
  dispatchAction,
  ofType,
} from '@ardoq/rxbeach';
import { Observable, combineLatest } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { withNamespaceOrNullNamespace } from 'streams/utils/streamOperators';
import {
  DEFAULT_TIMELINE_EVENT_CALLBACKS,
  timelineViewModelOperations,
  getViewRelevantScopeData,
  getHierarchySortComparator,
  getGraphNodeOrGroupLabelInViewpointMode,
  ViewModelSourceData,
  DEFAULT_TIMELINE_VIEW_MODEL_SOURCE_DATA,
} from '@ardoq/timeline2024';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { debounceTime, startWith } from 'rxjs/operators';
import graphModel$ from 'modelInterface/graphModel$';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { componentInterface } from '@ardoq/component-interface';
import { isPresentationMode } from 'appConfig';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { simpleNotifier } from '@ardoq/dropdown-menu';
import { ExtractStreamShape } from 'tabview/types';
import { isEmpty } from 'lodash';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { getTimelineViewGraphHierarchyForViewpointMode } from './utils';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { fieldInterface } from '@ardoq/field-interface';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import {
  buildGraphFromTraversalAndSearch,
  getAppliedGroups,
  getCanBeUsedAsGroup,
  getGraphInterfaceWithModelInterfaces,
  getLabelsInterfaceWithModelInterfaces,
  isGraphModelValid,
} from '@ardoq/graph';
import componentAttributesData$ from 'streams/componentAttributesData$';
import { locale$ } from 'streams/locale$';
import { dateRangeOperations } from '@ardoq/date-range';
import {
  ComponentByIdSortDependencies,
  returnTrue,
} from '@ardoq/common-helpers';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import { startAction } from 'actions/utils';
import { DEFAULT_DEBOUNCE_TIME } from 'modelInterface/consts';
import { getViewGraphHierarchyRootItems } from '@ardoq/timeline2024';
import { FieldsAndItemsSourceData } from '@ardoq/timeline2024';
import UserSettings from 'models/UserSettings';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import { COMPONENT_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { viewSettingsForModelSourceData$ } from './viewSettings$';

type BuildViewModelSourceDataArgs = ExtractStreamShape<typeof viewSourceData$>;

const getNeverShowAgain = async () =>
  (await new UserSettings(ViewIds.TIMELINE).get<boolean>(NEVER_SHOW_AGAIN)) ??
  false;

const setNeverShowAgain = (value: boolean) =>
  new UserSettings(ViewIds.TIMELINE).set(NEVER_SHOW_AGAIN, value);

const buildViewModelSourceDataReducer = (
  state: ViewModelSourceData,
  [
    { componentId: contextComponentId, workspacesIds, sort },
    viewSettings,
    graph,
    loadedGraph,
    { componentAttributesById },
    userLocale,
  ]: BuildViewModelSourceDataArgs
): ViewModelSourceData => {
  const viewInterface = state.viewInterface;

  const { getAllGroupingRules, isComponent, getAllComponentIds } =
    viewInterface;

  const userDefinedGrouping = getAllGroupingRules();

  const componentDoubleClickHandler = (componentId: ArdoqId) => {
    if (!isComponent(componentId)) {
      return;
    }
    dispatchAction(openDetailsDrawer([componentId]));
  };

  const hasGroupingStyles = true;
  const updatedState: ViewModelSourceData = {
    ...state,
    viewInterface: {
      ...viewInterface,
      componentDoubleClickHandler,
    },
    contextComponentId,
    graph,
    hasGroupingStyles,
    sourceDataViewSettings: viewSettings,
  };

  /**
   * Pass GraphContext as argument to use scopeData utils.
   * The graphInterface and labelsInterface are used to ensure that the view can be built with scopeData.
   */
  const graphInterface = getGraphInterfaceWithModelInterfaces();
  const labelsInterface = getLabelsInterfaceWithModelInterfaces();

  if (
    !isGraphModelValid(graphInterface, graph) ||
    isEmpty(componentAttributesById)
  ) {
    // if graph is invalid, typically this means the graphModel$ has not updated itself with the latest state
    // of the References collection, we therefore avoid rebuilding the graph based on other updates
    return updatedState;
  }

  const sortDependencies: ComponentByIdSortDependencies = {
    componentAttributesById,
    sort,
    isDateRangeField: dateRangeOperations.isCombinedDateRangeFieldNames(
      sort.attr
    ),
    userLocale,
  };

  const hierarchySortComparator = getHierarchySortComparator(sortDependencies);

  const hasUserDefinedGroups = Boolean(userDefinedGrouping.length);

  // graph model traversal with applied grouping rules hierarchy
  const traversalAndGroupingResult = buildGraphFromTraversalAndSearch({
    graphInterface,
    viewId: ViewIds.TIMELINE,
    graph,
    groupingRules: getAppliedGroups(userDefinedGrouping),
    loadedGraph,
    canBeUsedAsGroup: getCanBeUsedAsGroup(
      graphInterface,
      loadedGraph,
      hasUserDefinedGroups
    ),
    allComponentIdsRelevantForFilterCache: getAllComponentIds(), // This is a hack to satisfy the broken FilterCache.
  });

  // abstraction of the view graph hierarchy to graph nodes with temporary ids
  const { viewGraphHierarchy, allComponentIds } =
    getTimelineViewGraphHierarchyForViewpointMode({
      graphInterface,
      loadedGraph,
      graph,
      workspacesIds,
      hasUserDefinedGroups,
      contextComponentId,
      hierarchySortComparator,
      traversalAndGroupingResult,
    });

  viewGraphHierarchy.forEach(({ rawGraphItem, graphItem }) => {
    if (rawGraphItem) {
      graphItem.setItemLabels(
        getGraphNodeOrGroupLabelInViewpointMode({
          labelsInterface,
          rawGraphItem,
          isComponent,
        })
      );
    }
  });

  const { viewRelevantWorkspacesScopeData, viewRelevantComponentsScopeData } =
    getViewRelevantScopeData({
      allComponentIds,
      viewInterface,
    });

  const fieldsMetaData = timelineViewModelOperations.getFieldsMetaData({
    viewRelevantWorkspacesScopeData,
    viewSettings,
  });

  const { errors, hasClones } = traversalAndGroupingResult;

  const fieldsAndItemsSourceData: FieldsAndItemsSourceData = {
    viewGraphHierarchy,
    startSetNodes: getViewGraphHierarchyRootItems(viewGraphHierarchy),
    ...fieldsMetaData,
  };

  return {
    ...updatedState,
    errors,
    hasClones,
    viewRelevantComponentsScopeData,
    fieldsAndItemsSourceData,
    contextComponentId,
  };
};

const filtersChange$ = action$.pipe(
  ofType(notifyFiltersChanged),
  startWith(startAction())
);

const viewSourceData$ = combineLatest([
  context$,
  viewSettingsForModelSourceData$,
  graphModel$,
  loadedGraph$,
  componentAttributesData$,
  locale$,
  filtersChange$,
]).pipe(debounceTime(DEFAULT_DEBOUNCE_TIME * 2)); // should be larger than the debounce time of the componentAttributesData$

export const getViewpointModeViewModelSourceData$ = (
  viewInstanceId: string
): Observable<ViewModelSourceData> => {
  const openDateRangeViewSetting = simpleNotifier();
  const openMilestonesViewSetting = simpleNotifier();

  const initialViewModelSourceDataState: ViewModelSourceData = {
    ...DEFAULT_TIMELINE_VIEW_MODEL_SOURCE_DATA,
    timelineViewEventCallbacks: {
      ...DEFAULT_TIMELINE_EVENT_CALLBACKS,
      openDateRangeViewSetting,
      openMilestonesViewSetting,
      onViewSettingsUpdate,
      isPresentationMode,
      getNeverShowAgain,
      setNeverShowAgain,
    },
    viewInterface: {
      getWorkspaceOfComponent: componentInterface.getWorkspaceId,
      getRepresentationData: componentInterface.getRepresentationData,
      getComponentWorkspaceModelId: componentInterface.getModelId,
      getTypeId: componentInterface.getTypeId,
      isIncludedInContextByFilter: returnTrue,
      getComponentChildren: componentInterface.getChildren,
      getComponentDisplayColor:
        componentInterface.getComponentDisplayColorAsSVGAttributes,
      getComponentFieldValue: componentInterface.getFieldValue,
      getDisplayName: componentInterface.getDisplayName,
      isComponent: componentInterface.isComponent,
      getAllComponentIds: componentInterface.getAllComponentIds,

      getWorkspaceModelId: workspaceInterface.getWorkspaceModelId,
      getComponentTypesForWorkspace: workspaceInterface.getComponentTypes,
      getWorkspaceNameById: workspaceInterface.getWorkspaceName,

      getFieldsOfWorkspace: fieldInterface.getFieldsOfWorkspace,

      featureInterface: {
        isModernizedStyle: true,
      },
      getAllGroupingRules: groupingRuleInterface.getAll,
      isPresentationMode,
    },
    viewInstanceId,
    contextMenuDataAttributeName: COMPONENT_ID_ATTRIBUTE, // GLOBAL_HANDLER_ID_ATTRIBUTE for Discover
  };

  // This will be the main stream that will flow into the package
  const viewModelSourceData$: Observable<ViewModelSourceData> = action$.pipe(
    withNamespaceOrNullNamespace(viewInstanceId),
    reduceState<ViewModelSourceData>(
      'timelineViewModelSourceData',
      initialViewModelSourceDataState,
      [
        streamReducer<ViewModelSourceData, BuildViewModelSourceDataArgs>(
          viewSourceData$,
          buildViewModelSourceDataReducer
        ),
      ]
    )
  );

  return viewModelSourceData$;
};
