import {
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  FreeEdgeLabelModel,
  FreeNodeLabelModel,
  GroupingKeys,
  IEdge,
  IGraph,
  IGroupBoundsCalculator,
  ILabel,
  ILabelModelParameter,
  ILabelOwner,
  IModelItem,
  INode,
  INodeStyle,
  InteriorLabelModel,
  Point,
  Rect,
  Size,
  SmartEdgeLabelModel,
} from '@ardoq/yfiles';
import AggregateEdgeStyle from './AggregateEdgeStyle';
import CollapsibleGroupStyle from './CollapsibleGroupStyle';
import { ArdoqURLLabelStyle } from 'yfilesExtensions/styles/ardoqURLLabelStyle';
import { isEqualWith } from 'lodash';
import * as encodingUtils from '@ardoq/html';
import { getDefaultSize } from 'yfilesExtensions/styles/styleLoader';
import { logError } from '@ardoq/logging';
import GroupBoundsCalculator from './GroupBoundsCalculator';
import { componentInterface } from '@ardoq/component-interface';
import { ArdoqAddToScenario } from 'yfilesExtensions/styles/ArdoqAddToScenario';
import { DiffType } from '@ardoq/data-model';
import { ViewIds } from '@ardoq/api-types';
import { isContextNode } from 'yfilesExtensions/styles/nodeDecorator';
import getNodeLayoutSize from 'yfilesExtensions/getNodeLayoutSize';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  CONTEXT_HIGHLIGHT_STROKE,
  NODE_HEIGHT,
  NODE_WIDTH,
} from 'yfilesExtensions/styles/consts';
import {
  AggregatedGraphEdge,
  AggregatedEdgeLabelStyle,
  CollapsibleGraphGroup,
  GraphItem,
  GraphNode,
  TAB_HEIGHT,
  type RelationshipDiagramGraphEdgeType,
} from '@ardoq/graph';
import { urlNodeButtonModelParameter } from './labelParameters';
import { getCssClassFromDiffType } from 'scope/modelUtil';
import sizeEmptyGroupNodes from './sizeEmptyGroupNodes';
import MultiLabelStyle from './labels/MultiLabelStyle';
import { isEqual } from 'lodash';
import MultiLabelGroupStyle from './labels/MultiLabelGroupStyle';
import { updateEdgeLabels } from './modernized/graphBuilder/updateGraphLabels';

const getInitialLayout = (ancestor: INode | null) =>
  // if this is a descendant of a node which has been expanded, its origin (for animation purposes) should be the group center.
  ancestor ? new Rect(ancestor.layout.center, ancestor.layout.toSize()) : null;

const isCollapsedComponentGroup = (group: CollapsibleGraphGroup) =>
  group.collapsed && group.isComponent();

const graphItemsEqual = (
  a: GraphItem | GraphItem[],
  b: GraphItem | GraphItem
) => !Array.isArray(a) && a.isEqual(b);

const isURLLabel = (label: ILabel): label is ILabel & { tag: GraphItem } => {
  return label.style instanceof ArdoqURLLabelStyle;
};

const updateLabels = (
  owner: ILabelOwner,
  graph: IGraph,
  graphItem: GraphItem
) => {
  const labelsToRemove: ILabel[] = [];
  owner.labels.forEach(label => {
    if (label.tag === null) {
      graph.setLabelText(label, graphItem.getItemLabels()?.mainLabel ?? '');
    } else if (isURLLabel(label) && !label.tag.hasURLFields()) {
      labelsToRemove.push(label);
    }
  });
  labelsToRemove.forEach(label => graph.remove(label));
};
const urlButtonStyle = ArdoqURLLabelStyle.Classic;

/**
 * The label parameter specifying the location of URL icons in uncollapsed groups.
 *
 * @description
 * The URL Icon is treated like a label, but its location is based on the group node label.
 *
 * This location is calculated in ardoqURLLabelStyle-- the label is measured before an x-translate is applied.
 *
 * Therefore, the orientation of this label parameter is from the upper-left corner of the node, so the x-translate can be applied in the rendering.
 */
const urlGroupNodeButtonModelParameter =
  FreeNodeLabelModel.INSTANCE.createParameter({
    layoutRatio: Point.ORIGIN, // upper-left corner
    layoutOffset: new Point(0, TAB_HEIGHT / 2),
    labelRatio: new Point(0, 0.5),
  });
const urlEdgeButtonModelParameter =
  FreeEdgeLabelModel.INSTANCE.createDefaultParameter();

const addToScenarioButtonStyle = new ArdoqAddToScenario();
const addToScenarioNodeButtonModelParameter =
  FreeNodeLabelModel.INSTANCE.createParameter(
    new Point(0.5, 0), // place label at node layout top-center
    new Point(0, -5), // move up 5px
    new Point(0.5, 1) // anchor point of label is bottom-center
  );

const aggregatedEdgeCountLabelStyle = new AggregatedEdgeLabelStyle(
  getCssClassFromDiffType
);
const aggregatedEdgeCountLabelParameter =
  new SmartEdgeLabelModel().createDefaultParameter();

const southsideLabelParameter = new ExteriorLabelModel({
  insets: CONTEXT_HIGHLIGHT_STROKE,
}).createParameter(ExteriorLabelModelPosition.SOUTH);

const groupLabelLayoutParameter = InteriorLabelModel.NORTH_WEST;
const filterNullValues = <TKey, TValue>(
  keyValuePair: [TKey, TValue | null]
): keyValuePair is [TKey, TValue] => keyValuePair[1] !== null;

type DiffUpdateGraphArgs<T extends GraphItem, Y extends IModelItem> = {
  oldMap: Map<string, Y>;
  businessObjects: T[];
  create: (graphItem: T) => Y | null;
  update: (graphItem: T) => Y | null;
  remove: (graphItem: Y, oldMap: Map<string, Y>) => void;
};
const diffUpdateGraph = <T extends GraphItem, Y extends IModelItem>({
  oldMap,
  businessObjects,
  create,
  update,
  remove,
}: DiffUpdateGraphArgs<T, Y>): Map<string, Y> => {
  const newMap = new Map(
    businessObjects
      .map((businessObject): [string, Y | null] => [
        businessObject.id,
        // Add new items, update existing items
        (oldMap.has(businessObject.id) ? update : create)(businessObject),
      ])
      .filter(filterNullValues)
  );
  businessObjects.forEach(businessObject => oldMap.delete(businessObject.id));

  // Delete old items
  Array.from(oldMap.values()).forEach(graphItem => remove(graphItem, oldMap));
  return newMap;
};
export default class CollapsibleGroupsYGraphBuilder {
  private edgeMap = new Map<string, IEdge>();
  private nodeMap = new Map<string, INode>();
  private groupNodeMap = new Map<string, INode>();
  nodesSource: GraphNode[] = [];
  edgesSource: RelationshipDiagramGraphEdgeType[] = [];
  groupsSource: CollapsibleGraphGroup[] = [];
  constructor(
    viewId: ViewIds,
    private graph: IGraph,
    private getNodeStyle: (node: GraphNode) => INodeStyle,
    private getNodeSize: (nodeStyle: INodeStyle) => Size
  ) {
    // Initialization
    graph.edgeDefaults.style = new AggregateEdgeStyle();
    graph.groupNodeDefaults.style = CollapsibleGroupStyle.get(viewId);

    graph.groupNodeDefaults.labels.style = MultiLabelGroupStyle.Instance;
    graph.groupNodeDefaults.labels.layoutParameter = groupLabelLayoutParameter;
    // Style and labels
    graph.nodeDefaults.labels.layoutParameter = southsideLabelParameter;
    const labelStyle = MultiLabelStyle.Classic;
    graph.edgeDefaults.labels.style = labelStyle;
    graph.nodeDefaults.labels.style = labelStyle;

    const edgeLabelModel = new FreeEdgeLabelModel();
    graph.edgeDefaults.labels.layoutParameter =
      edgeLabelModel.createDefaultParameter();

    // registers information about the minimum node size of group nodes
    graph.mapperRegistry.createDelegateMapper(
      GroupingKeys.MINIMUM_NODE_SIZE_DP_KEY!,
      (groupNode: INode | null) => {
        if (!groupNode) {
          return Size.EMPTY.toYDimension();
        }
        const boundsProvider = groupNode.lookup(
          IGroupBoundsCalculator.$class
        ) as IGroupBoundsCalculator | null;
        const bounds =
          boundsProvider?.calculateBounds(graph, groupNode) ??
          Size.EMPTY.toYDimension();
        return bounds.toSize().toYDimension();
      }
    );
  }
  private createEdge = (graphEdge: RelationshipDiagramGraphEdgeType) => {
    const sourceNode =
      this.nodeMap.get(graphEdge.source) ||
      this.groupNodeMap.get(graphEdge.source);
    const targetNode =
      this.nodeMap.get(graphEdge.target) ||
      this.groupNodeMap.get(graphEdge.target);
    if (!sourceNode || !targetNode) {
      logError(Error('Source or Target node not found while creating Edge.'));
      return null;
    }

    const edge = this.graph.createEdge({
      tag: graphEdge,
      labels: [
        encodingUtils.unescapeHTML(graphEdge.getItemLabels()?.mainLabel ?? ''),
      ],
      source: sourceNode,
      target: targetNode,
    });

    if (graphEdge.hasURLFields()) {
      this.addURLEdgeLabel(edge, graphEdge);
    }
    if (graphEdge instanceof AggregatedGraphEdge) {
      this.addAggregatedCountLabel(edge, graphEdge);
    }
    return edge;
  };
  private updateEdge = (graphEdge: RelationshipDiagramGraphEdgeType) => {
    const edge = this.edgeMap.get(graphEdge.id);
    if (!edge) {
      logError(Error('Edge not found in updateEdge.'));
      return null;
    }

    if (
      graphEdge instanceof GraphItem &&
      !isEqual(edge.tag.getItemLabels(), graphEdge.getItemLabels())
    ) {
      this.removeEdge(edge);
      return this.createEdge(graphEdge);
    }

    // Create new edge if sourcePort/targetPort is gone (group Node turned into a normal node or vice-versa)
    if (!edge.sourcePort || !edge.targetPort) {
      return this.createEdge(graphEdge);
    }
    if (
      graphEdge.hasURLFields() &&
      !edge.labels.find(label => isURLLabel(label))
    ) {
      this.addURLEdgeLabel(edge, graphEdge);
    }
    if (
      edge.sourceNode?.tag instanceof GraphItem &&
      edge.targetNode?.tag instanceof GraphItem
    ) {
      if (
        edge.sourceNode.tag.id !== graphEdge.source ||
        edge.targetNode.tag.id !== graphEdge.target
      ) {
        // if source or target has changed, we must create a new edge.
        this.removeEdge(edge);
        return this.createEdge(graphEdge);
      }
    } else {
      logError(Error('Unexpected edge source or target.'));
    }
    updateEdgeLabels(edge, this.graph, graphEdge, false);
    return edge;
  };

  private getAncestorInGraph = (graphNode: GraphItem): INode | null =>
    (graphNode.parent &&
      (this.groupNodeMap.get(graphNode.parent.id) ??
        this.getAncestorInGraph(graphNode.parent))) ??
    null;

  private createNode = (graphNode: GraphNode) => {
    const node = this.graph.createNode({
      parent: graphNode.parent
        ? (this.groupNodeMap.get(graphNode.parent.id) ?? null)
        : null,
      tag: graphNode,
      labels: [graphNode.getItemLabels()?.mainLabel ?? ''],
      layout: getInitialLayout(this.getAncestorInGraph(graphNode)),
    });

    if (graphNode.hasURLFields()) {
      this.addURLNodeLabel(node, graphNode);
    }
    if (
      !(graphNode.getVisualDiffType() === DiffType.PLACEHOLDER) &&
      componentInterface.isScenarioRelated(graphNode.modelId)
    ) {
      this.addToScenarioButton(node, graphNode);
    }
    this.updateNodeLayout(node);
    return node;
  };
  private updateNode = (graphNode: GraphNode) => {
    const node = this.nodeMap.get(graphNode.id)!;
    if (!node) {
      logError(Error('Node not found in updateNode.'));
      return null;
    }

    if (!isEqual(node.tag.getItemLabels?.(), graphNode.getItemLabels())) {
      this.removeItem(node, this.nodeMap);
      return this.createNode(graphNode);
    }

    const style = this.getNodeStyle(graphNode);

    if (style !== node.style) {
      this.graph.setStyle(node, style);
    }
    this.updateNodeLayout(node);

    if (
      graphNode.hasURLFields() &&
      !node.labels.find(label => isURLLabel(label))
    ) {
      this.addURLNodeLabel(node, graphNode);
    }

    updateLabels(node, this.graph, graphNode);

    if (graphNode.parent) {
      const parentNode = this.groupNodeMap.get(graphNode.parent.id);
      if (parentNode) {
        this.graph.setParent(node, parentNode);
      } else {
        logError(Error('Parent node not found.'));
      }
    } else {
      this.graph.setParent(node, null);
    }
    if (
      !(graphNode.getVisualDiffType() === DiffType.PLACEHOLDER) &&
      componentInterface.isScenarioRelated(graphNode.modelId)
    ) {
      this.addToScenarioButton(node, graphNode);
    }
    return node;
  };

  private addGroupNodeLabels = (
    graphNode: CollapsibleGraphGroup,
    groupNode: INode
  ) => {
    const text = graphNode.getItemLabels()?.mainLabel ?? '';

    const label = this.graph.addLabel({
      owner: groupNode,
      text,
    });
    this.graph.setStyle(
      label,
      isCollapsedComponentGroup(graphNode)
        ? MultiLabelStyle.Classic
        : MultiLabelGroupStyle.Instance
    );
    this.graph.setLabelLayoutParameter(
      label,
      isCollapsedComponentGroup(graphNode)
        ? southsideLabelParameter
        : groupLabelLayoutParameter
    );
    if (graphNode.hasURLFields()) {
      this.addGroupURLNodeLabel(groupNode, graphNode);
    }
  };
  private createGroupNode = (graphNode: CollapsibleGraphGroup) => {
    const groupNode = this.graph.createGroupNode({
      tag: graphNode,
      layout: null,
    });
    this.addGroupNodeLabels(graphNode, groupNode);
    return groupNode;
  };
  private updateGroupNode = (graphNode: CollapsibleGraphGroup) => {
    const groupNode = this.groupNodeMap.get(graphNode.id);
    if (!groupNode) {
      logError(Error('Group node not found.'));
      return null;
    }
    this.graph.setNodeLayout(
      groupNode,
      new Rect(groupNode.layout.toPoint(), new Size(1, 1))
    );
    groupNode.tag = graphNode; // this could be a new GraphGroup with the same id.
    groupNode.labels.toArray().forEach(label => this.graph.remove(label));

    this.addGroupNodeLabels(graphNode, groupNode);
    return groupNode;
  };

  private removeItem = (item: IModelItem, map: Map<string, IModelItem>) => {
    const businessObject: GraphItem = item.tag;
    map.delete(businessObject.id);
    if (this.graph.contains(item)) {
      this.graph.remove(item);
    }
  };
  private removeEdge = (edge: IEdge) => {
    // Make sure the edge is attached to the graph
    if (edge.sourcePort || edge.targetPort) {
      this.removeItem(edge, this.edgeMap);
    }
  };

  private addAggregatedCountLabel = (
    owner: IEdge,
    graphEdge: AggregatedGraphEdge
  ) => {
    this.graph.addLabel({
      owner,
      text: `${graphEdge.modelIds.length}`,
      style: aggregatedEdgeCountLabelStyle,
      layoutParameter: aggregatedEdgeCountLabelParameter,
      tag: graphEdge,
    });
  };
  hasLayoutUpdate({
    nodes,
    groups,
    edges,
  }: {
    nodes: GraphNode[];
    groups: CollapsibleGraphGroup[];
    edges: RelationshipDiagramGraphEdgeType[];
  }) {
    const nodesUnchanged =
      !nodes || isEqualWith(nodes, this.nodesSource, graphItemsEqual);
    const groupsUnchanged =
      !groups || isEqualWith(groups, this.groupsSource, graphItemsEqual);
    const edgesUnchanged =
      !edges || isEqualWith(edges, this.edgesSource, graphItemsEqual);

    return !(nodesUnchanged && groupsUnchanged && edgesUnchanged);
  }
  clearEdges() {
    Array.from(this.edgeMap.values()).forEach(this.removeEdge);
  }
  clear() {
    this.graph.clear();

    this.groupNodeMap.clear();
    this.nodeMap.clear();
    this.edgeMap.clear();
  }
  buildGraph() {
    this.clear();

    this.groupNodeMap = new Map(
      this.groupsSource.map(graphNode => [
        graphNode.id,
        this.createGroupNode(graphNode),
      ])
    );

    this.setGroupNodeParents();

    this.nodeMap = new Map(
      this.nodesSource.map(graphNode => [
        graphNode.id,
        this.createNode(graphNode),
      ])
    );

    sizeEmptyGroupNodes(
      this.graph,
      GroupBoundsCalculator.Instance.getMinimumSize
    );

    this.edgeMap = new Map(
      this.edgesSource.map(graphEdge => {
        return [graphEdge.id, this.createEdge(graphEdge)!];
      })
    );
  }

  updateGraph() {
    if (this.nodeMap.size + this.edgeMap.size + this.groupNodeMap.size === 0) {
      this.buildGraph();
      return;
    }

    this.groupNodeMap = diffUpdateGraph({
      oldMap: this.groupNodeMap,
      businessObjects: this.groupsSource,
      create: this.createGroupNode,
      update: this.updateGroupNode,
      remove: this.removeItem,
    });

    this.setGroupNodeParents();

    this.nodeMap = diffUpdateGraph<GraphNode, INode>({
      oldMap: this.nodeMap,
      businessObjects: this.nodesSource,
      create: this.createNode,
      update: this.updateNode,
      remove: this.removeItem,
    });

    sizeEmptyGroupNodes(
      this.graph,
      GroupBoundsCalculator.Instance.getMinimumSize
    );

    this.edgeMap = diffUpdateGraph({
      oldMap: this.edgeMap,
      businessObjects: this.edgesSource,
      create: this.createEdge,
      update: this.updateEdge,
      remove: this.removeEdge,
    });
  }
  private addURLLabel(
    owner: ILabelOwner,
    graphNode: GraphItem,
    buttonModelParameter: ILabelModelParameter
  ) {
    this.graph.addLabel({
      owner,
      text: '',
      layoutParameter: buttonModelParameter,
      style: urlButtonStyle,
      tag: graphNode,
    });
  }
  private addURLEdgeLabel(
    owner: IEdge,
    graphEdge: RelationshipDiagramGraphEdgeType
  ) {
    this.addURLLabel(owner, graphEdge, urlEdgeButtonModelParameter);
  }
  private addURLNodeLabel(owner: INode, graphNode: GraphNode) {
    this.addURLLabel(owner, graphNode, urlNodeButtonModelParameter);
  }

  private addGroupURLNodeLabel(owner: INode, graphNode: CollapsibleGraphGroup) {
    this.addURLLabel(
      owner,
      graphNode,
      isCollapsedComponentGroup(graphNode)
        ? urlNodeButtonModelParameter
        : urlGroupNodeButtonModelParameter
    );
  }

  private setGroupNodeParents() {
    // Set parents of group nodes
    Array.from(this.groupNodeMap.values()).forEach(groupNode => {
      const parent =
        groupNode.tag instanceof GraphItem &&
        groupNode.tag.parent &&
        this.groupNodeMap.get(groupNode.tag.parent.id);

      if (parent) {
        this.graph.setParent(groupNode, parent);
      }
    });
  }
  private updateNodeLayout(node: INode) {
    const style = this.getNodeStyle(node.tag);
    const size = this.getNodeSize(style);
    const styleWidth = size?.width || getDefaultSize().width;
    const styleHeight = size?.height || getDefaultSize().height;
    const yAspectRatio = NODE_HEIGHT / styleHeight;
    const xAspectRatio = NODE_WIDTH / styleWidth;

    let width;
    let height;

    if (styleWidth * yAspectRatio > NODE_WIDTH) {
      width = NODE_WIDTH;
      height = styleHeight * xAspectRatio;
    } else {
      width = styleWidth * yAspectRatio;
      height = NODE_HEIGHT;
    }

    this.graph.setStyle(node, style);
    this.graph.setNodeLayout(
      node,
      new Rect(
        node.layout.topLeft,
        getNodeLayoutSize({
          styleWidth: width,
          styleHeight: height,
          hasContextHighlight: isContextNode(node),
          contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
          visualDiffType:
            node.tag instanceof GraphItem
              ? node.tag.getVisualDiffType()
              : DiffType.NONE,
        })
      )
    );
  }
  private addToScenarioButton(owner: INode, graphNode: GraphItem) {
    this.graph.addLabel({
      owner,
      text: '',
      layoutParameter: addToScenarioNodeButtonModelParameter,
      style: addToScenarioButtonStyle,
      tag: graphNode,
    });
  }
}
