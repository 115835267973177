import { PARENT_CHILD_REFERENCE } from '@ardoq/global-consts';
import {
  type LegendReferenceType,
  isParentChildReferenceModelId,
} from '@ardoq/graph';
import type {
  RelationshipsLink,
  RelationshipsLinkVisual,
  RelationshipsNode,
} from '../types';
import { referenceInterface } from '@ardoq/reference-interface';

export const getMatchingFilterIdsFilter =
  (ids: Set<string>) => (item: RelationshipsNode | RelationshipsLinkVisual) =>
    item.modelId && item.matchingFilterIds?.some(id => ids.has(id));

export const getReferenceTypesFilter = (
  referenceTypes: LegendReferenceType[]
) => {
  const referenceTypeIds = new Set(
    referenceTypes.flatMap(({ globalTypeIds }) => globalTypeIds)
  );
  return (link: RelationshipsLink) => {
    if (!link.modelId) {
      return false;
    }
    const referenceTypeModelId = referenceInterface.getGlobalTypeId(
      link.modelId
    );
    if (referenceTypeModelId === null) {
      return (
        isParentChildReferenceModelId(link.modelId) &&
        referenceTypes.some(({ globalTypeIds }) =>
          globalTypeIds.includes(PARENT_CHILD_REFERENCE)
        )
      );
    }
    return referenceTypeIds.has(referenceTypeModelId);
  };
};

export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return hash;
};
