import { getAttributesOfModel } from 'modelInterface/genericInterfaces';
import { isDateFieldType } from '@ardoq/date-time';
import {
  APIEntityType,
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  FieldGlobalAttributes,
  WorkspaceFieldUsage,
} from '@ardoq/api-types';
import { workspaceInterface } from '@ardoq/workspace-interface';
import Fields from 'collections/fields';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { ModelSaveOptions } from 'backbone';
import { logError } from '@ardoq/logging';
import type {
  GetFieldDataForFiltersAndFormatting,
  GetIsExistingFieldInWorkspace,
  GetReferenceTypes,
  ExistsByName,
  GetCombinedWorkspaceUsages,
  GetFieldsCount,
  GetFieldAttributes,
  IsExternallyManagedForEntityTypeWithinWorkspace,
  GetAllFieldsOfWorkspace,
  GetFieldsOfWorkspace,
  GetAllowedValues,
  GetAttributes,
  GetComponentTypes,
  Format,
  GetLabel,
  AppliesToComponent,
  GetDescription,
  GetByName,
  GetNumberFormatByName,
  GetUsageWorkspaces,
  GetCombinedUsageWorkspaces,
  GetCombinedUsages,
  GetModelId,
  IsDateRangeField,
  HasComponentType,
  HasReferenceType,
  IsExternallyManagedForComponentsWithinWorkspace,
  IsExternallyManagedForReferencesWithinWorkspace,
  IsExternallyManagedWithinWorkspace,
  GetFieldData,
  GetUrlFieldNames,
  GetFieldIdsByComponentType,
  GetFieldIdsByReferenceType,
  GetFieldsByComponentType,
  GetCalculatedFieldsFromWorkspace,
  AddToCollection,
} from '@ardoq/field-interface';
import { get as getGeneric } from 'collectionInterface/genericInterfaces';
import { getCollectionForEntityType } from 'collectionInterface/utils';
import { dateRangeOperations } from '@ardoq/date-range';
import Field from '../../models/field';
import { referenceInterface } from '@ardoq/reference-interface';
import { componentInterface } from '@ardoq/component-interface';
import { fieldApi } from '@ardoq/api';
import { getCurrentLocale, localeAreEqualLowercase } from '@ardoq/locale';
import { fieldUtils } from '@ardoq/scope-data';
import { formatUserField } from 'modelInterface/formatUserField';

/**
 * Check if a field exists in a workspace by its label
 */
const getIsExistingFieldInWorkspace: GetIsExistingFieldInWorkspace = (
  fieldLabel,
  workspaceId
) => {
  const locale = getCurrentLocale();
  const searchLabel = fieldLabel.trim();

  return getAllBackboneFieldsOfWorkspace(workspaceId).some(workspaceField => {
    const workspaceFieldLabel = workspaceField.getLabel().trim();

    if (
      dateRangeOperations.fieldLabelIsPartOfDateRangeField(workspaceFieldLabel)
    ) {
      const dateRangeFieldLabel =
        dateRangeOperations.extractDateRangeFieldLabel(workspaceFieldLabel);

      return localeAreEqualLowercase(dateRangeFieldLabel, searchLabel, locale);
    }

    return localeAreEqualLowercase(workspaceFieldLabel, searchLabel, locale);
  });
};

const getAllFieldsOfWorkspace: GetAllFieldsOfWorkspace = workspaceId => {
  return getAllBackboneFieldsOfWorkspace(workspaceId).map(field =>
    structuredClone(field.attributes)
  );
};

const getAllBackboneFieldsOfWorkspace = (workspaceId: ArdoqId) => {
  const model = workspaceInterface.getModelData(workspaceId);
  return model ? Fields.collection.getByModel(model._id) : [];
};

const getFieldsOfWorkspace: GetFieldsOfWorkspace = (workspaceId, fieldTypes) =>
  getAllFieldsOfWorkspace(workspaceId).filter(field =>
    fieldTypes.includes(field.type)
  );

const getAllowedValues: GetAllowedValues = fieldId => {
  const field = Fields.collection.get(fieldId);
  return field ? field.getAllowedValues() : [];
};

const getAttributes: GetAttributes = getAttributesOfModel<APIFieldAttributes>(
  Fields.collection
);

const getComponentTypes: GetComponentTypes = fieldId => {
  const field = Fields.collection.get(fieldId);
  return field ? field.get('componentType') : [];
};

const getReferenceTypes: GetReferenceTypes = fieldId => {
  const field = Fields.collection.get(fieldId);
  return field ? field.get('referenceType') : [];
};

const format: Format = ({
  fieldId,
  value,
  fieldType,
  excludeTime,
  textAreaFormat,
}) => {
  // For date range fields, is better to pass the fieldType explicitly when possible. Because if you look up the
  // date range field id in the fields collection, it will return the start date field, which is a date time field.

  const fieldAttributes = Fields.collection.get(fieldId)?.attributes;

  if (!fieldAttributes) {
    return null;
  }

  return fieldUtils.fieldAttributesToLabel({
    fieldAttributes,
    fieldType,
    value,
    excludeTime,
    textAreaFormat,
    formatUserFieldFn: formatUserField,
  });
};

const getLabel: GetLabel = fieldId => {
  const field = Fields.collection.get(fieldId);
  return field ? field.getRawLabel() : null;
};

const appliesToComponent: AppliesToComponent = (fieldId, componentId) => {
  const field = Fields.collection.get(fieldId);
  const fieldComponentTypeIds = field ? field.getComponentTypes() : [];
  const fieldModelId = field ? field.getModelId() : '';
  const isGlobal = field ? field.get('global') : false;
  const componentTypeId = componentInterface.getTypeId(componentId);
  const componentModelId = componentInterface.getModelId(componentId);
  return field && componentTypeId && componentModelId
    ? fieldModelId === componentModelId &&
        (isGlobal || fieldComponentTypeIds.includes(componentTypeId))
    : false;
};

const getDescription: GetDescription = fieldId => {
  const field = Fields.collection.get(fieldId);
  return field ? field.getDescription() : null;
};

const getByName: GetByName = (fieldName, options) => {
  const field = Fields.collection.getByName(fieldName, options);
  return field ? field.toJSON() : null;
};

const existsByName: ExistsByName = (fieldName, options) => {
  const field = Fields.collection.getByName(fieldName, options);
  return Boolean(field);
};

const getNumberFormatByName: GetNumberFormatByName = fieldName => {
  const field = getByName(fieldName, {
    acrossWorkspaces: true,
    includeTemplateFields: false,
  });

  if (field === null) return null;
  if (field.type !== APIFieldType.NUMBER) return null;

  return field.numberFormatOptions ?? null;
};

const getUsageWorkspaces: GetUsageWorkspaces = async fieldId => {
  if (!fieldId) return [];
  const result = await fieldApi.usage(fieldId);
  if (isArdoqError(result)) {
    return result;
  }
  return result.workspaces.map(workspace => workspace.workspaceId);
};
const getCombinedUsageWorkspaces: GetCombinedUsageWorkspaces =
  async fieldIds => {
    const results = (
      await Promise.all(fieldIds.map(fieldId => getUsageWorkspaces(fieldId)))
    ).flat();
    const workspaces = [];
    for (const result of results) {
      if (isArdoqError(result)) {
        return result;
      }
      workspaces.push(result);
    }
    return workspaces;
  };

const sumRecordEntries = (
  a: Record<string, number>,
  b: Record<string, number>
) => {
  const result: Record<string, number> = {};
  const keys = Object.keys(a);
  keys.forEach(key => {
    result[key] = a[key] + (b[key] || 0);
  });
  const keySet = new Set<string>(keys);
  Object.keys(b)
    .filter(key => !keySet.has(key))
    .forEach(key => (result[key] = b[key]));
  return result;
};
const combineWorkspaceFieldUsages = (
  a: WorkspaceFieldUsage,
  b: WorkspaceFieldUsage
): WorkspaceFieldUsage => {
  return {
    workspaceId: a.workspaceId,
    componentCount: a.componentCount + b.componentCount,
    componentTypes: sumRecordEntries(a.componentTypes, b.componentTypes),
    referenceCount: a.referenceCount + b.referenceCount,
    referenceTypes: sumRecordEntries(a.referenceTypes, b.referenceTypes),
    surveyCount: a.surveyCount + b.surveyCount,
    affectedComponentIds: [
      ...new Set(a.affectedComponentIds.concat(b.affectedComponentIds)),
    ],
    componentIds: [...new Set(a.componentIds.concat(b.componentIds))],
    referenceIds: [...new Set(a.referenceIds.concat(b.referenceIds))],
    affectedReferenceIds: [
      ...new Set(a.affectedReferenceIds.concat(b.affectedReferenceIds)),
    ],
  };
};

const getCombinedUsages: GetCombinedUsages = async fieldIds => {
  const results = await Promise.all(
    fieldIds.map(async fieldId => {
      return await fieldApi.usage(fieldId);
    })
  );
  const allUsages = [];
  for (const result of results) {
    if (isArdoqError(result)) {
      return result;
    }
    allUsages.push(result);
  }

  const combinedUsages = new Map<string, WorkspaceFieldUsage>();
  allUsages.forEach(usage => {
    usage.workspaces.forEach(usageWorkspace => {
      const workspaceId = usageWorkspace.workspaceId;
      if (!combinedUsages.has(workspaceId)) {
        combinedUsages.set(workspaceId, usageWorkspace);
      } else {
        combinedUsages.set(
          workspaceId,
          combineWorkspaceFieldUsages(
            combinedUsages.get(workspaceId)!,
            usageWorkspace
          )
        );
      }
    });
  });
  return [...combinedUsages.values()];
};

const getCombinedWorkspaceUsages: GetCombinedWorkspaceUsages =
  async fieldIds => {
    const fields = fieldIds
      .map(fieldId => Fields.collection.get(fieldId))
      .filter(ExcludeFalsy);

    const modelId = fields[0]?.get('model');

    if (!fields.every(field => field.get('model') === modelId)) {
      logError(
        Error('Fields of different models in getCombinedWorkspaceUsages')
      );
    }

    const results = await Promise.all(
      fieldIds.map(async fieldId => {
        return await fieldApi.localUsage(fieldId);
      })
    );

    const allUsages = [];
    for (const result of results) {
      if (isArdoqError(result)) {
        return result;
      }
      allUsages.push(result);
    }

    return allUsages.reduce(
      (combinedUsages, usageWorkspace) =>
        usageWorkspace
          ? combineWorkspaceFieldUsages(combinedUsages, usageWorkspace)
          : combinedUsages,
      {
        workspaceId: '',
        componentCount: 0,
        componentTypes: {},
        referenceCount: 0,
        referenceTypes: {},
        surveyCount: 0,
        affectedComponentIds: [],
        componentIds: [],
        referenceIds: [],
        affectedReferenceIds: [],
      }
    );
  };

const getModelId: GetModelId = fieldId => {
  const field = Fields.collection.get(fieldId);
  return field ? field.getModelId() : null;
};

const isDateRangeField: IsDateRangeField = fieldName => {
  if (!dateRangeOperations.fieldNameIsPartOfDateRangeField(fieldName))
    return false;
  const field = getByName(fieldName, {
    acrossWorkspaces: true,
    includeTemplateFields: true,
  });

  if (
    field &&
    (!isDateFieldType(field.type) ||
      !dateRangeOperations.fieldLabelIsPartOfDateRangeField(field.label))
  ) {
    return false;
  }
  let otherField = null;
  const dateRangeFieldName =
    dateRangeOperations.extractDateRangeFieldName(fieldName);
  if (dateRangeOperations.fieldNameIsDateRangeStartField(fieldName)) {
    otherField = getByName(
      dateRangeOperations.toEndDateName(dateRangeFieldName),
      {
        acrossWorkspaces: true,
        includeTemplateFields: true,
      }
    );
  } else if (dateRangeOperations.fieldNameIsDateRangeEndField(fieldName)) {
    otherField = getByName(
      dateRangeOperations.toStartDateName(dateRangeFieldName),
      {
        acrossWorkspaces: true,
        includeTemplateFields: true,
      }
    );
  }
  return isDateFieldType(otherField?.type);
};

const hasComponentType: HasComponentType = (fieldId, componentTypeId) => {
  const field = Fields.collection.get(fieldId)!;
  return Boolean(field?.hasComponentType(componentTypeId));
};

const hasReferenceType: HasReferenceType = (fieldId, referenceTypeId) => {
  const field = Fields.collection.get(fieldId);
  return Boolean(field?.hasReferenceType(referenceTypeId));
};
const isExternallyManagedForEntityTypeWithinWorkspace: IsExternallyManagedForEntityTypeWithinWorkspace =
  (entityType, fieldName, workspaceId) =>
    entityType === APIEntityType.REFERENCE
      ? isExternallyManagedForReferencesWithinWorkspace(fieldName, workspaceId)
      : isExternallyManagedForComponentsWithinWorkspace(fieldName, workspaceId);

const isExternallyManagedForComponentsWithinWorkspace: IsExternallyManagedForComponentsWithinWorkspace =
  (fieldName, workspaceId) =>
    workspaceInterface
      .getManagedComponentFields(workspaceId)
      .includes(fieldName);

const isExternallyManagedForReferencesWithinWorkspace: IsExternallyManagedForReferencesWithinWorkspace =
  (fieldName, workspaceId) =>
    workspaceInterface
      .getManagedReferenceFields(workspaceId)
      .includes(fieldName);

const isExternallyManagedWithinWorkspace: IsExternallyManagedWithinWorkspace = (
  fieldName,
  workspaceId
) =>
  isExternallyManagedForComponentsWithinWorkspace(fieldName, workspaceId) ||
  isExternallyManagedForReferencesWithinWorkspace(fieldName, workspaceId);

const getFieldData: GetFieldData = fieldId => {
  const field = Fields.collection.get(fieldId);
  return field ? field.toJSON() : null;
};

const getUrlFieldNames: GetUrlFieldNames = () =>
  new Set(
    Fields.collection
      .filter(field => field.getType() === APIFieldType.URL)
      .map(field => field.name())
  );

const getFieldIdsByComponentType: GetFieldIdsByComponentType = (
  componentTypeId: string,
  modelId: string
) =>
  Fields.collection
    .getByComponentType(componentTypeId, modelId)
    .map(({ id }) => id);

const getFieldsByComponentType: GetFieldsByComponentType = (
  componentTypeId: string,
  modelId: string
) =>
  Fields.collection
    .getByComponentType(componentTypeId, modelId)
    .map(field => field.toJSON());

const getFieldIdsByReferenceType: GetFieldIdsByReferenceType = (
  referenceTypeId,
  modelId
) =>
  Fields.collection
    .getByReferenceType(referenceTypeId, modelId)
    .map(({ id }) => id);

const getCalculatedFieldsFromWorkspace: GetCalculatedFieldsFromWorkspace =
  workspaceId =>
    Fields.collection
      .getAllWorkspaceFields(workspaceId)
      .filter(field => field.isCalculated())
      .map(field => structuredClone(field.attributes));

const save = (
  id: ArdoqId,
  attributes?: Partial<APIFieldAttributes>,
  options?: ModelSaveOptions
) => Fields.collection.get(id)?.save(attributes, options);

const deleteField = (id: ArdoqId) => Fields.collection.get(id)?.destroy();

const deleteFieldsGlobally = (ids: ArdoqId[]) => {
  Fields.collection.remove(ids.map(id => Fields.collection.get(id)));
  fieldApi.deleteGlobally(ids[0]);
};

const getFieldsCount: GetFieldsCount = () => {
  return Fields.collection.length;
};

const getFieldDataForFiltersAndFormatting: GetFieldDataForFiltersAndFormatting =
  (workspaceIds: ArdoqId[]) => {
    const modelIds = workspaceIds.map(workspaceInterface.getWorkspaceModelId);
    const modelIdsSet = new Set(modelIds);
    const relatedFieldsByName: Record<string, FieldGlobalAttributes> = {};
    const componentFields: FieldGlobalAttributes[] = [];
    const referenceFields: FieldGlobalAttributes[] = [];

    Fields.collection.forEach(({ attributes }) => {
      const { model, name } = attributes;
      const belongsToRelatedWorkspace = modelIdsSet.has(model);

      if (relatedFieldsByName[model] && !belongsToRelatedWorkspace) {
        return;
      }

      const field = pickGlobalFieldAttributes(attributes);
      relatedFieldsByName[name] = field;

      if (belongsToRelatedWorkspace) {
        if (isComponentField(attributes)) {
          componentFields.push(field);
        }

        if (isReferenceField(attributes)) {
          referenceFields.push(field);
        }
      }
    });

    return {
      relatedFieldsByName,
      componentFields,
      referenceFields,
    };
  };

const isComponentField = ({ global, componentType }: APIFieldAttributes) =>
  Boolean(global || componentType.length > 0);

const isReferenceField = ({ globalref, referenceType }: APIFieldAttributes) =>
  Boolean(globalref || referenceType.length > 0);

const pickGlobalFieldAttributes = ({
  calculatedFieldSettings,
  defaultValue,
  description,
  label,
  name,
  type,
  numberFormatOptions,
  model,
}: APIFieldAttributes): FieldGlobalAttributes => ({
  calculatedFieldSettings,
  defaultValue,
  description,
  label,
  name,
  type,
  numberFormatOptions,
  model,
});

const get: GetFieldAttributes = id => getGeneric(APIEntityType.FIELD, id);

const create = (
  attributes: Partial<APIFieldAttributes>,
  options?: ModelSaveOptions
) =>
  getCollectionForEntityType(APIEntityType.FIELD).create(attributes, options);

const addToCollection: AddToCollection = (attributes: APIFieldAttributes) => {
  Fields.collection.add(Field.create(attributes));
};

const getFieldValueForComponentOrReference = (
  modelId: ArdoqId,
  fieldName: string
) =>
  referenceInterface.isReference(modelId)
    ? referenceInterface.getFieldValue(modelId, fieldName)
    : componentInterface.getAttribute(modelId, fieldName);

const formatFieldValueForComponentOrReference = (
  fieldId: ArdoqId,
  modelId: ArdoqId
) => {
  const fieldAttributes = Fields.collection.get(fieldId)?.attributes;
  if (!fieldAttributes?.name) {
    return null;
  }

  const fieldValue = getFieldValueForComponentOrReference(
    modelId,
    fieldAttributes.name
  );

  return fieldUtils.fieldAttributesToLabel({
    fieldAttributes,
    value: fieldValue,
    excludeTime: true,
    formatUserFieldFn: formatUserField,
    textAreaFormat: 'text',
  });
};

const isFieldAllowedInSurvey = (fieldId: ArdoqId) => {
  const field = Fields.collection.get(fieldId);
  return !field?.get('plugin') && field?.attributes.type !== APIFieldType.FILE;
};

export const fieldInterface = {
  getIsExistingFieldInWorkspace,
  getAllFieldsOfWorkspace,
  getFieldsOfWorkspace,
  getAllowedValues,
  getAttributes,
  getComponentTypes,
  getReferenceTypes,
  format,
  getLabel,
  appliesToComponent,
  getDescription,
  getByName,
  existsByName,
  getNumberFormatByName,
  getUsageWorkspaces,
  getCombinedUsageWorkspaces,
  getCombinedUsages,
  getCombinedWorkspaceUsages,
  getModelId,
  isDateRangeField,
  hasComponentType,
  hasReferenceType,
  isExternallyManagedForEntityTypeWithinWorkspace,
  isExternallyManagedForComponentsWithinWorkspace,
  isExternallyManagedForReferencesWithinWorkspace,
  isExternallyManagedWithinWorkspace,
  getFieldData,
  getUrlFieldNames,
  getFieldIdsByComponentType,
  getFieldsByComponentType,
  getFieldIdsByReferenceType,
  getCalculatedFieldsFromWorkspace,
  save,
  deleteField,
  deleteFieldsGlobally,
  getFieldsCount,
  getFieldDataForFiltersAndFormatting,
  pickGlobalFieldAttributes,
  get,
  create,
  addToCollection,
  getFieldValueForComponentOrReference,
  formatFieldValueForComponentOrReference,
  isFieldAllowedInSurvey,
};
