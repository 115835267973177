import { componentInterface } from '@ardoq/component-interface';
import { ComponentsData, ReferencesMap } from '../types';
import { referenceInterface } from '@ardoq/reference-interface';

export const getReferences = (
  componentsData: ComponentsData,
  allTargetWorkspaceComponents: Set<string>
): ReferencesMap =>
  Object.fromEntries(
    componentsData.componentIds
      .map(id => [
        id,
        componentInterface
          .getTargets(id)
          .filter(
            ({ componentId, referenceId }) =>
              allTargetWorkspaceComponents.has(componentId) &&
              referenceInterface.isIncludedInContextByFilter(referenceId)
          ),
      ])
      .filter(([_, targetReferences]) => targetReferences.length > 0)
  );
