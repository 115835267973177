import { darken, getLuminance, lighten } from 'polished';
import { colors } from '@ardoq/design-tokens';

/** gets a contrasty text color according to the specified bg color */
export const getTextColor = (color: string) => {
  if (color === '' || color === 'transparent') {
    return colors.black;
  }
  if (color === colors.white) {
    return colors.grey25;
  }

  const luminance = getLuminance(color);

  return luminance > 0.179
    ? 'rgba(0, 0, 0, 0.75)'
    : 'rgba(255, 255, 255, 0.85)';
};

export const getHoverColor = (baseColor: string) => {
  return getLuminance(baseColor) > 0.179
    ? darken(0.02, baseColor)
    : lighten(0.08, baseColor);
};
