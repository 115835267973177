import { GlobalReferenceTypeId } from '@ardoq/data-model';
import { ArdoqId, ViewIds } from '@ardoq/api-types';
import Fields from 'collections/fields';
import Filters from 'collections/filters';
import Components from 'collections/components';
import Context from 'context';
import {
  componentProps,
  emptyState,
  extractCustomFields,
  fieldHeaderProps,
  setupHeaders,
  updateSort,
} from './common/utils';
import {
  resetExpandedDescriptionsReducer,
  toggleDescriptionReducer,
} from './common/commonReducers';
import { notifySortChanged } from 'streams/context/ContextActions';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsUpdated,
  sortComponents,
  sortComponentsByReferences,
} from 'streams/components/ComponentActions';
import { notifyFiltersChanged } from 'streams/filters/FilterActions';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from 'streams/fields/FieldActions';

import { relevantComponentsFromContextShape } from '../componentReducers';
import { isUnique } from 'utils/collectionUtil';
import { ContextSort, NumericSortOrder } from '@ardoq/common-helpers';
import * as modelUtils from 'models/utils/modelUtils';
import { referenceInterface } from '@ardoq/reference-interface';
import logMissingModel from 'models/logMissingModel';
import { ComponentBackboneModel } from 'aqTypes';
import {
  CellTypes,
  HeaderModel,
  HeaderType,
  LinkedComponents,
  TableViewModel,
  TableViewRow,
} from './common/types';
import {
  reducer,
  action$,
  ofType,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { GraphModelShape, LinkedComponent } from '@ardoq/data-model';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, startWith, withLatestFrom } from 'rxjs/operators';
import * as profiling from '@ardoq/profiling';
import { logError, logWarn } from '@ardoq/logging';
import { componentInterface } from '@ardoq/component-interface';
import graphModel$ from 'modelInterface/graphModel$';
import { withNamespaceOrNullNamespace } from 'streams/utils/streamOperators';
import { context$ } from 'streams/context/context$';
import { startAction } from 'actions/utils';
import {
  getCurrentLocale,
  localeCompare,
  localeCompareNumericLowercase,
} from '@ardoq/locale';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { APIFieldAttributes } from '@ardoq/api-types';
import { dateRangeOperations } from '@ardoq/date-range';
import { ExtractStreamShape } from 'tabview/types';

const ARDOQ_COMPONENT_PATH_PROPERTY_KEY = 'ardoq-component-path';

const defaultHeaders: HeaderModel[] = [
  {
    key: 'component-key',
    label: 'Ardoq ID',
    cellType: CellTypes.TEXT,
    isExpandController: false,
  },
  {
    key: 'id',
    label: 'Ardoq OID',
    cellType: CellTypes.TEXT,
    isExpandController: false,
  },
  {
    key: 'name',
    label: 'Name',
    cellType: CellTypes.NAME,
    isExpandController: true,
  },
  {
    key: ARDOQ_COMPONENT_PATH_PROPERTY_KEY,
    label: 'Path',
    cellType: CellTypes.PATH,
    isExpandController: false,
  },
  {
    key: 'parent',
    label: 'Parent',
    cellType: CellTypes.PARENT,
    isExpandController: false,
  },
  {
    key: 'type',
    label: 'Component type',
    cellType: CellTypes.TEXT,
    isExpandController: false,
  },
  {
    key: 'created',
    label: 'Created at',
    cellType: CellTypes.DATE_TIME,
    isExpandController: false,
  },
  {
    key: 'last-updated',
    label: 'Updated at',
    cellType: CellTypes.DATE_TIME,
    isExpandController: false,
  },
];

const hasData = () => Boolean(Context.activeWorkspaceId());

const componentPath = (component: ComponentBackboneModel) => {
  const parents = modelUtils.getComponentAncestry(component);
  const workspace = component.getWorkspace();
  if (!workspace) {
    logError(Error('Component workspace not found.'), null, {
      rootWorkspace: component.get('rootWorkspace'),
    });
  }
  return [
    {
      type: 'workspace',
      name: workspace?.get('name'),
    },
    ...parents.map(componentProps),
  ];
};

type GroupMap = Map<ArdoqId, Record<string, LinkedComponents>>;

type ComponentGroups = {
  keys: KeyMap;
  groups: GroupMap;
};

const toRow =
  ({
    fields,
    compGroups,
  }: {
    fields: APIFieldAttributes[];
    compGroups: GroupMap;
  }) =>
  (component: ComponentBackboneModel): TableViewRow => {
    const parentComponent = component.getParent();
    return {
      [ARDOQ_COMPONENT_PATH_PROPERTY_KEY]: componentPath(component),
      hasDescription: Boolean(component.get('description')),
      markdownDescription: component.get('description') || '',
      parent: parentComponent ? componentProps(parentComponent) : undefined,
      ...componentProps(component),
      ...(compGroups.get(component.id) || {}),
      ...extractCustomFields(fields, component),
      expandDescription: false,
    };
  };

const fields = (component: ComponentBackboneModel) => {
  const model = component.getMyModel();
  if (!model) {
    logMissingModel({
      id: component.id,
      rootWorkspace: component.get('rootWorkspace'),
      modelTypeName: 'component',
    });
    return { fields: [], createdDateRangeFields: [] };
  }
  return dateRangeOperations.mergeDateTimeFieldsToDateRangeFields(
    Fields.collection
      .getByModel(model.id)
      .toArray()
      .filter(field => field.isComponentField())
      .map(field => field.attributes)
  );
};

type KeyMap = Map<
  GlobalReferenceTypeId,
  {
    name: string;
    id: GlobalReferenceTypeId;
    outgoing: Set<ArdoqId>;
    incoming: Set<ArdoqId>;
  }
>;

const OUTGOING = 'outgoing';
const INCOMING = 'incoming';

type DIRECTION = typeof OUTGOING | typeof INCOMING;

const groupComponentsByReferenceTypes = (
  components: ComponentBackboneModel[],
  graphModel: GraphModelShape
) =>
  components.reduce<ComponentGroups>(
    (groupMap: ComponentGroups, component: ComponentBackboneModel) => {
      const linkedComps = new Map<string, LinkedComponents>();
      const componentId = component.id;

      populateGroup(
        graphModel.sourceMap,
        componentId,
        groupMap,
        OUTGOING,
        linkedComps
      );

      populateGroup(
        graphModel.targetMap,
        componentId,
        groupMap,
        INCOMING,
        linkedComps
      );

      const locale = getCurrentLocale();
      Array.from(linkedComps.values()).forEach(currentLinkedComps => {
        currentLinkedComps.value.sort((a, b) =>
          localeCompareNumericLowercase(a.name, b.name, locale)
        );
        currentLinkedComps.referencedComponentsAsString =
          currentLinkedComps.value.map(comp => comp.name).join();
      });

      groupMap.groups.set(componentId, Object.fromEntries(linkedComps));

      return groupMap;
    },
    {
      keys: new Map(),
      groups: new Map<string, Record<string, LinkedComponents>>(),
    }
  );

const populateGroup = (
  linkedMap: Map<ArdoqId, LinkedComponent[]>,
  componentId: string,
  groupMap: ComponentGroups,
  direction: DIRECTION,
  linkedComps: Map<string, LinkedComponents>
) =>
  (linkedMap.get(componentId) || []).forEach(
    ({ referenceId, componentId: linkedComponentId }) => {
      if (!referenceInterface.isIncludedInContextByFilter(referenceId)) {
        return;
      }
      const referenceType =
        referenceInterface.getGlobalReferenceType(referenceId);
      const name = referenceType?.name ?? '';
      const id = referenceType?.id ?? '';
      let group = groupMap.keys.get(id);
      if (!group) {
        group = {
          name,
          id,
          [OUTGOING]: new Set(),
          [INCOMING]: new Set(),
        };
        groupMap.keys.set(id, group);
      }
      group[direction].add(linkedComponentId);

      accumulateLinkedComponent(
        name,
        direction,
        linkedComps,
        linkedComponentId
      );
    }
  );

const accumulateLinkedComponent = (
  name: string,
  direction: DIRECTION,
  acc: Map<string, LinkedComponents>,
  linkedComponentId: ArdoqId
) => {
  const key = getKey(name, direction);

  const component = Components.collection.get(linkedComponentId);
  if (!component) {
    logWarn(Error('Component not found in table view reducers'));
    // We should never enter here, but we do, basically because we are
    // mixing streams and backbone event driven updates.
    // The application will recover because the according relevant updates will
    // be triggered on the correct channel, but we do obviously too much
    // usless data allocations and we run into inconsitent
    // model states.
    //
    // Currently this happens with big datasets in presentations.
    //
    // Issue is tracked in ARD-3919.
    return;
  }
  const linkedComponent = componentProps(component);

  let linkedComponents = acc.get(key);
  if (!linkedComponents) {
    linkedComponents = {
      isDisabled: false,
      value: [],
      referencedComponentsAsString: '',
    };
    acc.set(key, linkedComponents);
  }
  linkedComponents.value.push(linkedComponent);
};

const getKey = (name: string, direction: DIRECTION) =>
  `${name}(${direction === OUTGOING ? 'out' : 'in'})`;

const getReferenceTypesFromGroups = (groupMap: KeyMap) => {
  const locale = getCurrentLocale();

  return Array.from(groupMap.values())
    .flatMap(({ id, name, outgoing, incoming }) => [
      incoming.size > 0 && {
        label: name,
        key: getKey(name, INCOMING),
        id,
        type: HeaderType.SOURCE,
        cellType: CellTypes.REFERENCE_LIST,
        order: null,
      },
      outgoing.size > 0 && {
        label: name,
        key: getKey(name, OUTGOING),
        id,
        type: HeaderType.TARGET,
        cellType: CellTypes.REFERENCE_LIST,
        order: null,
      },
    ])
    .filter(ExcludeFalsy)
    .sort((a, b) => localeCompare(a.key, b.key, locale));
};

const sortRowsByReferences = (
  rows: (TableViewRow &
    Record<string, { referencedComponentsAsString: string }>)[],
  sort: ContextSort
) => {
  const locale = getCurrentLocale();
  return rows.sort((a, b) => {
    const aValue = a[sort.attr]
      ? a[sort.attr].referencedComponentsAsString
      : '';
    const bValue = b[sort.attr]
      ? b[sort.attr].referencedComponentsAsString
      : '';
    if (!aValue.length && !bValue.length) {
      return localeCompareNumericLowercase(a.name, b.name, locale);
    } else if (!aValue.length) {
      return 1;
    } else if (!bValue.length) {
      return -1;
    }
    return (
      localeCompareNumericLowercase(aValue, bValue, locale) *
      (sort.order === NumericSortOrder.ON_REFERENCE_DESCENDING ? -1 : 1)
    );
  });
};

type NextStateArgs = {
  components: ComponentBackboneModel[];
  sort: ContextSort;
  graphModel: GraphModelShape;
  hasComponentsAvailable: boolean;
  contextComponentId?: ArdoqId;
};
const nextState = ({
  components,
  sort,
  graphModel,
  hasComponentsAvailable,
  contextComponentId,
}: NextStateArgs): TableViewModel => {
  if (components.length === 0) {
    return {
      ...emptyState,
      rows: hasData() ? [] : null,
      hasComponentsAvailable,
      contextComponentId,
    };
  }
  const transaction = profiling.startTransaction(
    'table view model calculation',
    500,
    profiling.Team.INSIGHT
  );
  const filteredComponents = components.filter(c =>
    Filters.isIncludedInContextByFilter(c)
  );

  const compGroups = groupComponentsByReferenceTypes(
    filteredComponents,
    graphModel
  );

  const referenceTypes = getReferenceTypesFromGroups(compGroups.keys);
  // The compareBackboneModels function expects a comma separated string for date range field names. This gets used when you click
  // a column header in the table view to sort the data by that column.
  const customFields = fields(components[0]).fields.map(field =>
    dateRangeOperations.isDateRangeField(field)
      ? {
          ...field,
          name: `${field.dateTimeFields.start.name},${field.dateTimeFields.end.name}`,
        }
      : field
  );
  const rows: TableViewRow[] = filteredComponents.map(
    toRow({
      fields: customFields,
      compGroups: compGroups.groups,
    })
  );
  const filteredReferenceTypes = referenceTypes
    .filter(isUnique('key'))
    .map(refType => ({
      ...refType,
      isExpandController: false,
    }));

  const newState = {
    ...emptyState,
    hasComponentsAvailable,
    contextComponentId,
    defaultHeaders: setupHeaders(sort, defaultHeaders),
    referenceTypeHeaders: setupHeaders(sort, filteredReferenceTypes),
    fieldHeaders: setupHeaders(sort, customFields.map(fieldHeaderProps)),
    rows:
      (sort.order === NumericSortOrder.ON_REFERENCE_ASCENDING ||
        sort.order === NumericSortOrder.ON_REFERENCE_DESCENDING) &&
      filteredReferenceTypes.find(ref => ref.key === sort.attr)
        ? sortRowsByReferences(rows, sort)
        : rows,
  };
  profiling.endTransaction(transaction, {
    viewId: ViewIds.TABLEVIEW,
    metadata: {
      numberOfHeaders:
        newState.defaultHeaders.length +
        newState.referenceTypeHeaders.length +
        newState.fieldHeaders.length,
      numberofRows: rows ? rows.length : 0,
    },
  });
  return newState;
};

const changes$ = combineLatest([
  graphModel$,
  context$,
  action$.pipe(
    ofType(
      notifyComponentsUpdated,
      notifyComponentsRemoved,
      notifyFieldRemoved,
      notifyFieldUpdated,
      notifyFieldAdded,
      notifyFiltersChanged,
      notifySortChanged
    ),
    startWith(startAction())
  ),
]).pipe(
  debounceTime(50) // debounce this to avoid repeated resets when batch editing components in grid editor
);
const resetOnChanges = (
  _: TableViewModel,
  [graphModel, context]: ExtractStreamShape<typeof changes$>
) => {
  const { hasComponentsAvailable, relevantComponents } =
    relevantComponentsFromContextShape(context);

  return nextState({
    components: relevantComponents,
    sort: context.sort,
    graphModel,
    hasComponentsAvailable,
    contextComponentId: context.componentId,
  });
};

const componentAdded$ = action$.pipe(
  ofType(notifyComponentsAdded),
  withLatestFrom(graphModel$, context$)
);
const resetOnComponentAdded = (
  state: TableViewModel,
  [{ payload }, graphModel, context]: ExtractStreamShape<typeof componentAdded$>
) => {
  const parentIds = payload.componentIds.map(componentInterface.getParentId);

  const isAddedComponentInView =
    !context.componentId ||
    parentIds.some(parentId => context.componentId === parentId);

  if (!isAddedComponentInView) return state;

  const { relevantComponents, hasComponentsAvailable } =
    relevantComponentsFromContextShape(context);

  return nextState({
    components: relevantComponents,
    sort: context.sort,
    graphModel,
    hasComponentsAvailable,
    contextComponentId: context.componentId,
  });
};

const updateHeadersSortReducer = (
  state: TableViewModel,
  { attr, order }: ContextSort
): TableViewModel => {
  const doUpdateSort = updateSort(attr, order);
  state.defaultHeaders.forEach(doUpdateSort);
  state.referenceTypeHeaders.forEach(doUpdateSort);
  state.fieldHeaders.forEach(doUpdateSort);
  return {
    ...state,
  };
};

const sortComponentsByReferencesReducer = (
  state: TableViewModel,
  payload: ContextSort
): TableViewModel => {
  const doUpdateSort = updateSort(payload.attr, payload.order);
  state.defaultHeaders.forEach(doUpdateSort);
  state.referenceTypeHeaders.forEach(doUpdateSort);
  state.fieldHeaders.forEach(doUpdateSort);
  return {
    ...state,
    rows: sortRowsByReferences(
      state.rows as (TableViewRow &
        Record<string, { referencedComponentsAsString: string }>)[],
      payload
    ),
  };
};

export const createViewModel$ = (
  viewInstanceId: string
): Observable<TableViewModel> => {
  return action$.pipe(
    withNamespaceOrNullNamespace(viewInstanceId),
    reduceState<TableViewModel>('tableViewModel$', emptyState, [
      reducer(sortComponents, updateHeadersSortReducer),
      reducer(sortComponentsByReferences, sortComponentsByReferencesReducer),
      toggleDescriptionReducer,
      resetExpandedDescriptionsReducer(ViewIds.TABLEVIEW),
      streamReducer(changes$, resetOnChanges),
      streamReducer(componentAdded$, resetOnComponentAdded),
    ]),
    // Starting the app with the table view we get 4 updates here.
    // For now fixing this with debouncing.
    debounceTime(50)
  );
};
