import styled from 'styled-components';
import { colors, s32, s4, s8 } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { urlUtils } from '@ardoq/common-helpers';
import trackUrlLinkClicked from 'tabview/graphViews/tracking';
import { AsLink } from '@ardoq/typography';
import type { UrlFieldValue } from '@ardoq/graph';

// #region styled-components
const UrlFieldValueListElement = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 304px;
`;

const UrlFieldValueContainer = styled.li`
  height: ${s32};
  display: flex;
  align-items: center;
  margin: ${s4} 0;
  &:not(:last-child) {
    margin-bottom: ${s8};
  }
`;
const UrlFieldValueTypeIcon = styled(Icon)`
  color: ${colors.grey50};
  margin-right: ${s8};
  vertical-align: middle;
`;
const UrlFieldValueLink = styled(AsLink).attrs({ small: true })`
  vertical-align: middle;
  display: flex;
  overflow: hidden;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
// #endregion

type UrlFieldValueListProperties = {
  urlFieldValues: UrlFieldValue[];
  element: Element | null;
};
const UrlFieldValueList = ({
  urlFieldValues,
  element,
}: UrlFieldValueListProperties) => (
  <UrlFieldValueListElement>
    {urlFieldValues.map(({ label, url, isReference }, index) => (
      <UrlFieldValueContainer key={`${label}_${index}`}>
        <UrlFieldValueTypeIcon
          iconName={isReference ? IconName.REFERENCE : IconName.COMPONENT}
        />
        <UrlFieldValueLink
          href={urlUtils.processUrl(url)}
          target="_blank"
          rel="noreferrer"
          onClick={() => trackUrlLinkClicked(element)}
        >
          {label}
        </UrlFieldValueLink>
      </UrlFieldValueContainer>
    ))}
  </UrlFieldValueListElement>
);
export default UrlFieldValueList;
