import { LinkedComponent } from '@ardoq/data-model';
import {
  BubbleChartDataPointModel,
  BubbleChartDomains,
  BubbleChartSelectedFieldInfo,
  BubbleChartViewProperties,
  BubbleChartViewSettings,
  BubbleChartSelectedFieldType,
} from '../types';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { APIFieldType, ReferenceDirection } from '@ardoq/api-types';
import {
  type LinkedComponentWithReferenceTypeName,
  referenceFields,
  referencedComponentFields,
} from './viewModel$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { uniqBy } from 'lodash';
import {
  bubbleChartXYDomains,
  getDataRange,
  sortDataPointsByCluster,
} from '../util';
import { DISPLAYED_LABELS_LIMIT } from '../consts';

const selectedFieldValue = (
  { referenceId, componentId }: LinkedComponent,
  { fieldName, type }: BubbleChartSelectedFieldInfo
) =>
  type === BubbleChartSelectedFieldType.REFERENCE
    ? referenceInterface.getFieldValue(referenceId, fieldName)
    : componentInterface.getFieldValue(componentId, fieldName);

type ResetArgs = Pick<
  BubbleChartViewProperties,
  | 'availableIncomingReferenceTypeNames'
  | 'availableOutgoingReferenceTypeNames'
  | 'numericFields'
> & {
  viewSettings: BubbleChartViewSettings;
  incomingReferences: LinkedComponentWithReferenceTypeName[];
  outgoingReferences: LinkedComponentWithReferenceTypeName[];
};
const resetReferenceBubbleChart = (
  previousState: BubbleChartViewProperties,
  {
    viewSettings,
    incomingReferences,
    outgoingReferences,
    availableIncomingReferenceTypeNames,
    availableOutgoingReferenceTypeNames,
    numericFields,
  }: ResetArgs
): BubbleChartViewProperties => {
  const {
    selectedReferenceTypeName,
    selectedReferenceDirection,
    selectedFieldNameX,
    selectedFieldNameY,
    selectedFieldNameRadius,
  } = viewSettings;

  const references =
    selectedReferenceDirection === 'incoming'
      ? incomingReferences
      : outgoingReferences;

  const [availableReferenceFields, availableReferencedComponentFields] = [
    referenceFields,
    referencedComponentFields,
  ].map(getFields =>
    uniqBy(
      references
        .flatMap(getFields)
        .filter(({ type }) => type === APIFieldType.NUMBER)
        .filter(ExcludeFalsy),
      ({ name }) => name
    )
  );

  const usedReferences = references?.filter(
    ({ referenceTypeName }) => referenceTypeName === selectedReferenceTypeName
  );

  const unsortedData: BubbleChartDataPointModel[] =
    !selectedFieldNameX || !selectedFieldNameY || !usedReferences
      ? []
      : usedReferences
          .map(reference => {
            const [xValue, yValue] = [
              selectedFieldNameX,
              selectedFieldNameY,
            ].map(field =>
              typeof field === 'string'
                ? null
                : selectedFieldValue(reference, field)
            );
            const radiusValue =
              selectedFieldNameRadius &&
              typeof selectedFieldNameRadius !== 'string'
                ? selectedFieldValue(reference, selectedFieldNameRadius)
                : 1;
            const { referenceId, componentId } = reference;
            if (
              xValue === null ||
              xValue === undefined ||
              yValue === null ||
              yValue === undefined ||
              !componentInterface.isIncludedInContextByFilter(componentId)
            ) {
              return null;
            }

            return {
              cid: componentId,
              uniqueId: referenceId,
              isContext: false,
              class:
                componentInterface.getCssClassNames(componentId, {
                  useAsBackgroundStyle: false,
                }) ?? '',
              label: componentInterface.getDisplayLabel(componentId) ?? '',
              x: typeof xValue === 'number' ? xValue : 0,
              y: typeof yValue === 'number' ? yValue : 0,
              radius: typeof radiusValue === 'number' ? radiusValue : 0,
            };
          })
          .filter(ExcludeFalsy);

  const data = sortDataPointsByCluster(unsortedData);

  const domain: BubbleChartDomains = {
    ...bubbleChartXYDomains(viewSettings, data),
    radius: getDataRange(data, 'radius'),
    labeledBubblesRadius: getDataRange(
      data.slice(0, DISPLAYED_LABELS_LIMIT),
      'radius'
    ),
  };
  const referenceArrow =
    selectedReferenceDirection === ReferenceDirection.OUTGOING ? '→' : '←';

  const [xFieldName, yFieldName] = [selectedFieldNameX, selectedFieldNameY].map(
    field => (typeof field === 'string' ? '' : (field?.fieldName ?? ''))
  );
  return {
    ...previousState,
    availableIncomingReferenceTypeNames,
    availableOutgoingReferenceTypeNames,
    availableReferenceFields,
    availableReferencedComponentFields,
    numericFields,
    viewSettings,
    data,
    domain,
    xAxisTitle: `${selectedReferenceTypeName} ${referenceArrow} ${xFieldName}`,
    yAxisTitle: `${selectedReferenceTypeName} ${referenceArrow} ${yFieldName}`,
  };
};
export default resetReferenceBubbleChart;
