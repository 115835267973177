import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ArdoqGraphComponent,
  ArdoqGraphComponentForwardRefType,
  PerformanceTrackedGraphView,
  canBypassLimit,
  isAboveItemLimit,
} from 'tabview/graphComponent/ArdoqGraphComponent';
import { getViewSettingsStreamWithChanges } from 'viewSettings/viewSettingsStreams';
import { APIFieldAttributes, ViewIds } from '@ardoq/api-types';
import {
  GraphComponent,
  GraphViewerInputMode,
  HierarchicNestingPolicy,
  Matrix,
  Size,
} from '@ardoq/yfiles';
import getViewModel$ from './isometric/viewModel$';
import CollapsibleGroupsYGraphBuilder from './view/yFilesExtensions/CollapsibleGroupsYGraphBuilder';
import { isInScopeDiffMode, isLoadingScope } from 'scope/scopeDiff';
import onLayoutGraph from './view/yFilesExtensions/onLayoutGraph';
import { getLeftMenu as getLeftMenuBase, getRightMenu } from './menus';
import configureLayout from './view/configureLayout';
import BlockDiagramVisualDiffSwitcher from './view/BlockDiagramVisualDiffSwitcher';
import IsometricNodeStyle from './view/yFilesExtensions/isometric/IsometricNodeStyle';
import { SettingsBar, getFieldDropdownOptions } from '@ardoq/settings-bar';
import { type SettingsConfig, SettingsType } from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import { componentInterface } from '@ardoq/component-interface';
import {
  IsometricBlockDiagramViewProperties,
  IsometricBlockDiagramViewSettings,
} from './isometric/types';
import {
  addedAndUpdatedGraphEdges,
  addedAndUpdatedGraphGroups,
  addedAndUpdatedGraphNodes,
  xScaler,
  type RelationshipDiagramViewModel,
} from '@ardoq/graph';
import IsometricGraphModelManager from './view/yFilesExtensions/isometric/IsometricGraphModelManager';
import WithLoadingIndicator from 'tabview/WithLoadingIndicator';
import { dispatchAction, connectInstance } from '@ardoq/rxbeach';
import { notifyViewLoading } from 'tabview/actions';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { defaultLegendOnClick } from 'viewSettings/getRightMenuConfig';
import { returnZero } from '@ardoq/common-helpers';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import useUserSettingToggle from 'models/utils/useUserSettingToggle';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { isPresentationMode } from 'appConfig';

const VIEW_ID = ViewIds.ISOMETRIC_BLOCK_DIAGRAM;

const getLeftMenu = (
  args: Parameters<typeof getLeftMenuBase>[0] & {
    viewSettings: IsometricBlockDiagramViewSettings;
    numericFields: APIFieldAttributes[];
  }
): SettingsConfig[] => [
  ...getLeftMenuBase(args),
  {
    id: 'valueField',
    label: 'Select value field',
    iconName: IconName.ARROW_RIGHT_ALT,
    className: 'rotate-270',
    options: getFieldDropdownOptions(
      VIEW_ID,
      args.numericFields,
      'selectedFieldNameValue',
      name => args.viewSettings.selectedFieldNameValue === name,
      onViewSettingsUpdate
    ),
    type: SettingsType.DROPDOWN,
  },
];

const configureRendering = (graphComponent: GraphComponent) => {
  const inputMode = graphComponent.inputMode as GraphViewerInputMode;
  inputMode.navigationInputMode.enabled = false;
  graphComponent.maximumZoom = 1.6;
  graphComponent.minimumZoom = 0.01;
  graphComponent.mouseWheelZoomFactor = 1.1;
  graphComponent.projection = Matrix.ISOMETRIC;

  graphComponent.graphModelManager = new IsometricGraphModelManager(
    graphComponent,
    graphComponent.contentGroup
  );
  const { graphModelManager } = graphComponent;
  graphModelManager.hierarchicNestingPolicy =
    HierarchicNestingPolicy.GROUP_NODES;
  const {
    nodeLabelGroup,
    edgeLabelGroup,
    nodeGroup,
    edgeGroup,
    groupNodeGroup,
  } = graphModelManager;
  edgeLabelGroup.below(nodeGroup);
  nodeLabelGroup.below(edgeLabelGroup);
  edgeGroup.below(nodeLabelGroup);
  groupNodeGroup.below(edgeGroup);
};

const isEmptyGraph = (viewModel: RelationshipDiagramViewModel) => {
  const { groups, nodes, edges } = viewModel;

  return ![groups, nodes, edges].some(
    ({ add, update }) => add.length || update.length
  );
};

const ViewContainer = styled.div`
  & .rotate-270 {
    transform: rotate(270deg);
  }
`;
const IsometricBlockDiagramView = (
  props: IsometricBlockDiagramViewProperties
) => {
  const ardoqGraphComponentRef =
    useRef<ArdoqGraphComponentForwardRefType>(null);
  const graphBuilder = useRef<CollapsibleGroupsYGraphBuilder | null>(null);
  const nodeStyle = useRef(new IsometricNodeStyle(returnZero));

  const setScaler = (
    selectedFieldNameValue: string,
    selectedValueFieldDomain: [min: number, max: number]
  ) => {
    const scaler = xScaler([10, 50], selectedValueFieldDomain, [0, 1]);
    nodeStyle.current.scaler = graphNode => {
      const fieldValue = componentInterface.getFieldValue(
        graphNode.modelId,
        selectedFieldNameValue
      );
      if (typeof fieldValue !== 'number') return 10;
      return scaler(fieldValue) || 10;
    };
  };
  const {
    viewModel,
    viewInstanceId,
    embeddableViewConfig,
    viewSettings,
    scenarioName,
    numericFields,
    selectedValueFieldDomain,
    clearEdges,
  } = props;

  const buildGraph = (
    graphComponent: GraphComponent,
    bypassLimit: boolean,
    clearEdges: boolean
  ) => {
    if (!graphBuilder.current) {
      graphBuilder.current = new CollapsibleGroupsYGraphBuilder(
        VIEW_ID,
        graphComponent.graph,
        () => nodeStyle.current,
        () => new Size(50, 50)
      );
    }

    if (clearEdges) {
      graphBuilder.current.clearEdges();
    }
    const groups = addedAndUpdatedGraphGroups(viewModel);
    const nodes = addedAndUpdatedGraphNodes(viewModel);
    const edges = addedAndUpdatedGraphEdges(viewModel);

    const aboveLimit = isAboveItemLimit(nodes.length, edges.length);
    const canBypass = canBypassLimit(nodes.length, edges.length);
    const isEmpty = !nodes.length && !edges.length && !groups.length;
    const hasLayoutUpdate = graphBuilder.current.hasLayoutUpdate({
      groups,
      nodes,
      edges,
    });
    if ((!bypassLimit && aboveLimit) || isEmpty) {
      graphBuilder.current.groupsSource = [];
      graphBuilder.current.nodesSource = [];
      graphBuilder.current.edgesSource = [];
      graphBuilder.current.clear();
    } else {
      graphBuilder.current.groupsSource = groups;
      graphBuilder.current.nodesSource = nodes;
      graphBuilder.current.edgesSource = edges;
      graphBuilder.current.updateGraph();
    }
    graphComponent.selection.clear();
    // graphComponent.projection = Matrix.ISOMETRIC;
    return { isAboveLimit: aboveLimit, isEmpty, canBypass, hasLayoutUpdate };
  };
  const getContainer = () =>
    ardoqGraphComponentRef.current?.graphComponentContainer.current ?? null;
  const getGraphComponent = () =>
    ardoqGraphComponentRef.current?.graphComponent ?? null;

  const {
    noConnectedComponents,
    traversedOutgoingReferenceTypes,
    traversedIncomingReferenceTypes,
    referenceTypes,
    urlFieldValuesByComponentId,
    urlFieldValuesByReferenceId,
    expandedStateChangingGroupId,
    errors,
    hasClones,
  } = viewModel;
  useEffect(() => {
    if (noConnectedComponents) {
      // normally isBusy=false is dispatched in the graph layout callback, but in this case we have no graph, so we must dispatch isBusy=false
      setTimeout(() =>
        dispatchAction(
          notifyViewLoading({
            viewInstanceId,
            isBusy: false,
          })
        )
      );
    }
  }, [viewInstanceId, noConnectedComponents]);

  // let isLegendActive holds the "active" value of the legend and is temorarely detached from
  // the state due to getFilteredViewSettings$.
  // This value & the viewstate are synchronized as soon an update passes the filter.
  let isLegendActive = viewSettings.isLegendActive ?? false;

  const { activeDiffMode, selectedFieldNameValue } = viewSettings;

  const showLeftSettingsSection =
    !embeddableViewConfig || embeddableViewConfig.showLeftSettingsSection;
  const showRightSettingsSection =
    !embeddableViewConfig || embeddableViewConfig.showRightSettingsSection;

  setScaler(selectedFieldNameValue, selectedValueFieldDomain);
  const activeGroups = viewModel.groups.add.concat(viewModel.groups.update);

  const isEmptyView = isEmptyGraph(viewModel) || noConnectedComponents;

  const [clearedErrors, setClearedErrors] = useState(false);
  const [clearedHasClones, setClearedHasClones] = useState(false);
  const [neverShowAgain, toggleNeverShowAgain] = useUserSettingToggle(
    ViewIds.ISOMETRIC_BLOCK_DIAGRAM,
    NEVER_SHOW_AGAIN
  );

  return (
    <ViewContainer className={`tab-pane ${VIEW_ID}Tab active`}>
      <div className="menuContainer">
        <SettingsBar
          viewId={VIEW_ID}
          leftMenu={getLeftMenu({
            viewSettings,
            traversedOutgoingReferenceTypes,
            traversedIncomingReferenceTypes,
            referenceTypes,
            numericFields,
            viewId: VIEW_ID,
            onViewSettingsUpdate,
            activeGroups,
            isViewpointMode: false,
          })}
          rightMenu={getRightMenu({
            viewSettings,
            getContainer,
            getGraphComponent: () => getGraphComponent()?.current ?? null,
            viewId: VIEW_ID,
            legendOnClick: () => {
              isLegendActive = !isLegendActive;
              defaultLegendOnClick({
                isLegendActive: isLegendActive,
                viewId: VIEW_ID,
              });
            },
            onViewSettingsUpdate,
            isEmptyView,
          })}
          showLeftSettingsSection={showLeftSettingsSection}
          showRightSettingsSection={showRightSettingsSection}
        />
      </div>
      <div style={{ flex: 1, position: 'relative' }}>
        <ArdoqGraphComponent
          viewId={VIEW_ID}
          ref={ardoqGraphComponentRef}
          viewModel={viewModel}
          isLegendActive={viewSettings.isLegendActive}
          buildGraph={(graphComponent, bypassLimit) =>
            buildGraph(graphComponent, bypassLimit, clearEdges)
          }
          enableStyles={true}
          configureLayout={graphComponent =>
            configureLayout(graphComponent, viewModel, viewSettings)
          }
          configureRendering={configureRendering}
          onLayoutGraph={onLayoutGraph(expandedStateChangingGroupId)}
          useHoverDecorator={false}
          viewInstanceId={viewInstanceId}
          urlFieldValuesByComponentId={urlFieldValuesByComponentId}
          urlFieldValuesByReferenceId={urlFieldValuesByReferenceId}
        />

        <ErrorInfoBox
          errors={clearedErrors ? [] : errors}
          hasClones={!neverShowAgain && !clearedHasClones && hasClones}
          clearHasClones={() => setClearedHasClones(true)}
          clearErrors={() => setClearedErrors(true)}
          isShowNeverAgainSet={neverShowAgain}
          toggleNeverShowAgain={toggleNeverShowAgain}
          isPresentationMode={isPresentationMode()}
        />
        {(isInScopeDiffMode() || isLoadingScope()) && (
          <BlockDiagramVisualDiffSwitcher
            viewId={ViewIds.ISOMETRIC_BLOCK_DIAGRAM}
            scenarioName={scenarioName}
            activeDiffMode={activeDiffMode}
          />
        )}
      </div>
    </ViewContainer>
  );
};

const viewSettings$ =
  getViewSettingsStreamWithChanges<IsometricBlockDiagramViewSettings>(VIEW_ID);

const PerformanceTrackedIsometricBlockDiagram = (
  props: IsometricBlockDiagramViewProperties
) => PerformanceTrackedGraphView(IsometricBlockDiagramView, props, VIEW_ID);

const IsometricBlockDiagramViewWithLoadingIndicator = (
  props: IsometricBlockDiagramViewProperties
) =>
  WithLoadingIndicator({
    WrappedComponent: PerformanceTrackedIsometricBlockDiagram,
    wrappedProps: props,
    showContentWhileLoading: false,
  });
export default connectInstance(
  IsometricBlockDiagramViewWithLoadingIndicator,
  getViewModel$({
    viewId: VIEW_ID,
    viewSettings$,
  })
);
