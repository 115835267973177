import {
  CactusGroupLayoutData,
  CircularLayoutConfig,
  CircularLayoutData,
  CompactDiskLayoutData,
  CompositeLayoutData,
  EdgeRouterData,
  GenericLayoutData,
  HierarchicLayoutConfig,
  HierarchicLayoutData,
  ILabel,
  IModelItem,
  INode,
  ItemMapping,
  LayerConstraintData,
  LayoutDescriptor,
  OrganicLayoutData,
  OrthogonalLayoutData,
  PreferredPlacementDescriptor,
  RadialLayoutConfig,
  RadialLayoutData,
  TabularLayoutData,
  TreeLayoutData,
  TreeMapLayoutData,
} from '@ardoq/yfiles';
import {
  GraphItem,
  GraphNode,
  sourceGroupIds as getSourceGroupIds,
  targetGroupIds as getTargetGroupIds,
  type RelationshipDiagramViewModel,
  processDuplicateLabels,
} from '@ardoq/graph';
import {
  HasEdgeBundling,
  ProteanLayoutType,
  ProteanTabularLayoutStageConfig,
} from '../types';
import { componentInterface } from '@ardoq/component-interface';
import { logError } from '@ardoq/logging';
import { ProteanGraphState, RenderGraphFlags } from './types';
import processUpdates from './buildGraph/processUpdates';
import { isEqual } from 'lodash';
import { PROTEAN_DEFAULT_LAYOUT_OPTIONS } from 'views/defaultState';
import createSequenceConstraints from 'tabview/graphViews/layoutConstraints/createSequenceConstraints';
import type { LayoutConstraint } from 'tabview/graphViews/types';
import {
  CELL_ADDRESS_DATA_PROVIDER_KEY,
  NODE_IDS_DATA_PROVIDER_KEY,
} from './consts';
import { ProteanTabularCellAddress } from '../intentionalLayout/layoutStage/types';

const DEFAULT_PLACEMENT_DESCRIPTOR = new PreferredPlacementDescriptor();

export const createLayoutDescriptor = (
  state: ProteanGraphState
): LayoutDescriptor => {
  const { viewSettings, lastViewSettings, renderFlags } = state;
  const { layoutType, layoutOptions } = viewSettings;
  const lastLayout = lastViewSettings?.layoutType;
  const lastLayoutOptions = lastViewSettings?.layoutOptions;
  const fromScratch = (renderFlags & RenderGraphFlags.FROM_SCRATCH) !== 0;
  switch (layoutType) {
    case ProteanLayoutType.Hierarchic: {
      const {
        layoutState: { hierarchic },
      } = state;
      const sequenceConstraints = hierarchic?.sequenceConstraints;
      const layerConstraints = hierarchic?.layerConstraints;
      const lastSequenceConstraints =
        state.lastLayoutState?.hierarchic?.sequenceConstraints;
      const lastLayerConstraints =
        state.lastLayoutState?.hierarchic?.layerConstraints;
      const layoutMode =
        !fromScratch &&
        state.lastViewModel &&
        processUpdates(state.lastViewModel, state.viewModel).nodes.update
          .length &&
        lastLayout === ProteanLayoutType.Hierarchic &&
        lastLayoutOptions?.hierarchic?.orientation ===
          layoutOptions.hierarchic?.orientation &&
        lastLayoutOptions?.hierarchic?.directedEdges ===
          layoutOptions.hierarchic?.directedEdges &&
        lastLayoutOptions?.hierarchic?.recursiveGroupLayering ===
          layoutOptions.hierarchic?.recursiveGroupLayering &&
        lastLayoutOptions?.hierarchic?.separateLayers ===
          layoutOptions.hierarchic?.separateLayers &&
        lastLayoutOptions?.hierarchic?.minimumLayerDistance ===
          layoutOptions.hierarchic?.minimumLayerDistance &&
        lastLayoutOptions?.hierarchic?.nodeToNodeDistance ===
          layoutOptions.hierarchic?.nodeToNodeDistance &&
        isEqual(sequenceConstraints, lastSequenceConstraints) &&
        isEqual(layerConstraints, lastLayerConstraints)
          ? 'incremental'
          : 'from-scratch';

      const layout: LayoutDescriptor = {
        name: 'HierarchicLayout',
        properties: {
          orthogonalRouting: layoutOptions.hierarchic?.orthogonalRouting,
          layoutOrientation: layoutOptions.hierarchic?.orientation,
          compactGroups: layoutOptions.hierarchic?.compactGroups,
          separateLayers: layoutOptions.hierarchic?.separateLayers,
          integratedEdgeLabeling: true,
          considerNodeLabels: true,
          layoutMode,
          recursiveGroupLayering:
            layoutOptions.hierarchic?.recursiveGroupLayering,
          minimumLayerDistance: layoutOptions.hierarchic?.minimumLayerDistance,
          nodeToNodeDistance: layoutOptions.hierarchic?.nodeToNodeDistance,
        },
      };

      return layout;
    }
    case ProteanLayoutType.Organic: {
      const isUpdate =
        state.lastViewModel &&
        processUpdates(state.lastViewModel, state.viewModel).nodes.update
          .length &&
        state.lastViewSettings?.layoutType === ProteanLayoutType.Organic;
      const layout: LayoutDescriptor = {
        name: 'OrganicLayout',
        properties: {
          nodeEdgeOverlapAvoided: layoutOptions.organic?.nodeEdgeOverlapAvoided,
          compactnessFactor: layoutOptions.organic?.compactnessFactor,
          automaticGroupNodeCompaction: true,
          scope: isUpdate ? 'mainly-subset' : 'all', // if this is an update (i.e. not a totally fresh graph), then we set the scope here to indicate only a subset of nodes need repositioning. that subset will be provided in the layout data.
        },
      };
      return layout;
    }
    case ProteanLayoutType.Orthogonal: {
      const layout: LayoutDescriptor = {
        name: 'OrthogonalLayout',
        properties: {
          gridSpacing: Math.max(layoutOptions.orthogonal?.gridSpacing ?? 1, 1),
          integratedEdgeLabeling: true,
          crossingReduction:
            layoutOptions.orthogonal?.crossingReduction ?? true,
          edgeLengthReduction:
            layoutOptions.orthogonal?.edgeLengthReduction ?? true,
          optimizePerceivedBends:
            layoutOptions.orthogonal?.optimizePerceivedBends ?? true,
          uniformPortAssignment:
            layoutOptions.orthogonal?.uniformPortAssignment ?? false,
        },
      };
      return layout;
    }

    case ProteanLayoutType.Circular: {
      const layout: LayoutDescriptor & {
        properties: CircularLayoutConfig & HasEdgeBundling;
      } = {
        name: 'CircularLayout',
        properties: {
          edgeBundling: layoutOptions.circular?.edgeBundling ?? 0,
          layoutStyle: layoutOptions.circular?.layoutStyle ?? 'bcc-compact',
        },
      };
      return layout;
    }
    case ProteanLayoutType.Radial: {
      const layout: LayoutDescriptor & {
        properties: RadialLayoutConfig & HasEdgeBundling;
      } = {
        name: 'RadialLayout',
        properties: {
          edgeBundling: layoutOptions.radial?.edgeBundling ?? 0,
          layeringStrategy: layoutOptions.radial?.layeringStrategy ?? 'bfs',
        },
      };
      return layout;
    }
    case ProteanLayoutType.CompactDisk: {
      const layout: LayoutDescriptor = {
        name: 'CompactDiskLayout',
        properties: {},
      };

      return layout;
    }
    case ProteanLayoutType.Swimlanes: {
      const layout: LayoutDescriptor & {
        properties: HierarchicLayoutConfig & { swimlanes: boolean };
      } = {
        name: 'HierarchicLayout',
        properties: {
          swimlanes: true,
          componentLayoutEnabled: false,
          layoutOrientation: 'top-to-bottom',
          orthogonalRouting: true,
          integratedEdgeLabeling: true,
        },
      };
      return layout;
    }
    case ProteanLayoutType.GeographicMap:
    case ProteanLayoutType.SpatialMap: {
      const layout: LayoutDescriptor = {
        name: 'RadialLayout',
        properties: { centerNodesPolicy: 'custom' },
      };
      return layout;
    }
    case ProteanLayoutType.Cactus: {
      const layout: LayoutDescriptor = {
        name: 'CactusGroupLayout',
        properties: {},
      };
      return layout;
    }
    case ProteanLayoutType.Tree: {
      const layout: LayoutDescriptor = {
        name: 'TreeLayout',
        properties: {},
      };
      return layout;
    }
    case ProteanLayoutType.TreeMap: {
      const layout: LayoutDescriptor = {
        name: 'TreeMapLayout',
        properties: {},
      };
      return layout;
    }
    case ProteanLayoutType.Tabular:
    case ProteanLayoutType.HierarchicInGrid: {
      const {
        layoutOptions: {
          tabular: {
            rowInsets = 0,
            columnInsets = 0,
            minRowSize = 0,
            minColumnSize = 0,
            separateReferences = false,
          } = {},
        },
      } = viewSettings;
      const properties: ProteanTabularLayoutStageConfig = {
        layoutType,
        rowInsets,
        columnInsets,
        minRowSize,
        minColumnSize,
        separateReferences,
      };
      const layout: LayoutDescriptor = {
        name: 'UserDefined',
        properties,
      };
      return layout;
    }
    default:
      logError(Error('unrecognized layout type'));
      return { name: 'CircularLayout' };
  }
};
const nodeTypes = (node: INode) => {
  const modelId = (node.tag as GraphItem | null)?.modelId;
  return modelId ? componentInterface.getTypeId(modelId) : null;
};

export const createLayoutData = (
  layoutType: ProteanLayoutType,
  viewModel: RelationshipDiagramViewModel,
  state: ProteanGraphState
) => {
  const { graphComponent, viewSettings } = state;
  if (!graphComponent.current) {
    return null;
  }
  const { graph } = graphComponent.current;
  const layoutOptions = {
    ...PROTEAN_DEFAULT_LAYOUT_OPTIONS,
    ...viewSettings.layoutOptions,
  };
  switch (layoutType) {
    case ProteanLayoutType.Hierarchic: {
      const { layoutState } = state;
      const {
        sequenceConstraints: resolvedSequenceConstraints = [],
        layerConstraints: resolvedLayerConstraints = [],
      } = layoutState.hierarchic ?? {};

      const [sequenceConstraints, layerConstraints] = [
        resolvedSequenceConstraints,
        resolvedLayerConstraints,
      ].map(constraints =>
        constraints.map<LayoutConstraint>(([id, , resolvedValue]) => [
          id,
          resolvedValue,
        ])
      );

      const layerConstraintMap = new Map(layerConstraints);
      const layerConstraintsMapping = new ItemMapping<IModelItem, number>(
        item => layerConstraintMap.get(item.tag.id) ?? 0
      );

      const [sourceGroupIds, targetGroupIds] = !layoutOptions.hierarchic
        .separateReferences
        ? [getSourceGroupIds(graph), getTargetGroupIds(graph)]
        : [undefined, undefined];
      return new HierarchicLayoutData({
        nodeTypes,
        edgeDirectedness: Number(layoutOptions.hierarchic?.directedEdges ?? 0),
        sequenceConstraints: createSequenceConstraints(
          sequenceConstraints ?? [],
          graph
        ),
        layerConstraints: new LayerConstraintData({
          nodeComparables: layerConstraintsMapping,
        }),
        sourceGroupIds,
        targetGroupIds,
      });
    }
    case ProteanLayoutType.Organic: {
      const updatedNodeIds = state.lastViewModel
        ? new Set(processUpdates(state.lastViewModel, viewModel).nodes.update)
        : null;
      return new OrganicLayoutData({
        nodeTypes,
        affectedNodes: node => !updatedNodeIds?.has((node.tag as GraphNode).id), // only new nodes need repositioning.
      });
    }
    case ProteanLayoutType.Orthogonal:
      return new OrthogonalLayoutData({ nodeTypes });
    case ProteanLayoutType.Circular:
      return new CircularLayoutData({
        nodeTypes,
        customGroups: (node: INode) => graph.getParent(node),
      });
    case ProteanLayoutType.Radial:
      return new RadialLayoutData();
    case ProteanLayoutType.CompactDisk:
      return new CompactDiskLayoutData();
    case ProteanLayoutType.Swimlanes:
      return new HierarchicLayoutData({ nodeTypes });
    case ProteanLayoutType.GeographicMap:
    case ProteanLayoutType.SpatialMap:
      return new RadialLayoutData();
    case ProteanLayoutType.Cactus:
      return new CactusGroupLayoutData();
    case ProteanLayoutType.Tree:
      return new TreeLayoutData();
    case ProteanLayoutType.TreeMap:
      return new TreeMapLayoutData();
    case ProteanLayoutType.Tabular:
    case ProteanLayoutType.HierarchicInGrid: {
      const activeLayoutType =
        layoutType === ProteanLayoutType.Tabular
          ? 'tabular'
          : 'hierarchicInGrid';
      const {
        layoutState: { [activeLayoutType]: tabularLayoutState },
        viewSettings: {
          layoutOptions: { [activeLayoutType]: tabularLayoutOptions },
        },
      } = state;
      if (!tabularLayoutState || !tabularLayoutOptions) {
        return new TabularLayoutData();
      }
      const {
        columnConstraintsMap,
        rowConstraintsMap,
        columnSpansMap,
        rowSpansMap,
      } = tabularLayoutState;

      const layoutData = new GenericLayoutData();
      layoutData.addNodeItemMapping(
        CELL_ADDRESS_DATA_PROVIDER_KEY,
        node =>
          ({
            column: columnConstraintsMap.get(node.tag.id)?.[2] ?? 0,
            row: rowConstraintsMap.get(node.tag.id)?.[2] ?? 0,
            columnSpan: Math.max(1, columnSpansMap.get(node.tag.id)?.[2] ?? 1),
            rowSpan: Math.max(1, rowSpansMap.get(node.tag.id)?.[2] ?? 1),
          }) satisfies ProteanTabularCellAddress
      );
      layoutData.addNodeItemMapping<string>(
        NODE_IDS_DATA_PROVIDER_KEY,
        node => node.tag.id
      );
      if (tabularLayoutOptions.separateReferences) {
        return layoutData;
      }
      const edgeLabelPreferredPlacement = processDuplicateLabels(graph);

      return new CompositeLayoutData(
        layoutData,
        new EdgeRouterData({
          sourceGroupIds: getSourceGroupIds(graph),
          targetGroupIds: getTargetGroupIds(graph),
          edgeLabelPreferredPlacement: (label: ILabel) =>
            edgeLabelPreferredPlacement.get(label) ??
            DEFAULT_PLACEMENT_DESCRIPTOR,
        })
      );
    }
  }
};
