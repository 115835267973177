import type { LegendComponentType } from '@ardoq/graph';
import type { RelationshipsNode } from '../types';
import { componentInterface } from '@ardoq/component-interface';
import { getMatchingFilterIdsFilter } from './util';

const getSelectedNodes = (
  allNodes: RelationshipsNode[],
  selectedComponentTypes: LegendComponentType[] | null,
  selectedConditionalFormatting: string[] | null
) => {
  const componentTypesSet = new Set(
    selectedComponentTypes?.flatMap(({ ids }) => ids) ?? []
  );
  const nodeMatchesComponentType = selectedComponentTypes?.length
    ? (node: RelationshipsNode) => {
        if (!node.modelId) {
          return false;
        }
        const componentTypeModelId = componentInterface.getTypeId(node.modelId);
        return (
          componentTypeModelId && componentTypesSet.has(componentTypeModelId)
        );
      }
    : () => false;
  const nodeMatchesSelectedFormatting = getMatchingFilterIdsFilter(
    new Set(selectedConditionalFormatting)
  );
  return allNodes.filter(
    node =>
      nodeMatchesComponentType(node) || nodeMatchesSelectedFormatting(node)
  );
};
export default getSelectedNodes;
