import {
  action$,
  combineReducers,
  streamReducer,
  ofType,
} from '@ardoq/rxbeach';
import { Observable, combineLatest } from 'rxjs';
import { context$ } from 'streams/context/context$';
import { APIComponentAttributes, ArdoqId, ViewIds } from '@ardoq/api-types';
import modelCss$ from 'utils/modelCssManager/modelCss$';
import { getComponentCssColors } from 'utils/modelCssManager/getCssColors';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import type {
  CalculationFn,
  ComponentDecoratorImageProps,
  ComponentsReducerOptions,
  ComponentsToDisplay,
  LegendProps,
  TreemapData,
  TreemapDataDisplayOptions,
  ViewModel,
  ViewSettings,
} from './types';
import { WorkspaceField } from './types';
import { getSelectedFieldSettingsName, isByCustomField } from './helpers';
import { representationDataToEntityIconProps } from '@ardoq/renderers';
import { logWarn } from '@ardoq/logging';
import { EMPTY_DISPLAY_OPTIONS, MISSING_FIELD_WARN_MESSAGE } from './constants';
import { settingsBarConsts } from '@ardoq/settings-bar';
import { getComponentTypesInfo } from '@ardoq/graph';
import allDescendantsReducer from 'modelInterface/components/allDescendantsReducer';
import graphModel$ from 'modelInterface/graphModel$';
import { GraphModelMap, GraphModelShape } from '@ardoq/data-model';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsSynced,
  notifyComponentsUpdated,
} from 'streams/components/ComponentActions';
import { getComponentNumericFieldsOfWorkspace } from 'utils/getComponentNumericFieldsOfWorkspace';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
} from 'streams/fields/FieldActions';
import { startWith } from 'rxjs/operators';
import { startAction } from 'actions/utils';
import { SHOW_ALL_COMPONENTS, SizeBasedOn } from 'views/treemapConsts';
import { ExtractStreamShape } from 'tabview/types';
import { colors } from '@ardoq/design-tokens';

const {
  getComponentData,
  getRepresentationData,
  getRootComponents,
  isIncludedInContextByFilter,
  getParentId,
  getChildren,
  getFieldValue,
} = componentInterface;

const EMPTY_STATE: ViewModel = {
  data: {
    id: null,
    label: '',
    value: 0,
    displayOptions: EMPTY_DISPLAY_OPTIONS,
  },
  settings: {
    sizeBasedOn: SizeBasedOn.CHILD_COUNT,
    maxLimitValue: SHOW_ALL_COMPONENTS,
  },
  legend: {
    isActive: false,
    componentTypes: [],
  },
  workspaceFields: [],
  fieldsInUse: new Set<ArdoqId>(),
  hasIncomingReferences: false,
  hasOutgoingReferences: false,
  hasComponentsAvailable: false,
};

const checkHasReferences = (
  componentId: ArdoqId,
  graphModelMap: GraphModelMap
) =>
  Boolean(
    graphModelMap
      .get(componentId)
      ?.some(({ referenceId }) =>
        referenceInterface.isIncludedInContextByFilter(referenceId)
      )
  );

const getReferenceCount = (
  component: APIComponentAttributes,
  graphModelMap: GraphModelMap
) => {
  const componentIds = [component._id].reduce(allDescendantsReducer, []);

  return componentIds.reduce((acc, componentId) => {
    const referenceCount =
      graphModelMap
        .get(componentId)
        ?.filter(({ referenceId }) =>
          referenceInterface.isIncludedInContextByFilter(referenceId)
        ).length || 0;

    return acc + referenceCount;
  }, 0);
};

const createCalculationFn = (
  settings: ViewSettings,
  graphModel: GraphModelShape
): CalculationFn => {
  if (isByCustomField(settings.sizeBasedOn)) {
    return component => {
      const selectedField = settings.sizeBasedOn;

      if (!selectedField) {
        logWarn(Error(MISSING_FIELD_WARN_MESSAGE), null, {
          viewId: ViewIds.TREEMAP,
          settings,
        });
        return undefined;
      }

      const componentIds = [component._id].reduce(allDescendantsReducer, []);
      return componentIds.reduce((acc, componentId) => {
        const value = getFieldValue(componentId, selectedField);

        if (typeof value === 'number') {
          return acc + value;
        }

        return acc;
      }, 0);
    };
  }

  switch (settings.sizeBasedOn) {
    case SizeBasedOn.CHILD_COUNT:
      return component =>
        [component._id].reduce(allDescendantsReducer, []).length - 1;
    case SizeBasedOn.INCOMING_LINKS:
      return component => getReferenceCount(component, graphModel.targetMap);
    case SizeBasedOn.OUTGOING_LINKS:
      return component => getReferenceCount(component, graphModel.sourceMap);
    default:
      return _component => 0;
  }
};

const getTopChildren = (limit: number, components: TreemapData[]) => {
  if (limit <= 0 || limit >= components.length) return components;
  return components.sort((a, b) => b.value - a.value).slice(0, limit);
};

const validateSettings = (
  settings: ViewSettings,
  workspaceFields: WorkspaceField[]
) => {
  const isCustomField = isByCustomField(settings.sizeBasedOn);
  const selectedField = isCustomField ? settings.sizeBasedOn : null;

  const selectedInCurrentWorkspace = workspaceFields.some(
    ({ name }) => name === selectedField
  );

  const isFieldNotFromCurrentWorkspace =
    isCustomField && (!selectedField || !selectedInCurrentWorkspace);

  if (isFieldNotFromCurrentWorkspace) {
    return {
      ...settings,
      sizeBasedOn: SizeBasedOn.CHILD_COUNT,
    };
  }
  return settings;
};

const getComponentDecoratorImageProps = (
  componentId: string
): ComponentDecoratorImageProps | undefined => {
  const representationData = getRepresentationData(componentId);
  if (!representationData) return undefined;
  return representationDataToEntityIconProps(representationData);
};

const getComponentDisplayOptions = (
  componentId: ArdoqId,
  currentComponentId: string
): TreemapDataDisplayOptions => {
  const componentColors = getComponentCssColors(componentId, {
    useAsBackgroundStyle: true,
  });
  const componentDecoratorImageProps =
    getComponentDecoratorImageProps(componentId) ?? {};
  const isSelected = currentComponentId === componentId;
  return {
    componentDecoratorImageProps,
    color: isSelected ? colors.blue50 : componentColors?.stroke,
    backgroundColor: isSelected ? colors.blue95 : componentColors?.fill,
    isSelected: isSelected,
  };
};

const reduceComponent =
  ({ getWeight, context }: ComponentsReducerOptions) =>
  (acc: TreemapData[], component: APIComponentAttributes) => {
    if (!isIncludedInContextByFilter(component._id)) return acc;
    const weight = getWeight(component);
    if (!weight) return acc;
    const displayOptions = getComponentDisplayOptions(
      component._id,
      context.componentId
    );
    return [
      ...acc,
      {
        id: component._id,
        label: component.name,
        value: weight,
        displayOptions,
      },
    ];
  };

const getComponentsToDisplay = (
  componentId: string
): ComponentsToDisplay | undefined => {
  const children = getChildren(componentId) ?? [];
  if (children.length) {
    return {
      components: children.reduce<APIComponentAttributes[]>((acc, id) => {
        const data = getComponentData(id);
        if (!data) return acc;
        return [data, ...acc];
      }, []),
      containerId: componentId,
    };
  }
  const parentId = getParentId(componentId);
  if (parentId) return getComponentsToDisplay(parentId);
  return undefined;
};

const getContainerData = (
  containerId: ArdoqId | undefined,
  workspaceId: ArdoqId,
  children: TreemapData[],
  reduceComponentFn: (
    acc: TreemapData[],
    component: APIComponentAttributes
  ) => TreemapData[]
) => {
  const doNotShowContainer = !containerId && children.length > 0;

  if (doNotShowContainer || !containerId) return EMPTY_STATE.data;

  const containerData = getComponentData(containerId);

  if (!containerData) return EMPTY_STATE.data;

  const [data] = [containerData].reduce(reduceComponentFn, []);

  if (!data) {
    return EMPTY_STATE.data;
  }

  return {
    workspaceId,
    ...data,
  };
};

const getRootComponentsData = (
  workspaceId: ArdoqId
): APIComponentAttributes[] => {
  const rootComponentsIds = getRootComponents(workspaceId) ?? [];
  return rootComponentsIds
    .map(getComponentData)
    .filter<APIComponentAttributes>(
      (component): component is APIComponentAttributes => component !== null
    );
};

const getLegend = (
  settings: ViewSettings,
  children: TreemapData[]
): LegendProps => {
  const isLegendActive = settings[settingsBarConsts.IS_LEGEND_ACTIVE] ?? false;
  const { legendComponentTypes } = getComponentTypesInfo(
    children.map(({ id }) => id!)
  );

  return {
    isActive: isLegendActive,
    componentTypes: legendComponentTypes,
  };
};

export const getViewModel$ = (viewSettings$: Observable<ViewSettings>) => {
  const componentUpdate$ = action$.pipe(
    ofType(
      notifyComponentsAdded,
      notifyComponentsUpdated,
      notifyComponentsRemoved,
      notifyComponentsSynced,
      notifyFieldAdded,
      notifyFieldRemoved
    ),
    startWith(startAction())
  );

  const treemapContext$ = combineLatest([
    viewSettings$,
    context$,
    graphModel$,
    modelCss$,
    componentUpdate$,
  ]);

  const reset = (
    _: ViewModel,
    [settings, context, graphModel]: ExtractStreamShape<typeof treemapContext$>
  ): ViewModel => {
    const workspaceFields = getComponentNumericFieldsOfWorkspace(
      context.workspaceId
    ).map(({ _id, name }) => ({ _id, name }));

    const { components, containerId } =
      getComponentsToDisplay(context.componentId) ?? {};

    const rootComponents =
      components ?? getRootComponentsData(context.workspaceId);

    const componentTreeIds = rootComponents
      .map(({ _id }) => _id)
      .reduce(allDescendantsReducer, []);

    const hasIncomingReferences = componentTreeIds.some(componentId =>
      checkHasReferences(componentId, graphModel.targetMap)
    );

    const hasOutgoingReferences = componentTreeIds.some(componentId =>
      checkHasReferences(componentId, graphModel.sourceMap)
    );

    const safeSettings = validateSettings(settings, workspaceFields);
    const getWeight = createCalculationFn(safeSettings, graphModel);

    const reduceComponentFn = reduceComponent({
      getWeight,
      context,
    });

    const children = rootComponents
      .reduce<TreemapData[]>(reduceComponentFn, [])
      .filter(({ value }) => value > 0);

    const containerData = getContainerData(
      containerId,
      context.workspaceId,
      children,
      reduceComponentFn
    );

    const topChildren = getTopChildren(safeSettings.maxLimitValue, children);

    const fieldsInUse = new Set<string>(
      workspaceFields.reduce((acc: string[], field) => {
        const isFieldInUse = componentTreeIds.some(componentId => {
          const value = getFieldValue(componentId, field.name);

          return Boolean(value);
        });

        return isFieldInUse ? [...acc, field.name] : acc;
      }, [])
    );

    const activeField =
      isByCustomField(safeSettings.sizeBasedOn) &&
      workspaceFields.find(
        ({ name }) =>
          getSelectedFieldSettingsName(name) === safeSettings.sizeBasedOn
      );

    const activeFieldLabel = activeField
      ? (fieldInterface.getLabel(activeField?._id) ?? undefined)
      : undefined;

    const hasComponentsAvailable = !!rootComponents.length;

    const legend = getLegend(safeSettings, topChildren);

    return {
      data: {
        ...containerData,
        children: topChildren,
      },
      settings: safeSettings,
      workspaceFields,
      fieldsInUse,
      legend,
      hasIncomingReferences,
      hasOutgoingReferences,
      hasComponentsAvailable,
      activeFieldLabel,
    };
  };

  const getContextReducers = streamReducer(treemapContext$, reset);

  return action$.pipe(
    combineReducers<ViewModel>(EMPTY_STATE, [getContextReducers])
  );
};
