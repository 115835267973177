import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { HasUrlFieldValuesById } from './types';
import UrlFieldValueList from './UrlFieldValueList';

const urlFieldValuesPopover =
  ({
    urlFieldValuesByComponentId,
    urlFieldValuesByReferenceId,
  }: HasUrlFieldValuesById) =>
  (element: Element | null) => {
    const componentId = element
      ?.closest(`.component[${GLOBAL_HANDLER_ID_ATTRIBUTE}]`)
      ?.getAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE);

    const componentUrlFieldValues =
      componentId &&
      urlFieldValuesByComponentId
        .get(componentId)
        ?.map(urlFieldValue => ({ ...urlFieldValue, isReference: false }));

    const referenceIds =
      element
        ?.closest('[data-reference-model-ids]')
        ?.getAttribute('data-reference-model-ids')
        ?.split(',') ??
      [
        element
          ?.closest(`.integration[${GLOBAL_HANDLER_ID_ATTRIBUTE}]`)
          ?.getAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE),
      ].filter(ExcludeFalsy);

    const referenceUrlFieldValues = referenceIds?.flatMap(
      referenceId =>
        urlFieldValuesByReferenceId
          .get(referenceId)
          ?.map(urlFieldValue => ({ ...urlFieldValue, isReference: true })) ??
        []
    );

    const urlFieldValues = [
      ...(componentUrlFieldValues || []),
      ...(referenceUrlFieldValues || []),
    ];
    if (!urlFieldValues.length) {
      return null;
    }
    return (
      <UrlFieldValueList element={element} urlFieldValues={urlFieldValues} />
    );
  };
export default urlFieldValuesPopover;
