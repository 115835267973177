import { ArdoqId } from '@ardoq/api-types';
import {
  ComponentDetails,
  DependencyWheelViewSettings,
  LIMIT_OF_DISPLAYED_COMPONENTS_ON_DEPENDENCY_WHEEL,
  ReferenceDetails,
  collectReferenceDetails,
  filterReferenceToRenderIds,
  getComponentsToDisplay,
  getConnectedComponentIds,
  getReferenceIdsByComponentId,
} from '@ardoq/dependency-wheel';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { uniq, uniqBy } from 'lodash';
import type { SimpleReferenceModel, ContextShape } from '@ardoq/data-model';

type WheelDataToDisplay = {
  references: ReferenceDetails[];
  components: ComponentDetails[];
  isComponentsCountLimitExceeded: boolean;
  totalComponentsCount: number;
};

const getDataToDisplay = (
  componentId: ArdoqId,
  selectedWorkspaceId: ArdoqId,
  referenceMap: Map<string, SimpleReferenceModel>
): WheelDataToDisplay => {
  const { componentIds, componentsCount } = getComponentsToDisplayData(
    referenceMap,
    selectedWorkspaceId,
    componentId
  );

  const references = getReferencesToDisplayData(
    referenceMap,
    componentIds,
    componentId
  );

  const components = getComponentsToDisplay(componentIds);

  return {
    references,
    components,
    isComponentsCountLimitExceeded:
      componentsCount > LIMIT_OF_DISPLAYED_COMPONENTS_ON_DEPENDENCY_WHEEL,
    totalComponentsCount: componentsCount,
  };
};

const getReferencesToDisplayData = (
  referenceMap: Map<string, SimpleReferenceModel>,
  componentToDisplayIds: ArdoqId[],
  selectedComponentId?: ArdoqId
) => {
  const componentToDisplayIdsSet = new Set<ArdoqId>(componentToDisplayIds);

  const selectedComponentAndDescendantsIds = selectedComponentId && [
    selectedComponentId,
    ...componentInterface.getSubtreeIds(selectedComponentId),
  ];

  const referenceIds = Array.isArray(selectedComponentAndDescendantsIds)
    ? getReferenceIdsToDisplay(
        selectedComponentAndDescendantsIds,
        componentToDisplayIdsSet,
        referenceMap
      )
    : Array.from(referenceMap.keys());

  const referenceToDisplayIds = filterReferenceToRenderIds({
    referenceIds,
    referenceMap,
    componentToDisplayIdsSet,
    selectedComponentId,
  });

  return uniq(referenceToDisplayIds)
    .map(collectReferenceDetails)
    .filter(ExcludeFalsy);
};

const getComponentsToDisplayData = (
  referenceMap: Map<string, SimpleReferenceModel>,
  workspaceId: ArdoqId,
  componentId?: ArdoqId
) => {
  const componentIds = componentId
    ? [componentId, ...componentInterface.getSubtreeIds(componentId)]
    : componentInterface
        .getComponentsByWorkspaceId(workspaceId)
        .map(component => component._id);

  const connectedComponentIds = getConnectedComponentIds(
    componentIds,
    referenceMap
  );

  return {
    componentsCount: connectedComponentIds.length,
    componentIds: connectedComponentIds.slice(
      0,
      LIMIT_OF_DISPLAYED_COMPONENTS_ON_DEPENDENCY_WHEEL
    ),
  };
};

const getReferenceIdsToDisplay = (
  selectedComponentAndDescendantsIds: ArdoqId[],
  visibleComponentIds: Set<ArdoqId>,
  referenceMap: Map<string, SimpleReferenceModel>
) =>
  selectedComponentAndDescendantsIds.reduce<ArdoqId[]>(
    (acc, componentId) => [
      ...acc,
      ...getReferenceIdsByComponentId(componentId, referenceMap).filter(
        referenceId =>
          visibleComponentIds.has(
            referenceInterface.getSourceComponentId(referenceId)!
          ) &&
          visibleComponentIds.has(
            referenceInterface.getTargetComponentId(referenceId)!
          )
      ),
    ],
    []
  );

const getReferenceTypesForLegend = (references: ReferenceDetails[]) => {
  return uniqBy(
    references
      .map(reference => referenceInterface.getModelType(reference.referenceId))
      .filter(ExcludeFalsy),
    model => model.id
  );
};

export const composeViewModel = (
  context: ContextShape,
  viewSettings: DependencyWheelViewSettings,
  referenceMap: Map<string, SimpleReferenceModel>
) => {
  const { componentId, workspaceId, isExploreMode } = context;
  const {
    references,
    components,
    isComponentsCountLimitExceeded,
    totalComponentsCount,
  } = getDataToDisplay(componentId, workspaceId, referenceMap);

  return {
    viewSettings,
    references,
    components,
    isExploreMode,
    isComponentsCountLimitExceeded,
    totalComponentsCount,
    referenceTypesForLegend: getReferenceTypesForLegend(references),
    selectedComponentId: componentId,
  };
};
