import {
  Font,
  ILabel,
  IRenderContext,
  LabelStyleBase,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import addText from 'yfilesExtensions/addText';
import { Node } from 'graph/node';
import {
  REFERENCE_ID_ATTRIBUTE,
  COMPONENT_ID_ATTRIBUTE,
} from '@ardoq/global-consts';
import {
  CollapsibleGraphGroup,
  GraphItem,
  GraphNode,
  ParentChildGraphEdge,
  GraphEdge,
  GRAPH_ITEM_LABEL_CACHE_SIZE,
} from '@ardoq/graph';
import { classes, createFifoCache } from '@ardoq/common-helpers';
import { DiffType } from '@ardoq/data-model';
import {
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
  BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  DATA_RENDER_HASH,
  LABEL_CLASS,
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
  visualDiffModes,
} from './consts';
import { getLabelPreferredSize, measureLabelElement } from './measureLabels';
import { dataModelId } from 'tabview/graphComponent/graphComponentUtil';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { GLOBAL_HANDLER_ID_ATTRIBUTE } from 'consts';
import { colors } from '@ardoq/design-tokens';
import { createSvgElement } from '@ardoq/dom-utils';
import { getElementOpacityIfTransparentized } from 'tabview/blockDiagram/view/utils';
import { getEdgeLabelIndent } from './util';
import SparkMD5 from 'spark-md5';
import { addFieldLabelAndValue } from 'tabview/blockDiagram/view/yFilesExtensions/labels/legacyLabelUtils';
import { getMaxLabelSize } from 'tabview/blockDiagram/view/yFilesExtensions/labels/getMaxLabelSize';

const labelIsNode = (label: ILabel) => {
  const graphItem = label.owner ? label.owner.tag : null;
  return (
    graphItem instanceof Node ||
    graphItem instanceof GraphNode ||
    graphItem instanceof CollapsibleGraphGroup
  );
};

const getLabelFont = (label: ILabel) =>
  labelIsNode(label)
    ? BLOCK_DIAGRAM_NODE_LABEL_FONT
    : BLOCK_DIAGRAM_EDGE_LABEL_FONT;

const getHash = (label: ILabel) => {
  const owner = label.owner!.tag as Node | GraphItem;

  return SparkMD5.hash(
    `${label.text},${owner.getVisualDiffType()},${owner.isTransparentized}`
  );
};

const renderLabel = (container: SVGGElement, label: ILabel, hash: string) => {
  container.innerHTML = '';
  container.setAttribute(DATA_RENDER_HASH, hash);
  const { text, layout } = label;
  if (!text) {
    return;
  }

  const node: Node | GraphItem = label.owner!.tag;
  const isParentChildReference = node instanceof ParentChildGraphEdge;
  const modelId = dataModelId(node);
  container.setAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE, modelId);

  const diffType = node.getVisualDiffType();
  const isVisualDiffMode = visualDiffModes.has(diffType);
  const isPlaceholder = diffType === DiffType.PLACEHOLDER;

  const isReference = referenceInterface.isReference(modelId);
  const isComponent = componentInterface.isComponent(modelId);
  const classNames = classes(
    LABEL_CLASS,
    isComponent
      ? 'component'
      : node.isGroup()
        ? 'edgeGroup'
        : isParentChildReference
          ? 'parent-child-reference'
          : 'integration',
    isVisualDiffMode && 'label-visual-diff-mode',
    isPlaceholder && 'visual-diff-placeholder-label'
  );

  const entityIdAttribute = isComponent
    ? COMPONENT_ID_ATTRIBUTE
    : isReference
      ? REFERENCE_ID_ATTRIBUTE
      : null;

  if (entityIdAttribute) {
    container.setAttribute(entityIdAttribute, modelId);
  }

  const centerTransformOrigin = `${layout.width / 2}px ${
    layout.height / 4
  }px 0px`;
  container.setAttribute('class', classNames);
  container.setAttribute('transform-origin', centerTransformOrigin);

  const getTextElement = labelIsNode(label)
    ? nodeLabelTextElementCache
    : edgeLabelTextElementCache;
  const {
    textElement: cachedTextElement,
    width,
    isSingleLine,
  } = getTextElement(text);
  // text elements are cached based on the text value, so it's possible we'll be pulling another node's label (with the same text) from the cache.
  // we don't want to steal another node's label! if it already has a parent, it's being used, so just clone it.
  const textElement = cachedTextElement.parentNode
    ? (cachedTextElement.cloneNode(true) as SVGTextElement)
    : cachedTextElement;
  if (isPlaceholder) {
    textElement.style.fill = '';
    textElement.style.stroke = '';
  } else {
    textElement.style.fill = colors.black;
    textElement.style.fillOpacity = String(
      getElementOpacityIfTransparentized(node.isTransparentized)
    );
    textElement.style.stroke = 'none';
  }

  const labelIndent = isReference ? getEdgeLabelIndent(modelId) : 0;

  const translateX = isSingleLine
    ? layout.width / 2
    : width / 2 + LABEL_HORIZONTAL_PADDING / 2 + labelIndent;
  const translateY = isSingleLine
    ? layout.height / 2
    : LABEL_VERTICAL_PADDING / 2 + labelIndent;

  if (isSingleLine) {
    textElement.setAttribute('dominant-baseline', 'central');
  }
  textElement.setAttribute(
    'transform',
    `translate(${translateX} ${translateY})`
  );

  container.style.pointerEvents = modelId ? '' : 'none';

  const rect = createSvgElement('rect', {
    width: `${label.layout.width - labelIndent * 2}`,
    height: `${label.layout.height - labelIndent * 2}`,
    fill: 'white',
    stroke: 'none',
    rx: '4',
    ry: '4',
    transform: `translate(${labelIndent} ${labelIndent})`,
  });

  container.appendChild(rect);
  container.appendChild(textElement);

  addFieldLabelAndValue(textElement, dataModelId(node));
};

export default class ArdoqLabelStyle extends LabelStyleBase {
  static Instance = new ArdoqLabelStyle();
  private constructor() {
    super();
  }
  override createVisual(context: IRenderContext, label: ILabel) {
    const g = createSvgElement('g');

    const hash = getHash(label);
    renderLabel(g, label, hash);

    const transform = LabelStyleBase.createLayoutTransform(label.layout, true);
    transform.applyTo(g);

    return new SvgVisual(g);
  }
  override updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ) {
    const container = (oldVisual as SvgVisual).svgElement as SVGGElement;
    const oldHash = container.getAttribute(DATA_RENDER_HASH);
    const newHash = getHash(label);
    if (oldHash !== newHash) {
      renderLabel(container, label, newHash);
    }
    const transform = LabelStyleBase.createLayoutTransform(label.layout, true);
    transform.applyTo(container);
    return oldVisual;
  }

  override getPreferredSize(label: ILabel) {
    const edgeId =
      label.owner?.tag instanceof GraphEdge ? label.owner?.tag.modelId : null;

    const labelIndent = edgeId ? getEdgeLabelIndent(edgeId) : 0;

    return getLabelPreferredSize(label.text, getLabelFont(label), labelIndent);
  }
}

const createTextElement = (text: string, font: Font) => {
  const textElement = createSvgElement('text', {
    'text-anchor': 'middle',
  });
  font.applyTo(textElement);

  const { width, height } = measureLabelElement(text, font);

  const isSingleLine = height < 2 * font.fontSize;
  if (isSingleLine) {
    // optimization: if the text is not multiline, don't use TextRenderSupport.addText. this call is expensive, and it generates <tspan> elements which leads to a larger DOM.
    textElement.textContent = text;
    return { textElement, width, height, isSingleLine };
  }

  const maximumSize = getMaxLabelSize(font, false);

  addText({
    targetElement: textElement,
    text,
    font,
    ...BLOCK_DIAGRAM_LABEL_TEXT_PARAMS,
    maximumSize,
  });

  return { textElement, width, height, isSingleLine };
};

const [nodeLabelTextElementCache, edgeLabelTextElementCache] = [
  BLOCK_DIAGRAM_NODE_LABEL_FONT,
  BLOCK_DIAGRAM_EDGE_LABEL_FONT,
].map(font =>
  createFifoCache(GRAPH_ITEM_LABEL_CACHE_SIZE, (text: string) =>
    createTextElement(text, font)
  )
);
