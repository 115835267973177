import { useState } from 'react';
import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import { referenceInterface } from '@ardoq/reference-interface';
import { componentInterface } from '@ardoq/component-interface';
import styled, { css } from 'styled-components';
import { fontSizeDefinitions_DEPRECATED } from '../../atomicComponents/fonts/fontSizeDefinitionsWithDefaultFont';
import ExpandButton from 'tabview/pagesView/ExpandButton';
import {
  DEFAULT_NUMBER_OF_LIST_ITEMS_TO_SHOW,
  horizontalSpacing,
} from 'tabview/pagesView/constants';
import AqCollapsible from 'atomicComponents/AqCollapsible/AqCollapsible';
import {
  Header,
  HeaderAndTableContainer,
  TableRow,
  TableRowContainer,
} from 'tabview/pagesView/commonUIElements';
import Description from 'tabview/pagesView/Description';
import Fields from 'tabview/pagesView/Fields';
import { ComponentRepresentation } from '@ardoq/renderers';
import { getReferenceFieldsWithDateRanges } from './utils';
import { withReferencePopover } from 'tabview/pagesView/ReferencePopover';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { colors } from '@ardoq/design-tokens';
import { CUSTOM_SVG_ICON_SELECTOR } from '@ardoq/icons';
import { contrastEnsuredComponentFill } from 'utils/modelCssManager/getCssColors';
import { ARDOQ_DEFAULT_FONT_FAMILY } from '@ardoq/typography';
import { REFERENCE_ID_ATTRIBUTE } from '@ardoq/global-consts';
import { getComponentLabelParts } from '@ardoq/graph';
import {
  PAGE_VIEW_MEDIUM_TEMP_FONT_SIZE,
  PAGE_VIEW_MEDIUM_TEMP_LINE_HEIGHT,
} from 'app/designTokens/fonts';
import { createFifoCache } from '@ardoq/common-helpers';
import SelfTruncatingComponentLabel from 'tabview/SelfTruncatingComponentLabel';
import { MeasureStyledText } from '@ardoq/dom-utils';

const printableStyles = css`
  .printable & {
    word-break: break-word;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }
`;

const RowLabel = styled.div`
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  color: ${colors.grey15};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  span {
    font-weight: normal;
  }

  .printable & {
    align-items: flex-start;
    word-break: break-word;
    overflow: unset;
  }
`;

const ComponentRepresentationContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  min-width: 18px;
  max-height: 18px;

  > ${CUSTOM_SVG_ICON_SELECTOR} {
    margin: 0;
    width: 18px;
    max-height: 18px;
    max-width: 18px;
  }
`;

const ComponentLink = styled.a`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    ${fontSizeDefinitions_DEPRECATED.MEDIUM}
    color: ${colors.grey15};
  }
  ${fontSizeDefinitions_DEPRECATED.MEDIUM}
  color: ${colors.grey15};
  margin-left: ${horizontalSpacing.SMALL};
  ${printableStyles}
`;

const ReferenceTypeLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block !important;
  padding-left: 5px;
  ${printableStyles}
`;

const PageContentLayout = styled.div`
  margin: 0 0 ${horizontalSpacing.MEDIUM} 0;
  background: ${colors.grey90};
`;

interface ComponentLabelProps {
  componentId: ArdoqId;
  isSource: boolean;
  referenceId: ArdoqId;
  style: object;
  bypassRenderLimit: boolean;
}
const ComponentLabel = ({
  componentId,
  isSource,
  referenceId,
  style,
  bypassRenderLimit,
}: ComponentLabelProps) => {
  const componentRepresentationData = componentInterface.getRepresentationData(
    componentId
  ) ?? {
    isImage: false,
    value: null,
  };
  return (
    <RowLabel
      style={{
        ...style,
        paddingLeft: horizontalSpacing.SMALL,
        color: contrastEnsuredComponentFill(componentId),
      }}
    >
      <ComponentRepresentationContainer>
        <ComponentRepresentation
          isImage={componentRepresentationData.isImage}
          value={componentRepresentationData.value}
          icon={componentRepresentationData.icon}
        />
      </ComponentRepresentationContainer>
      <ComponentLink
        data-global-handler-id={componentId}
        style={{ fontWeight: 'bold' }}
        {...withReferencePopover({ componentId, referenceId, isSource })}
        className="component overflowHiddenOnPrint"
      >
        <SelfTruncatingComponentLabel
          {...getComponentLabelParts(componentId)}
          measure={measureReferencePageComponentLinkText}
          bypassRenderLimit={bypassRenderLimit}
        />
      </ComponentLink>
    </RowLabel>
  );
};

const measureReferencePageComponentLinkText = createFifoCache(
  1000,
  (text: string) =>
    MeasureStyledText.Instance.getTextWidth({
      text,
      fontFamily: ARDOQ_DEFAULT_FONT_FAMILY,
      fontSize: PAGE_VIEW_MEDIUM_TEMP_FONT_SIZE,
      lineHeight: PAGE_VIEW_MEDIUM_TEMP_LINE_HEIGHT,
      fontWeight: 'bold',
    })
);

interface ReferenceRowProps {
  sourceComponentId: ArdoqId;
  targetComponentId: ArdoqId;
  referenceId: ArdoqId;
  bypassRenderLimit: boolean;
  defaultFields: Pick<APIFieldAttributes, 'label' | 'name' | 'type' | '_id'>[];
  hideEmptyFields: boolean;
  expandDescription: boolean;
}

const ReferenceRow = ({
  sourceComponentId,
  targetComponentId,
  referenceId,
  bypassRenderLimit,
  defaultFields,
  hideEmptyFields,
  expandDescription,
}: ReferenceRowProps) => {
  const [shouldShowExpandButton, setShowExpandButton] = useState(false);
  return (
    <AqCollapsible
      defaultValue={false}
      renderContent={() => (
        <PageContentLayout {...{ [REFERENCE_ID_ATTRIBUTE]: referenceId }}>
          <Description
            text={referenceInterface.getDescription(referenceId) ?? ''}
            bypassRenderLimit={bypassRenderLimit}
            expandDescription={expandDescription}
          />
          <Fields
            fields={getReferenceFieldsWithDateRanges(referenceId)}
            defaultFields={defaultFields}
            modelId={referenceId}
            showHeader={false}
            hideEmptyFields={hideEmptyFields}
          />
        </PageContentLayout>
      )}
      renderTarget={({
        expand,
        collapse,
        isExpanded,
        toggle,
      }: {
        expand: () => void;
        collapse: () => void;
        isExpanded: boolean;
        toggle: () => void;
      }) => (
        <TableRow
          onClick={toggle}
          $active={isExpanded}
          onMouseEnter={() => setShowExpandButton(true)}
          onMouseLeave={() => setShowExpandButton(false)}
          $showCursor={true}
          {...{ [REFERENCE_ID_ATTRIBUTE]: referenceId }}
        >
          <ComponentLabel
            style={{ width: '30%' }}
            componentId={sourceComponentId}
            isSource={true}
            referenceId={referenceId}
            bypassRenderLimit={bypassRenderLimit}
          />
          <RowLabel
            style={{ fontStyle: 'italic', width: '20%' }}
            className="overflowHiddenOnPrint"
          >
            <ReferenceTypeLabel>
              {referenceInterface.getGlobalReferenceType(referenceId)?.name}
              &nbsp;
              {/* nonbreaking space is needed because the last character will be clipped */}
            </ReferenceTypeLabel>
            {shouldShowExpandButton && (
              <ExpandButton
                marginTop={false}
                isExpanded={isExpanded}
                expand={shouldExpand => (shouldExpand ? expand() : collapse())}
                showLessString=""
                showMoreString=""
              />
            )}
          </RowLabel>
          <ComponentLabel
            style={{
              width: '50%',
            }}
            componentId={targetComponentId}
            isSource={false}
            referenceId={referenceId}
            bypassRenderLimit={bypassRenderLimit}
          />
        </TableRow>
      )}
    />
  );
};

interface ReferencePageProps {
  defaultFields: Pick<APIFieldAttributes, 'label' | 'name' | 'type' | '_id'>[];
  references: {
    incoming: ArdoqId[];
    outgoing: ArdoqId[];
  };
  bypassRenderLimit: boolean;
  hideEmptyFields: boolean;
  expandDescription: boolean;
}

const ReferencePage = ({
  references,
  bypassRenderLimit,
  defaultFields,
  expandDescription,
  hideEmptyFields,
}: ReferencePageProps) => {
  const [isExpanded, setIsExpanded] = useState(bypassRenderLimit);

  const locale = getCurrentLocale();
  const showAllReferences = isExpanded || bypassRenderLimit;

  const allReferences = [...references.incoming, ...references.outgoing].sort(
    (a, b) => {
      const refTypeA = referenceInterface.getGlobalReferenceType(a)?.name ?? '';
      const refTypeB = referenceInterface.getGlobalReferenceType(b)?.name ?? '';
      return localeCompare(refTypeA, refTypeB, locale);
    }
  );
  const referencesToShow = showAllReferences
    ? allReferences
    : allReferences.slice(0, DEFAULT_NUMBER_OF_LIST_ITEMS_TO_SHOW);
  return referencesToShow.length > 0 ? (
    <>
      <HeaderAndTableContainer>
        <Header>
          References ({references.incoming.length} incoming,{' '}
          {references.outgoing.length} outgoing)
        </Header>
        {referencesToShow.map((refId, i) => (
          <TableRowContainer key={`${refId}-${i}`}>
            <ReferenceRow
              sourceComponentId={
                referenceInterface.getSourceComponentId(refId) ?? ''
              }
              targetComponentId={
                referenceInterface.getTargetComponentId(refId) ?? ''
              }
              referenceId={refId}
              bypassRenderLimit={bypassRenderLimit}
              defaultFields={defaultFields}
              expandDescription={expandDescription}
              hideEmptyFields={hideEmptyFields}
            />
          </TableRowContainer>
        ))}
      </HeaderAndTableContainer>
      {allReferences.length > DEFAULT_NUMBER_OF_LIST_ITEMS_TO_SHOW &&
        !bypassRenderLimit && (
          <ExpandButton
            isExpanded={showAllReferences}
            expand={setIsExpanded}
            showLessString="Show fewer references"
            showMoreString="Show all references"
          />
        )}
    </>
  ) : null;
};

export default ReferencePage;
