import type { ContextShape } from '@ardoq/data-model';
import { ArdoqId } from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import allDescendantsReducer from 'modelInterface/components/allDescendantsReducer';
import type { NamedNumericRange, NumericRange } from '@ardoq/graph';
import { format } from 'utils/numberUtils';
import { getRoundedInterval } from 'tabview/ticks';
import {
  type BubbleChartDataPointModel,
  BubbleChartGridMode,
  type BubbleChartViewSettings,
  type IncludeDescendants,
} from './types';
import {
  defaultBubbleChartViewSettings,
  DISPLAYED_LABELS_LIMIT,
} from './consts';
import { groupBy } from 'lodash';

const getComponentsAtLevel = (workspaceId: ArdoqId, level: number) => {
  const rootComponents = componentInterface.getRootComponents(workspaceId);
  let currentLevel = 1; // root components are at level 1
  let currentLevelComponents = rootComponents;
  while (currentLevel++ < level) {
    currentLevelComponents = currentLevelComponents.flatMap(componentId =>
      componentInterface.getChildren(componentId)
    );
  }
  return currentLevelComponents;
};

type ScaleFactory = (params: {
  domain: NamedNumericRange; // input
  range: NamedNumericRange; // output
}) => (x: number) => number;
export const bubbleAreaScaleFactory: ScaleFactory = ({ domain, range }) => {
  const norm = (({ min, max }: NamedNumericRange) => {
    const extent = max - min;
    return (x: number) => (x - min) / extent;
  })(domain);
  const base = (({ min, max }: NamedNumericRange) => {
    const invertMin = 1 / min;
    return (x: number) => invertMin - (invertMin - max) * norm(x);
  })(range);
  const exponent = (({ min, max }: NamedNumericRange) => {
    const extent = max - min;
    const middle = min + extent / 2;
    return (x: number) => (x - middle) / extent;
  })(domain);
  return x => base(x) ** exponent(x);
};

const hasXAndYFieldValues = (
  componentId: ArdoqId,
  xFieldName: string,
  yFieldName: string
) => {
  const xFieldValue = componentInterface.getFieldValue(componentId, xFieldName);
  if (xFieldValue === null || xFieldValue === undefined) {
    return false;
  }
  const yFieldValue = componentInterface.getFieldValue(componentId, yFieldName);
  if (yFieldValue === null || yFieldValue === null) {
    return false;
  }
  return true;
};
const hasValidComponents = (
  components: ArdoqId[],
  xFieldName: string,
  yFieldName: string
) =>
  components.some(componentId =>
    hasXAndYFieldValues(componentId, xFieldName, yFieldName)
  );

export const resolveIncludeAllDescendants = (
  value: BubbleChartViewSettings['includeAllDescendants']
): IncludeDescendants =>
  value === true ? 'all' : value === false ? 'direct' : value;

/**
 *
 * @returns hasComponentsAvailable: boolean; components: string[]
 * `hasComponentsAvailable` - Will be truthy when having components in the provided context (non filtered)
 * `components` - Filtered set of components
 */
export const getComponents = (
  { workspaceId, componentId }: ContextShape,
  xFieldName: string,
  yFieldName: string,
  includeAllDescendants: BubbleChartViewSettings['includeAllDescendants']
) => {
  let hasComponentsAvailable = false;
  let startSet: ArdoqId[] | null = null;
  const componentInContext = componentId;
  if (componentInContext) {
    hasComponentsAvailable = true;
    const children = componentInterface.getChildren(componentInContext, true);
    if (children.length > 0 || includeAllDescendants) {
      startSet = children;
    } else {
      const parent = componentInterface.getParentId(componentInContext);
      if (parent) {
        startSet = componentInterface.getChildren(parent, true);
      }
    }
  }

  if (!startSet && workspaceId) {
    startSet = componentInterface.getRootComponents(workspaceId);
    hasComponentsAvailable = !!startSet.length;
  }

  if (!startSet || startSet.length === 0) {
    return { components: [], hasComponentsAvailable };
  }

  switch (resolveIncludeAllDescendants(includeAllDescendants)) {
    case 'all':
      return {
        hasComponentsAvailable,
        components: startSet
          ? startSet
              .reduce(allDescendantsReducer, [])
              .filter(componentId =>
                hasXAndYFieldValues(componentId, xFieldName, yFieldName)
              )
              .filter(componentInterface.isIncludedInContextByFilter)
          : [],
      };
    case 'direct':
      while (
        !hasValidComponents(startSet, xFieldName, yFieldName) &&
        startSet.length > 0
      ) {
        startSet = getComponentsAtLevel(
          componentInterface.getWorkspaceId(startSet[0]) ?? '',
          componentInterface.getLevel(startSet[0]) + 1
        );
      }
      return {
        hasComponentsAvailable,
        components: startSet
          .filter(componentId =>
            hasXAndYFieldValues(componentId, xFieldName, yFieldName)
          )
          .filter(componentInterface.isIncludedInContextByFilter),
      };
    case 'none': {
      const components = (
        componentId
          ? [componentId]
          : componentInterface.getRootComponents(workspaceId)
      ).filter(componentInterface.isIncludedInContextByFilter);
      return { components, hasComponentsAvailable: components.length > 0 };
    }
  }
};

/** removes any non-breaking spaces from the formatted number, because spaces in axis labels look bad. weird cultures like Norway use a space as the thousands separator */
export const formatAxisLabel = (value: number) =>
  format(value).replaceAll('\xa0', '');

export const getRoundedRange = (range: NumericRange): NumericRange => {
  const interval = getRoundedInterval(range);
  return [
    interval * Math.floor(range[0] / interval),
    interval * Math.ceil(range[1] / interval),
  ];
};
/** we'll be clamping to these max safe numbers to avoid infinite loops caused by inability to increment and compare with decimal precision. */
const MAX_SAFE_NUMBER = (Number.MAX_SAFE_INTEGER + 1) / 16 - 1;
const MIN_SAFE_NUMBER = -MAX_SAFE_NUMBER;
const clampToSafeNumber = (value: number) =>
  Math.max(MIN_SAFE_NUMBER + 1, Math.min(MAX_SAFE_NUMBER - 1, value));
const clampToSafeNumbers = ([min, max]: NumericRange): NumericRange => [
  clampToSafeNumber(min),
  clampToSafeNumber(max),
];
export const makeFinite = (range: NumericRange): NumericRange => [
  Number.isFinite(range[0]) ? range[0] : 0,
  Number.isFinite(range[1]) ? range[1] : 0,
];
export const getRange = (
  data: BubbleChartDataPointModel[],
  fieldName: 'x' | 'y' | 'radius'
): NumericRange =>
  inflateZeroRange(
    clampToSafeNumbers(makeFinite(getDataRange(data, fieldName)))
  );
export const inflateZeroRange = (range: NumericRange): NumericRange =>
  range[0] === range[1] ? [range[0] - 1, range[1] + 1] : range;
export const getDataRange = (
  data: BubbleChartDataPointModel[],
  fieldName: 'x' | 'y' | 'radius'
): NumericRange => [
  Math.min(...data.map(item => item[fieldName])),
  Math.max(...data.map(item => item[fieldName])),
];

export const bubbleChartXYDomains = (
  viewSettings: BubbleChartViewSettings,
  data: BubbleChartDataPointModel[]
) => {
  const {
    background,
    customQuadrantSettings: viewSettingsCustomQuadrantSettings,
  } = viewSettings;
  const timeMode = background === BubbleChartGridMode.TIME;
  const customQuadrants = background === BubbleChartGridMode.CUSTOM;

  const customQuadrantSettings =
    viewSettingsCustomQuadrantSettings ||
    defaultBubbleChartViewSettings.customQuadrantSettings;

  const customQuadrantsXRange: NumericRange = [
    customQuadrantSettings.xMinimum,
    customQuadrantSettings.xMaximum,
  ];
  const customQuadrantsYRange: NumericRange = [
    customQuadrantSettings.yMinimum,
    customQuadrantSettings.yMaximum,
  ];

  const xNeedsAutoRange =
    !timeMode &&
    (!customQuadrants ||
      !Number.isFinite(customQuadrantsXRange[0]) ||
      !Number.isFinite(customQuadrantsXRange[1]));
  const yNeedsAutoRange =
    !timeMode &&
    (!customQuadrants ||
      !Number.isFinite(customQuadrantsYRange[0]) ||
      !Number.isFinite(customQuadrantsYRange[1]));

  const invalidRange: NumericRange = [NaN, NaN];
  const xAutoRange = xNeedsAutoRange
    ? getRoundedRange(getRange(data, 'x'))
    : invalidRange;
  const yAutoRange = yNeedsAutoRange
    ? getRoundedRange(getRange(data, 'y'))
    : invalidRange;
  if (customQuadrants) {
    customQuadrantsXRange[0] = Number.isFinite(customQuadrantsXRange[0])
      ? customQuadrantsXRange[0]
      : xAutoRange[0];
    customQuadrantsXRange[1] = Number.isFinite(customQuadrantsXRange[1])
      ? customQuadrantsXRange[1]
      : xAutoRange[1];
    customQuadrantsYRange[0] = Number.isFinite(customQuadrantsYRange[0])
      ? customQuadrantsYRange[0]
      : yAutoRange[0];
    customQuadrantsYRange[1] = Number.isFinite(customQuadrantsYRange[1])
      ? customQuadrantsYRange[1]
      : yAutoRange[1];
  }
  const x: NumericRange = timeMode
    ? [1, 5]
    : customQuadrants
      ? inflateZeroRange(makeFinite(customQuadrantsXRange))
      : xAutoRange;
  const y: NumericRange = timeMode
    ? [1, 5]
    : customQuadrants
      ? inflateZeroRange(makeFinite(customQuadrantsYRange))
      : yAutoRange;
  return { x, y };
};

/**
 * Sorts data points by clusters and organizes them so that:
 * 1. The largest points from each cluster are displayed first
 * 2. The first DISPLAYED_LABELS_LIMIT points are used for displaying labels
 *
 * @returns Sorted array of data points
 */
export const sortDataPointsByCluster = (
  dataPoints: BubbleChartDataPointModel[]
): BubbleChartDataPointModel[] => {
  if (dataPoints.length <= DISPLAYED_LABELS_LIMIT) {
    return dataPoints.sort((a, b) => b.radius - a.radius);
  }

  // Group points by coordinates and radius (create clusters)
  const clusters = Object.values(
    groupBy(
      dataPoints,
      dataPoint => `${dataPoint.x},${dataPoint.y},${dataPoint.radius}`
    )
  );

  // Collect points for labels, selecting them by levels
  const dataPointsForLabels: BubbleChartDataPointModel[] = [];

  for (
    let clusterDepth = 0;
    dataPointsForLabels.length < DISPLAYED_LABELS_LIMIT;
    clusterDepth++
  ) {
    let dataPointAdded = false;

    for (const cluster of clusters) {
      if (clusterDepth < cluster.length) {
        dataPointsForLabels.push(cluster[clusterDepth]);
        dataPointAdded = true;

        if (dataPointsForLabels.length >= DISPLAYED_LABELS_LIMIT) {
          break;
        }
      }
    }
    // Break the loop, because there are no datapoints on next cluster level
    if (!dataPointAdded) {
      break;
    }
  }

  // Sort labeled points by radius (from largest to smallest)
  dataPointsForLabels.sort((a, b) => b.radius - a.radius);

  // Create a set of labeled point IDs for quick lookup
  const labeledPointIds = new Set(dataPointsForLabels);

  // Collect remaining points
  const remainingDataPoints: BubbleChartDataPointModel[] = [];
  clusters.forEach(cluster => {
    cluster.forEach(point => {
      if (!labeledPointIds.has(point)) {
        remainingDataPoints.push(point);
      }
    });
  });

  return [...dataPointsForLabels, ...remainingDataPoints];
};
