import { DropdownItem } from '@ardoq/dropdown-menu';
import { getComponentMenu } from 'contextMenus/componentMenu';
import { getReferenceMenu } from 'contextMenus/referenceMenu';
import { getDropdownDivider } from 'contextMenus/menuItems';
import { dispatchAction } from '@ardoq/rxbeach';
import { relationshipsViewZoomToFit } from './actions';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { RelationshipsLinkVisual, RelationshipsNode } from './types';
import { getWorkspaceMenu } from 'contextMenus/workspaceMenu';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { getZoomToFitContextMenuItem } from 'contextMenus/utils';
import {
  getGroupControlContextMenuItems,
  getNonComponentGroupMenu,
} from 'contextMenus/groupContextMenu';
import { ContextMenuTestIds } from 'contextMenus/consts';
import { ContextMenuData } from '@ardoq/context-menu';
import { isOpenViewInferenceWindowMenuItem } from 'traversals/viewInference/utils';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

const zoomToFitContextMenuItem = (
  node: RelationshipsNode,
  viewInstanceId: string
): DropdownItem =>
  getZoomToFitContextMenuItem(() =>
    dispatchAction(relationshipsViewZoomToFit({ node }), viewInstanceId)
  );

interface BuildMenuOptionsArguments {
  event: MouseEvent;
  node: RelationshipsNode | null;
  link: RelationshipsLinkVisual | null;
  viewInstanceId: string;
  isViewpointMode: boolean;
  toggleCollapsed: (node: RelationshipsNode) => void;
}

export const getContextMenuData = ({
  event,
  node,
  link,
  viewInstanceId,
  isViewpointMode,
  toggleCollapsed,
}: BuildMenuOptionsArguments): ContextMenuData => {
  if (
    !(event.target instanceof HTMLElement || event.target instanceof SVGElement)
  ) {
    return { options: null };
  }

  const eventDetails = {
    event,
    target: event.target,
    x: event.clientX,
    y: event.clientY,
  };

  const referenceIds =
    link && link.modelIds.filter(referenceInterface.isReference);
  if (referenceIds?.length) {
    return {
      testId: ContextMenuTestIds.REFERENCE,
      options: getReferenceMenu({
        referenceIds,
        isViewpointMode,
        ...eventDetails,
      }),
    };
  }

  if (!node) {
    return { options: null };
  }

  // determine what has been clicked
  const eventTargetModelId = node.modelId;
  const isEventTargetComponent =
    eventTargetModelId && componentInterface.isComponent(eventTargetModelId);
  const isEventTargetGroup = Boolean(node.children?.length);
  const isEventTargetWorkspace =
    eventTargetModelId && workspaceInterface.isWorkspace(eventTargetModelId);

  // create group control item args
  const expandOrCollapseGroupContextMenuItemProps = {
    onExpandOrCollapseGroupContextMenuItemClick: () => toggleCollapsed(node),
    isGroupOpen: node.open,
  };
  const onZoomToFitContextMenuItemClick = () =>
    dispatchAction(relationshipsViewZoomToFit({ node }), viewInstanceId);
  const clickedElement = event.target;

  const groupControlMenuItems = getGroupControlContextMenuItems({
    expandOrCollapseGroupContextMenuItemProps,
    onZoomToFitContextMenuItemClick,
    clickedElement,
  });

  // display the correct context menu

  if (isEventTargetComponent) {
    const componentMenu =
      getComponentMenu({
        // rv3 does not support multiselect for now
        componentIds: [eventTargetModelId],
        eventTargetModelId,
        isViewpointMode,
        ...eventDetails,
      }) ?? [];

    const viewInferenceMenuItems =
      componentMenu[0] &&
      componentMenu[1] &&
      isOpenViewInferenceWindowMenuItem(componentMenu[0])
        ? componentMenu.slice(0, 2)
        : [];

    return {
      testId: ContextMenuTestIds.COMPONENT,
      options: [
        ...viewInferenceMenuItems, // we always want to have the "Add new dataset with AI" button on the top of the list
        ...(isEventTargetGroup
          ? groupControlMenuItems
          : [
              zoomToFitContextMenuItem(node, viewInstanceId),
              getDropdownDivider(),
            ]),
        ...(viewInferenceMenuItems.length
          ? componentMenu.slice(2)
          : componentMenu),
      ].filter(ExcludeFalsy),
    };
  }

  if (isEventTargetWorkspace) {
    // under premise that workspace is always a group
    const activeScenarioState = getActiveScenarioState();

    const workspaceMenu =
      getWorkspaceMenu({
        workspaceIds: [eventTargetModelId],
        scenarioId:
          activeScenarioOperations.getActiveScenarioId(activeScenarioState),
        isViewpointMode,
        ...eventDetails,
      }) || [];

    return {
      testId: ContextMenuTestIds.WORKSPACE,
      options: [...groupControlMenuItems, ...workspaceMenu].filter(
        ExcludeFalsy
      ),
    };
  }

  if (
    isEventTargetGroup &&
    !isEventTargetWorkspace &&
    !isEventTargetComponent
  ) {
    return {
      testId: ContextMenuTestIds.NON_COMPONENT_GROUP,
      options:
        getNonComponentGroupMenu({
          eventTargetModelId,
          clickedElement,
          expandOrCollapseGroupContextMenuItemProps,
          onZoomToFitContextMenuItemClick,
          isViewpointMode,
        }) || [],
    };
  }

  return { options: null };
};
