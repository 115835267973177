import { Features, hasFeature } from '@ardoq/features';
import { confirm, alert } from '@ardoq/modal';
import { APP_VERSION } from 'appConfig';
import { UsersWithEmails } from 'components/AppMainSidebar/ListOrgAdmins';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';

interface GetSupportContextOpts {
  message?: string;
  chat?: boolean;
  prePopulateMessage?: string | undefined;
}

const getSupportContext = ({
  message = '',
  chat = false,
  prePopulateMessage = undefined,
}: GetSupportContextOpts) => ({
  location: `${window.location.pathname}, hash:${window.location.hash}`,
  versionFront: APP_VERSION,
  versionAPI: window.API_VERSION,
  message,
  chat,
  prePopulateMessage,
});

export const showSupport = async (opts: GetSupportContextOpts = {}) => {
  const supportContext = getSupportContext(opts);
  const orgAdmins = orgUsersInterface.getOrgAdmins();
  const noIntercom = hasFeature(Features.DISABLED_INTERCOM);
  if (noIntercom) {
    await alert({
      title: 'Contact your admin',
      text: UsersWithEmails(orgAdmins),
    });
  } else if (typeof window.Intercom === 'undefined') {
    if (
      await confirm({
        title: 'Unable to load support',
        text: (
          <>
            <p>
              Unable to load the support chat. Please try disabling your
              adblocker or contact us{' '}
              <a
                href="mailto:support@ardoq.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                by e-mail
              </a>
              .
            </p>
            <p>
              <em>
                This might be due to firewall restrictions if you are on an
                on-premise installation.
              </em>
            </p>
          </>
        ),
        cancelButtonTitle: '', // this hides the cancel button
        confirmButtonTitle: 'Email Support',
      })
    ) {
      window.open('mailto:support@ardoq.com', 'email-support');
    }
  } else if (supportContext.prePopulateMessage) {
    window.Intercom('showNewMessage', supportContext.prePopulateMessage);
  } else {
    window.Intercom(supportContext.chat ? 'show' : 'showNewMessage');
  }
};
