import { Observable } from 'rxjs';
import {
  ViewSettings,
  getViewSettingsStreamWithChanges,
} from 'viewSettings/viewSettingsStreams';
import {
  TimelineViewSettings,
  TIMELINE_VIEW_SOURCE_DATA_RELEVANT_VIEW_SETTINGS,
  TIMELINE_RENDERING_RELEVANT_VIEW_SETTINGS,
} from '@ardoq/timeline2024';
import { ViewIds } from '@ardoq/api-types';
import { map, filter } from 'rxjs/operators';
import {
  getFilteredViewSettings$,
  getViewSettingsFilter,
} from 'views/filteredViewSettings$';
import { viewSettingsConsts } from '@ardoq/view-settings';

// #region view settings stream filters

type IgnoreSettingsPredicate = (
  { changedSettings }: ViewSettings<TimelineViewSettings>,
  index: number
) => boolean;

const ignoreViewSettingsChangeForModelSourceData =
  getViewSettingsFilter<TimelineViewSettings>(
    ...TIMELINE_RENDERING_RELEVANT_VIEW_SETTINGS
  );

const ignoreViewSettingsChangeForRenderingData =
  getViewSettingsFilter<TimelineViewSettings>(
    ...TIMELINE_VIEW_SOURCE_DATA_RELEVANT_VIEW_SETTINGS
  );

const getFilteredTimelineViewSettings = (
  viewSettings$: Observable<ViewSettings<TimelineViewSettings>>,
  predicate: IgnoreSettingsPredicate
) =>
  getFilteredViewSettings$(viewSettings$).pipe(
    filter((viewSettings, index) => !predicate(viewSettings, index)),
    map(({ currentSettings }) => currentSettings)
  );

const isLegendStateChange = ({
  changedSettings,
}: ViewSettings<TimelineViewSettings>) =>
  changedSettings.some(
    changedSetting => changedSetting[0] === viewSettingsConsts.IS_LEGEND_ACTIVE
  );

// #endregion

// #region stream composition

const rawTimelineViewSettings$ =
  getViewSettingsStreamWithChanges<TimelineViewSettings>(ViewIds.TIMELINE);

export const viewSettingsForModelSourceData$ = getFilteredTimelineViewSettings(
  rawTimelineViewSettings$,
  ignoreViewSettingsChangeForModelSourceData
);
export const viewSettingsForRenderingData$ = getFilteredTimelineViewSettings(
  rawTimelineViewSettings$,
  ignoreViewSettingsChangeForRenderingData
);

export const timelineViewLegend$ = rawTimelineViewSettings$.pipe(
  filter(isLegendStateChange),
  map(({ currentSettings }) => currentSettings)
);

// #endregion
