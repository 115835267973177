import SparkMD5 from 'spark-md5';
import {
  CreatedItemsProcessedPayload,
  LoadedStateUpdatedPayload,
  SetTraversalIdPayload,
  ToggleCollapsedPathActivePayload,
  ToggleLoadedStateVisibilityPayload,
} from './actions';
import {
  ArdoqId,
  CreatedItemsLoadedState,
  DirectedTriple,
  DirectedTripleWithFilters,
  isCreatedItemsLoadedState,
  isTraversalLoadedState,
  isManualComponentSelection,
  isQueryComponentSelection,
  LoadedState,
  SearchLoadedState,
  TraversalLoadedState,
  LoadedStateHash,
  StartContextSelectionType,
  LoadedStateType,
  Traversal,
  isTraversalCreatedInViewLoadedState,
  StartSetTraversalParams,
  TraversalCreatedInViewLoadedState,
  isAnyTraversalLoadedState,
  APIComponentAttributes,
  APIParentChildReferenceAttributes,
  Path,
  LoadedStateParams,
  TraversalLoadedStateParams,
  LoadedGraphChunk,
  APIViewpointAttributes,
  TraversalCreatedInViewLoadedStateParams,
  ComponentSelection,
  SearchLoadedStateParams,
  TraversalParams,
  isSearchLoadedState,
  TraversalPathMatchingType,
} from '@ardoq/api-types';
import { uniq, uniqBy, uniqWith, partition, isEqual, pick, omit } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  DisableableDirectedTripleWithFilters,
  NamedDirectedTriple,
} from '../viewpointBuilder/types';
import { flattenDict } from './flattenDict';
import { collapsedPathToHash } from 'viewpointBuilder/collapsePathsTab/collapsedPathToHash';
import { getParentChildTriples } from './getParentChildTriples';
import { isSameTriple, toCollapsedPaths } from 'traversals/pathAndGraphUtils';
import {
  componentOperations,
  referenceAttributesOperations,
} from '@ardoq/core';
import { pathToKey } from '../viewpointBuilder/traversals/pathToKey';

const excludeDuplicateLoadedStates = (
  existingStates: LoadedState[],
  registerStates: LoadedState[]
) => {
  const existingStateHashes = existingStates.map(stateToHash);
  return registerStates.filter(
    state => !existingStateHashes.includes(stateToHash(state))
  );
};

const normalizeTriple = (
  triple: DirectedTripleWithFilters
): DirectedTripleWithFilters => ({
  ...triple,
  // Make sure that the properties exist on the object, even if they are
  // undefined to ensure consistent hashing.
  sourceFilter: triple.sourceFilter,
  targetFilter: triple.targetFilter,
  referenceFilter: triple.referenceFilter,
});

const normalizePath = (path: DirectedTriple[]): DirectedTriple[] =>
  path.map(normalizeTriple);

type IsLoadedStateWithQueryComponentSelection = {
  (loadedState: LoadedState): loadedState is TraversalLoadedState;
  (loadedState: LoadedStateParams): loadedState is TraversalLoadedStateParams;
};
/**
 *
 * This function is only because typescript needs a bit of help to understand the types.
 */
const isLoadedStateWithQueryComponentSelection: IsLoadedStateWithQueryComponentSelection =
  (loadedState): loadedState is TraversalLoadedState =>
    isTraversalLoadedState(loadedState) &&
    isQueryComponentSelection(loadedState.data.componentSelection);

export const normalizeLoadedState = <T extends LoadedStateParams>(
  loadedState: T
): T => {
  if (isAnyTraversalLoadedState(loadedState)) {
    const { data } = loadedState;
    if (isManualComponentSelection(data.componentSelection)) {
      return {
        ...loadedState,
        data: {
          filters: data.filters,
          paths: data.paths.map(normalizePath),
          componentSelection: {
            startSet: data.componentSelection.startSet,
            startContextSelectionType:
              StartContextSelectionType.MANUAL_SELECTION,
          },
          pathMatching: data.pathMatching,
          pathCollapsingRules: data.pathCollapsingRules,
        },
      };
    }

    if (isLoadedStateWithQueryComponentSelection(loadedState)) {
      const nextLoadedState: TraversalLoadedStateParams = {
        ...loadedState,
        data: {
          filters: data.filters,
          paths: data.paths.map(normalizePath),
          componentSelection: {
            startQuery: data.componentSelection.startQuery,
            startContextSelectionType:
              data.componentSelection.startContextSelectionType,
          },
          pathMatching: data.pathMatching,
          pathCollapsingRules: data.pathCollapsingRules,
        },
      };

      // Safe type-cast as we ensured that nextLoadedState is a TraversalLoadedStateParams
      // and will keep any additional properties from loadedState.
      return nextLoadedState as T;
    }
  }
  // TODO should probably be normalized too.
  return loadedState;
};

const reduceRegisterLoadedState = (
  state: LoadedState[],
  loadedState: LoadedState[]
) => [
  ...state,
  ...excludeDuplicateLoadedStates(state, loadedState.map(normalizeLoadedState)),
];

const reduceClearLoadedState = () => [];

// called after saving a new traversal and receiving id from server
const reduceSetTraversalId = (
  state: LoadedState[],
  { id: traversalId, loadedStateHash }: SetTraversalIdPayload
) => {
  return state.map(loadedState => {
    if (stateToHash(loadedState) === loadedStateHash) {
      return {
        ...(loadedState as
          | TraversalLoadedState // only those two can be saved
          | TraversalCreatedInViewLoadedState),
        traversalId,
        type: LoadedStateType.TRAVERSAL,
      } satisfies TraversalLoadedState;
    }
    return loadedState;
  });
};
const reduceToggleLoadedStateVisibility = (
  state: LoadedState[],
  { loadedStateHash }: ToggleLoadedStateVisibilityPayload
) => {
  return state.map(loadedState => {
    if (stateToHash(loadedState) === loadedStateHash) {
      return {
        ...loadedState,
        isHidden: !loadedState.isHidden,
      };
    }
    return loadedState;
  });
};

const reduceDeleteLoadedState = (
  states: LoadedState[],
  loadedStateHash: LoadedStateHash
) => {
  return states.filter(state => stateToHash(state) !== loadedStateHash);
};

/**
 * Atomic update of loaded state because params cannot be updated separately
 * as the loadedGraph/scopeData would not match.
 */
const replaceExistingLoadedState = (
  state: LoadedState[],
  {
    existingHash,
    loadedState,
    isMainLoadedState = false,
  }: LoadedStateUpdatedPayload
) => {
  if (isMainLoadedState) {
    return [loadedState, ...state.slice(1)];
  }
  return state.map(existingState => {
    if (stateToHash(existingState) === existingHash) {
      return loadedState;
    }
    return existingState;
  });
};

const addDataToLoadedStateSearch = (
  loadedState: CreatedItemsLoadedState,
  newCreatedItemsLoadedState: CreatedItemsLoadedState
) => {
  const componentIds = uniq([
    ...loadedState.data.componentIds,
    ...newCreatedItemsLoadedState.data.componentIds,
  ]);
  const referenceIds = uniq([
    ...loadedState.data.referenceIds,
    ...newCreatedItemsLoadedState.data.referenceIds,
  ]);
  const references = uniqBy(
    [
      ...(loadedState.componentIdsAndReferences?.references ?? []),
      ...(newCreatedItemsLoadedState.componentIdsAndReferences?.references ??
        []),
    ],
    '_id'
  );

  const parentChildReferences = uniqBy(
    [
      ...(loadedState.componentIdsAndReferences?.parentChildReferences ?? []),
      ...(newCreatedItemsLoadedState.componentIdsAndReferences
        ?.parentChildReferences ?? []),
    ],
    '_id'
  );

  return {
    ...loadedState,
    componentIdsAndReferences: {
      componentIds,
      references,
      parentChildReferences,
      startSetResult: componentIds,
    },
    data: {
      ...loadedState.data,
      referenceIds,
      componentIds,
    },
  };
};

const searchParamsFromSelection = (
  componentSelection: ComponentSelection
): SearchLoadedStateParams => ({
  data: { componentSelection },
  isHidden: false,
  type: LoadedStateType.SEARCH,
});

const reduceUpdateOrCreateLoadedStateOnItemCreated = (
  states: LoadedState[],
  createdItemsLoadedState: CreatedItemsLoadedState
): LoadedState[] => {
  const hasCreatedItemsLoadedState = states.some(isCreatedItemsLoadedState);
  if (!hasCreatedItemsLoadedState) {
    return [...states, createdItemsLoadedState];
  }
  return states.map(state => {
    if (isCreatedItemsLoadedState(state)) {
      return addDataToLoadedStateSearch(state, createdItemsLoadedState);
    }
    return state;
  });
};

const getLoadedStateWithHash = <T extends LoadedStateParams>(
  state: T[],
  loadedStateHash: LoadedStateHash
) => {
  return state.find(
    loadedState => stateToHash(loadedState) === loadedStateHash
  );
};

const getTraversalWithLoadedStateHash = (
  state: LoadedState[],
  loadedStateHash: LoadedStateHash
): TraversalLoadedState | TraversalCreatedInViewLoadedState | null => {
  const loadedState = getLoadedStateWithHash(state, loadedStateHash);
  return loadedState && isAnyTraversalLoadedState(loadedState)
    ? loadedState
    : null;
};

const stateToHash = (loadedState: LoadedState | LoadedStateParams) => {
  return SparkMD5.hash(
    flattenDict(normalizeLoadedState(loadedState).data ?? {}).join('')
  );
};

const toPathsWithStartSetResultsAndCollapsedPaths = (state: LoadedState[]) =>
  state.map(loadedState => {
    if (isAnyTraversalLoadedState(loadedState)) {
      const paths = loadedState.data.paths;
      const collapsedPaths = loadedState.data.pathCollapsingRules
        ? toCollapsedPaths(
            paths,
            loadedState.data.pathCollapsingRules.filter(
              ({ isActive }) => isActive
            )
          )
        : paths;

      return {
        paths,
        collapsedPaths,
        startSetResult:
          loadedState.componentIdsAndReferences?.startSetResult ?? [],
      };
    }
    return {
      paths: [],
      collapsedPaths: [],
      startSetResult:
        loadedState.componentIdsAndReferences?.startSetResult ?? [],
    };
  });

const handleRemovedComponentsAndReferences = (
  states: LoadedState[],
  removedEntityIds: Set<ArdoqId>
): LoadedState[] =>
  states.some(loadedState =>
    containsRemovedEntityIds(loadedState, removedEntityIds)
  )
    ? states
        .map(loadedState =>
          removeIdsFromLoadedState(loadedState, removedEntityIds)
        )
        .filter(ExcludeFalsy)
    : states;

const containsRemovedEntityIds = (
  { componentIdsAndReferences }: LoadedState,
  removedEntityIds: Set<ArdoqId>
) => {
  if (!componentIdsAndReferences) {
    return false;
  }

  const { componentIds, references, parentChildReferences, startSetResult } =
    componentIdsAndReferences;

  return (
    componentIds.some(id => removedEntityIds.has(id)) ||
    references.some(({ _id }) => removedEntityIds.has(_id)) ||
    parentChildReferences.some(
      ({ source, target }) =>
        removedEntityIds.has(source) || removedEntityIds.has(target)
    ) ||
    startSetResult.some(id => removedEntityIds.has(id))
  );
};

const removeIdsFromLoadedState = (
  loadedState: LoadedState,
  removedEntityIds: Set<ArdoqId>
): LoadedState | null => {
  const { componentIdsAndReferences: currentComponentIdsAndReferences } =
    loadedState;

  const componentIdsAndReferences = {
    componentIds: currentComponentIdsAndReferences.componentIds.filter(
      id => !removedEntityIds.has(id)
    ),
    references: currentComponentIdsAndReferences.references.filter(
      ({ _id }) => !removedEntityIds.has(_id)
    ),
    parentChildReferences:
      currentComponentIdsAndReferences.parentChildReferences.filter(
        ({ source, target }) =>
          !removedEntityIds.has(source) && !removedEntityIds.has(target)
      ),
    startSetResult: currentComponentIdsAndReferences.startSetResult.filter(
      id => !removedEntityIds.has(id)
    ),
  };

  if (isTraversalLoadedState(loadedState)) {
    return {
      ...loadedState,
      componentIdsAndReferences,
      data: removeIdsFromTraversalData(loadedState.data, removedEntityIds),
    };
  }
  if (isTraversalCreatedInViewLoadedState(loadedState)) {
    const data = removeIdsFromTraversalCreatedFromViewData(
      loadedState.data,
      removedEntityIds
    );
    if (data === null) {
      return null;
    }

    return {
      ...loadedState,
      componentIdsAndReferences,
      data: data,
    } satisfies TraversalCreatedInViewLoadedState;
  }
  if (isCreatedItemsLoadedState(loadedState)) {
    return {
      ...loadedState,
      componentIdsAndReferences,
      data: removeIdsFromCreatedItemsLoadedState(
        loadedState.data,
        removedEntityIds
      ),
    } satisfies CreatedItemsLoadedState;
  }
  return {
    ...loadedState,
    componentIdsAndReferences,
    data: removeIdsFromSearchData(loadedState.data, removedEntityIds),
  };
};

const removeIdsFromTraversalData = (
  data: Traversal,
  removedEntityIds: Set<ArdoqId>
): Traversal => {
  if (isManualComponentSelection(data.componentSelection)) {
    return {
      ...data,
      componentSelection: {
        startSet: data.componentSelection.startSet.filter(
          id => !removedEntityIds.has(id)
        ),
        startContextSelectionType:
          data.componentSelection.startContextSelectionType,
      },
    };
  }
  return {
    ...data,
  };
};
const removeIdsFromTraversalCreatedFromViewData = (
  data: StartSetTraversalParams,
  removedEntityIds: Set<ArdoqId>
): StartSetTraversalParams | null => {
  const newStartSet = data.componentSelection.startSet.filter(
    id => !removedEntityIds.has(id)
  );
  if (newStartSet.length === 0) {
    return null;
  }

  return {
    ...data,
    componentSelection: {
      startSet: newStartSet,
      startContextSelectionType:
        data.componentSelection.startContextSelectionType,
    },
  };
};

const removeIdsFromSearchData = (
  data: SearchLoadedState['data'],
  removedEntityIds: Set<ArdoqId>
): SearchLoadedState['data'] => {
  if (isQueryComponentSelection(data.componentSelection)) {
    return data;
  }
  return {
    ...data,
    componentSelection: {
      ...data.componentSelection,
      startSet: data.componentSelection.startSet.filter(
        id => !removedEntityIds.has(id)
      ),
    },
  };
};

const removeIdsFromCreatedItemsLoadedState = (
  data: CreatedItemsLoadedState['data'],
  removedEntityIds: Set<ArdoqId>
): CreatedItemsLoadedState['data'] => ({
  ...data,
  componentIds: data.componentIds.filter(id => !removedEntityIds.has(id)),
  referenceIds: data.referenceIds.filter(id => !removedEntityIds.has(id)),
});

const getTraversalParamsFromViewpoint = (
  viewpoint: APIViewpointAttributes,
  componentIds: ArdoqId[]
): TraversalLoadedStateParams => {
  return {
    type: LoadedStateType.TRAVERSAL,
    isHidden: false,
    traversalId: viewpoint._id,
    data: {
      componentSelection: {
        startSet: componentIds,
        startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
      },
      paths: viewpoint.paths,
      filters: viewpoint.filters || {},
      pathMatching: viewpoint.pathMatching,
      pathCollapsingRules: viewpoint.pathCollapsingRules || [],
    },
  };
};

// prettier-ignore
function updateTraversalContextComponents(prev: TraversalLoadedStateParams, startSet: ArdoqId[]): TraversalLoadedStateParams;
// prettier-ignore
function updateTraversalContextComponents(prev: TraversalCreatedInViewLoadedStateParams, startSet: ArdoqId[]): TraversalCreatedInViewLoadedStateParams;
function updateTraversalContextComponents(
  previousLoadedState:
    | TraversalLoadedStateParams
    | TraversalCreatedInViewLoadedStateParams,
  startSet: ArdoqId[]
): TraversalLoadedStateParams | TraversalCreatedInViewLoadedStateParams {
  const data: TraversalParams = {
    componentSelection: {
      startSet: startSet,
      startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
    },
    paths: previousLoadedState.data.paths,
    filters: previousLoadedState.data.filters,
    pathMatching: previousLoadedState.data.pathMatching,
    pathCollapsingRules: previousLoadedState.data.pathCollapsingRules,
  };

  if (isTraversalLoadedState(previousLoadedState)) {
    return {
      data,
      isHidden: previousLoadedState.isHidden,
      type: previousLoadedState.type,
      traversalId: previousLoadedState.traversalId,
    };
  }

  return {
    data,
    isHidden: previousLoadedState.isHidden,
    type: previousLoadedState.type,
  };
}

const isComponentPartOfTraversalLoadedState = (
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState,
  componentId: string
) => {
  const componentIds =
    loadedState.componentIdsAndReferences?.componentIds ?? [];
  return componentIds.includes(componentId);
};

const isComponentPartOfStartSet = (
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState,
  componentId: string
) => {
  const startSetComponentIds =
    loadedState.componentIdsAndReferences?.startSetResult ?? [];
  return startSetComponentIds.includes(componentId);
};

const arePathMatching = (
  pathFromStartSetToSelectedComponents: DirectedTriple[],
  path: DirectedTriple[]
) => {
  return pathFromStartSetToSelectedComponents.every((triple, index) =>
    isSameTriple(triple, path[index])
  );
};

const pathContainsComponentTypeForSelectedComponents = (
  path: DirectedTriple[],
  pathsFromStartSetToSelectedComponents: DirectedTripleWithFilters[][]
) => {
  const result =
    pathsFromStartSetToSelectedComponents.find(
      pathFromStartSetToSelectedComponents => {
        return arePathMatching(pathFromStartSetToSelectedComponents, path);
      }
    ) ?? [];
  return uniqWith(result, isEqual);
};

const includeDisabledFlagAndHintIfApplicable = (
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState,
  triple: NamedDirectedTriple
) => {
  const isDisabled = loadedState.type === LoadedStateType.TRAVERSAL;
  return {
    ...triple,
    targetFilter: undefined,
    sourceFilter: undefined,
    isDisabled,
    togglingDisabledExplanation: isDisabled
      ? "This reference is part of another dataset and can't be turned on or off here"
      : undefined,
  };
};

const getPathsForStartComponent = (
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState
): DisableableDirectedTripleWithFilters[][] => {
  return loadedState.data.paths.map(path => {
    return [includeDisabledFlagAndHintIfApplicable(loadedState, path[0])];
  });
};

const getOnlyTriplesRepresentingSelectedComponent = (
  path: DirectedTripleWithFilters[],
  matchingPathsFromStartSet: DirectedTripleWithFilters[],
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState
) => {
  return path[matchingPathsFromStartSet.length]
    ? [
        includeDisabledFlagAndHintIfApplicable(
          loadedState,
          path[matchingPathsFromStartSet.length]
        ),
      ]
    : [];
};

const getPathsForNonStartComponent = (
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState,
  pathsFromStartSetToSelectedComponents: DirectedTripleWithFilters[][]
): DisableableDirectedTripleWithFilters[][] => {
  const pathsThatContainComponentTypeForSelectedComponent =
    loadedState.data.paths
      .map(path => {
        return {
          path,
          matchingPathsFromStartSet:
            pathContainsComponentTypeForSelectedComponents(
              path,
              pathsFromStartSetToSelectedComponents
            ),
        };
      })
      .filter(
        ({ matchingPathsFromStartSet }) => matchingPathsFromStartSet.length > 0
      )
      .map(({ path, matchingPathsFromStartSet }) => {
        return getOnlyTriplesRepresentingSelectedComponent(
          path,
          matchingPathsFromStartSet,
          loadedState
        );
      })
      .filter(path => path.length > 0);
  return uniqWith(pathsThatContainComponentTypeForSelectedComponent, isEqual);
};

const getPathsForSelectedComponentTypeFromTraversalsContainingComponentId = (
  loadedStates: LoadedState[],
  componentId: ArdoqId,
  pathsFromStartSetToSelectedComponents: DirectedTripleWithFilters[][]
): DisableableDirectedTripleWithFilters[][] => {
  return loadedStates
    .filter(loadedState => isAnyTraversalLoadedState(loadedState))
    .filter(loadedState => !loadedState.isHidden)
    .filter(loadedState =>
      isComponentPartOfTraversalLoadedState(loadedState, componentId)
    )
    .flatMap(loadedState => {
      if (isComponentPartOfStartSet(loadedState, componentId)) {
        return getPathsForStartComponent(loadedState);
      }

      return getPathsForNonStartComponent(
        loadedState,
        pathsFromStartSetToSelectedComponents
      );
    });
};

const componentIdIsIncludedInLoadedState = (
  loadedStates: LoadedState[],
  componentId: ArdoqId
) => {
  return loadedStates
    .filter(loadedState => !loadedState.isHidden)
    .some(
      loadedState =>
        loadedState.componentIdsAndReferences?.componentIds.includes(
          componentId
        ) ?? false
    );
};

const toggleCollapsedPathActive = (
  states: LoadedState[],
  { rule, loadedStateHash }: ToggleCollapsedPathActivePayload
): LoadedState[] => {
  return states.map(loadedState => {
    if (
      stateToHash(loadedState) === loadedStateHash &&
      isTraversalLoadedState(loadedState)
    ) {
      return {
        ...loadedState,
        data: {
          ...loadedState.data,
          pathCollapsingRules: loadedState.data.pathCollapsingRules.map(
            currentRule => {
              if (
                collapsedPathToHash(currentRule) === collapsedPathToHash(rule)
              ) {
                return {
                  ...currentRule,
                  isActive: !currentRule.isActive,
                };
              }
              return currentRule;
            }
          ),
        },
      };
    }
    return loadedState;
  });
};

const handleChangedParents = (
  states: LoadedState[],
  changedParents: APIComponentAttributes[]
): LoadedState[] => {
  const componentIdsWithChangedParents = new Set(
    changedParents.map(({ _id }) => _id)
  );
  const changedComponentsWithParents = changedParents.filter(
    componentOperations.isComponentWithParent
  );
  return states.map(loadedState => {
    if (!isAnyTraversalLoadedState(loadedState)) {
      return loadedState;
    }
    const {
      componentIdsAndReferences,
      data: { paths },
    } = loadedState;
    if (!componentIdsAndReferences) {
      return loadedState;
    }
    const { parentChildReferences, componentIds, startSetResult } =
      componentIdsAndReferences;
    if (!parentChildReferences) {
      return loadedState;
    }
    const componentIdsSet = new Set(componentIds);
    const updatedParentChildReferences = [
      ...removeChangedParentChildReferences(
        parentChildReferences,
        componentIdsWithChangedParents
      ),
      ...changedComponentsWithParents
        .filter(
          getIsParentChildReferenceIncludedInTraversal(componentIdsSet, paths)
        )
        .map(
          referenceAttributesOperations.componentToParentChildReferenceInBEFormat
        ),
    ];

    const additionalStartSetResults = getAdditionalStartSetResults(
      updatedParentChildReferences,
      paths
    );

    return {
      ...loadedState,
      componentIdsAndReferences: {
        ...componentIdsAndReferences,
        parentChildReferences: updatedParentChildReferences,
        startSetResult: uniq([...startSetResult, ...additionalStartSetResults]),
      },
    };
  });
};

// Changing the parent of a component can also affect the start set. Such a
// change can create new instant path which match the current traversal
// configuration. To make sure that they still display in the view we must make
// sure that the head of the path is included in the start set. Without that
// they could just get removed from the view (think e.g. a component child which
// is moved to the root with traversals starting with parent-child references).
const getAdditionalStartSetResults = (
  updatedParentChildReferences: APIParentChildReferenceAttributes[],
  paths: Path[]
) => {
  const startParentChildTriples = paths
    .filter(([firstTriple]) => firstTriple.referenceType === 'ardoq_parent')
    .map(([firstTriple]) => firstTriple);
  const [parentChildStart, childParentStart] = partition(
    startParentChildTriples,
    ({ direction }) => direction === 'outgoing'
  );
  const additionalStartSetResults: string[] = [];
  // Parent child references in BE format.
  updatedParentChildReferences.forEach(
    ({ source /* child */, target /* parent */ }) => {
      const [parentChildCandidate, childParentCandidate] =
        getParentChildTriples(source, target);
      if (
        parentChildStart.some(triple =>
          isSameTriple(triple, parentChildCandidate)
        )
      ) {
        additionalStartSetResults.push(target);
      }
      if (
        childParentStart.some(triple =>
          isSameTriple(triple, childParentCandidate)
        )
      ) {
        additionalStartSetResults.push(source);
      }
    }
  );
  return additionalStartSetResults;
};

const removeChangedParentChildReferences = (
  parentChildReferences: APIParentChildReferenceAttributes[],
  componentIdsWithChangedParents: Set<string>
) =>
  parentChildReferences.filter(
    ({ source }) => !componentIdsWithChangedParents.has(source)
  );

const getIsParentChildReferenceIncludedInTraversal = (
  componentIdsOfLoadedState: Set<string>,
  paths: Path[]
) => {
  const triples = paths.flat(2);
  return ({ _id, parent }: APIComponentAttributes & { parent: string }) => {
    const parentChildTriples = getParentChildTriples(_id, parent);
    const hasParentChildTriple = parentChildTriples.some(parentChildTriple =>
      triples.some(triple => isSameTriple(triple, parentChildTriple))
    );
    return (
      componentIdsOfLoadedState.has(_id) &&
      componentIdsOfLoadedState.has(parent) &&
      hasParentChildTriple
    );
  };
};

const getLoadedStateParamsFromLoadedState = <T extends LoadedStateParams>(
  loadedState: T
): LoadedStateParams => {
  return pick(loadedState, ['data', 'type', 'isHidden']) as LoadedStateParams; // "gently" nudge TS to understand that the type is correct
};

// prettier-ignore
function attachLoadedGraph<T extends LoadedState>(loadedState: T, loadedGraph: LoadedGraphChunk): T;
// prettier-ignore
function attachLoadedGraph<T extends LoadedStateParams>(loadedState: T, loadedGraph: LoadedGraphChunk): T & LoadedGraphChunk;
// prettier-ignore
function attachLoadedGraph(loadedState: LoadedStateParams, {scopeData, componentIdsAndReferences}: LoadedGraphChunk): LoadedState {
  return { ...loadedState, scopeData, componentIdsAndReferences };
}

const fromTraversalParams = (
  traversal: TraversalParams,
  traversalId: string | undefined
): TraversalLoadedStateParams => {
  return {
    data: traversal,
    isHidden: false,
    type: LoadedStateType.TRAVERSAL,
    traversalId,
  };
};

const removePath = <
  T extends
    | TraversalCreatedInViewLoadedStateParams
    | TraversalLoadedStateParams,
>(
  loadedState: T,
  path: Path
) => {
  const pathKey = pathToKey(path);
  return {
    ...loadedState,
    data: {
      ...loadedState.data,
      paths: loadedState.data.paths.filter(
        currentPath => pathToKey(currentPath) !== pathKey
      ),
    },
  };
};

const appendPathToTraversalParams = <
  T extends
    | TraversalCreatedInViewLoadedStateParams
    | TraversalLoadedStateParams,
>(
  loadedState: T,
  path: Path
) => {
  return {
    ...omit(loadedState, 'scopeData', 'componentIdsAndReferences'),
    data: {
      ...loadedState.data,
      paths: [...loadedState.data.paths, path],
    },
  };
};

const createdItemsProcessed = (
  states: LoadedState[],
  { componentIds, referenceIds }: CreatedItemsProcessedPayload
) => {
  const [[createdItemsLoadedState], loadedStates] = partition(
    states,
    loadedState => isCreatedItemsLoadedState(loadedState)
  );
  if (!createdItemsLoadedState) {
    return states;
  }

  const { data, componentIdsAndReferences } = createdItemsLoadedState;
  const processedReferenceIdsSet = new Set(referenceIds);
  const processedComponentIdsSet = new Set(componentIds);
  const updatedData = {
    componentIds: data.componentIds.filter(
      id => !processedComponentIdsSet.has(id)
    ),
    referenceIds: data.referenceIds.filter(
      id => !processedReferenceIdsSet.has(id)
    ),
  };

  if (
    updatedData.componentIds.length === 0 &&
    updatedData.referenceIds.length === 0
  ) {
    return loadedStates;
  }

  const updatedComponentIdsAndReferences = {
    componentIds: updatedData.componentIds,
    references: componentIdsAndReferences.references.filter(
      ({ _id }) => !processedReferenceIdsSet.has(_id)
    ),
    parentChildReferences:
      componentIdsAndReferences.parentChildReferences.filter(
        ({ _id }) => !processedReferenceIdsSet.has(_id)
      ),
    startSetResult: updatedData.componentIds,
  };

  const updatedCreatedItemsLoadedState = {
    ...createdItemsLoadedState,
    componentIdsAndReferences: updatedComponentIdsAndReferences,
    data: updatedData,
  };

  return [updatedCreatedItemsLoadedState, ...loadedStates];
};

const toTraversalLoadedState = (
  loadedState: LoadedState
): TraversalLoadedState | TraversalCreatedInViewLoadedState => {
  if (isAnyTraversalLoadedState(loadedState)) {
    return loadedState;
  }
  if (isSearchLoadedState(loadedState)) {
    return {
      ...loadedState,
      type: LoadedStateType.TRAVERSAL,
      traversalId: undefined,
      data: {
        componentSelection: loadedState.data.componentSelection,
        paths: [],
        filters: {},
        pathMatching: TraversalPathMatchingType.LOOSE,
        pathCollapsingRules: [],
      },
    } as TraversalLoadedState;
  }
  return {
    ...loadedState,
    type: LoadedStateType.TRAVERSAL,
    traversalId: undefined,
    data: {
      componentSelection: {
        startSet: loadedState.data.componentIds,
        startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
      },
      paths: [],
      filters: {},
      pathMatching: TraversalPathMatchingType.LOOSE,
      pathCollapsingRules: [],
    },
  } as TraversalLoadedState;
};

export const loadedStateOperations = {
  getTraversalParamsFromViewpoint,
  updateTraversalContextComponents,
  reduceRegisterLoadedState,
  reduceClearLoadedState,
  reduceSetTraversalId,
  reduceToggleLoadedStateVisibility,
  reduceDeleteLoadedState,
  replaceExistingLoadedState,
  reduceUpdateOrCreateLoadedStateOnItemCreated,
  attachLoadedGraph,
  fromTraversalParams,
  getTraversalWithLoadedStateHash,
  getLoadedStateWithHash,
  stateToHash,
  searchParamsFromSelection,
  toPathsWithStartSetResultsAndCollapsedPaths,
  handleRemovedComponentsAndReferences,
  getPathsForSelectedComponentTypeFromTraversalsContainingComponentId,
  componentIdIsIncludedInLoadedState,
  toggleCollapsedPathActive,
  handleChangedParents,
  getLoadedStateParamsFromLoadedState,
  removePath,
  appendPathToTraversalParams,
  createdItemsProcessed,
  toTraversalLoadedState,
};
