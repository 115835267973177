import { referenceInterface } from '@ardoq/reference-interface';
import { componentInterface } from '@ardoq/component-interface';
import { LinkedComponent, GlobalReferenceTypeId } from '@ardoq/data-model';
import { ArdoqId, ReferenceDirection } from '@ardoq/api-types';
import { ListValuesMap, RefTypeIds, TemporaryDict } from './types';
import { getComponentLabelParts, truncateComponentLabel } from '@ardoq/graph';
import { type Point, geometry } from '@ardoq/math';
import { getIconColor } from '@ardoq/dependency-map';
import { returnZero } from '@ardoq/common-helpers';
import {
  getReferenceLabelFormattingFieldLabel,
  getReferenceLabelFormattingFieldValue,
} from 'modelInterface/util';

type PrioritySorter = (a: any, b: any) => number;
type LinkedComponentsPerRefType = Map<GlobalReferenceTypeId, Set<ArdoqId>>;

const isUnset = (value: any) => value === null || value === undefined;

const CLICK_THRESHOLD = 15;

const TARGET_RATIO = 4 / 3;
// Ratio of a standard leaf box in pixels dimensions
const RATIO_STANDARD_BOX = 4 / 1;
// A estmate based on the empty cells
const AVERAGE_COMPACTNESS = 0.9;

export const getIdealGridWidth = (leavesCount: number) => {
  const adjustedLeavesCount = leavesCount / AVERAGE_COMPACTNESS;
  return Math.round(
    (adjustedLeavesCount * (TARGET_RATIO / RATIO_STANDARD_BOX)) ** 0.5
  );
};

export const isClick = (event: React.MouseEvent, mouseDownPosition: Point) =>
  geometry.distanceTwoArgs([event.clientX, event.clientY], mouseDownPosition) <
  CLICK_THRESHOLD;

export const ROOT_NODE_ID = '@@root-node@@';

export const getRootNode = (
  priorityKey: string,
  groupingKey: string,
  prioritySorter: PrioritySorter,
  listValuesMap: ListValuesMap,
  supportingValue: string,
  includeIncomingReferenceTypes: GlobalReferenceTypeId[],
  includeOutgoingReferenceTypes: GlobalReferenceTypeId[],
  childrenIds: ArdoqId[]
): TemporaryDict => {
  const fallbackPriorityValue = getFallbackPriorityValue(
    listValuesMap,
    supportingValue
  );
  const children = childrenIds
    .filter(componentInterface.isIncludedInContextByFilter)
    .map(
      toViewModel(
        priorityKey,
        groupingKey,
        prioritySorter,
        listValuesMap,
        supportingValue,
        fallbackPriorityValue,
        includeIncomingReferenceTypes,
        includeOutgoingReferenceTypes,
        ReferenceDirection.NONE
      )
    )
    .sort(prioritySorter);

  const { supportingColumns, priorityRows, anyRow } = getGroups(
    children,
    supportingValue,
    fallbackPriorityValue
  );

  return {
    id: ROOT_NODE_ID,
    name: '',
    classNames: '',
    representationData: { isImage: false, value: null },
    priorityValue: undefined,
    isGrouping: false,
    priority: undefined,
    navigatorOrder: 0,
    layoutType: '',
    leavesCount: 0,
    minGridWidth: 1,
    children,
    collapsedDescendants: [],
    isCollapsed: false,
    supportingColumns,
    priorityRows,
    anyRow,
    referencedDirection: ReferenceDirection.NONE,
    referenceLabel: '',
    referenceFieldValue: '',
    referenceFieldLabel: '',
    isCollapsedReference: false,
  };
};

const toViewModel =
  (
    priorityKey: string,
    groupingKey: string,
    prioritySorter: PrioritySorter,
    listValuesMap: ListValuesMap,
    supportingValue: string,
    fallbackPriorityValue: FallbackPriorityValue,
    includeIncomingReferenceTypes: GlobalReferenceTypeId[],
    includeOutgoingReferenceTypes: GlobalReferenceTypeId[],
    referencedDirection: ReferenceDirection = ReferenceDirection.NONE,
    indexOffset = 0,
    referencedLabels: (string | null)[] = [],
    referencedFieldValues: (string | null)[] = [],
    isCollapsedReference = false
  ) =>
  (componentId: ArdoqId, index = 0): TemporaryDict => {
    const isReferenced = referencedDirection !== ReferenceDirection.NONE;
    const priorityValue = isReferenced
      ? undefined
      : componentInterface.getAttribute(componentId, priorityKey);
    const children = isReferenced
      ? []
      : componentInterface
          .getChildren(componentId)
          .filter(componentInterface.isIncludedInContextByFilter)
          .map(
            toViewModel(
              priorityKey,
              groupingKey,
              prioritySorter,
              listValuesMap,
              supportingValue,
              fallbackPriorityValue,
              includeIncomingReferenceTypes,
              includeOutgoingReferenceTypes,
              referencedDirection
            )
          )
          .sort(prioritySorter);

    const { supportingColumns, priorityRows, anyRow } = getGroups(
      children,
      supportingValue,
      fallbackPriorityValue
    );
    const id = componentInterface.getAttribute(componentId, 'id');
    const name = truncateComponentLabel({
      ...getComponentLabelParts(componentId),
      measure: returnZero,
      width: Infinity,
    });
    const classNames =
      componentInterface.getCssClassNames(componentId, {
        useAsBackgroundStyle: true,
      }) ?? '';
    const iconColor = getIconColor(componentId, componentId, false);
    return {
      id,
      name,
      classNames,
      iconColor,
      representationData: componentInterface.getRepresentationData(
        componentId
      ) ?? { isImage: false, value: null },
      priorityValue,
      isGrouping: componentInterface.getTypeId(componentId) === groupingKey,
      priority: listValuesMap[priorityValue],
      navigatorOrder: indexOffset + index,
      layoutType: '',
      leavesCount: 0,
      minGridWidth: 1,
      children,
      collapsedDescendants: [],
      isCollapsed: false,
      supportingColumns,
      priorityRows,
      anyRow,
      referencedDirection,
      referenceLabel: referencedLabels[index] || '',
      referenceFieldValue: referencedFieldValues[index] || '',
      referenceFieldLabel: getReferenceLabelFormattingFieldLabel() || '',
      isCollapsedReference,
    };
  };

type AddBothDirectionsReferencedComponentsArgs = {
  node: TemporaryDict;
  targetAndSourceNodeId: string;
  includeIncomingReferenceTypes: GlobalReferenceTypeId[];
  includeOutgoingReferenceTypes: GlobalReferenceTypeId[];
  incomingLinkedComponentsPerRefType: LinkedComponentsPerRefType;
  outgoingLinkedComponentsPerRefType: LinkedComponentsPerRefType;
  priorityKey: string;
  groupingKey: string;
  prioritySorter: PrioritySorter;
  listValuesMap: ListValuesMap;
  supportingValue: string;
  fallbackPriorityValue: FallbackPriorityValue;
  collapsed: boolean;
};
const addBothDirectionsOfReferencedComponents = ({
  node,
  targetAndSourceNodeId,
  includeIncomingReferenceTypes,
  includeOutgoingReferenceTypes,
  incomingLinkedComponentsPerRefType,
  outgoingLinkedComponentsPerRefType,
  priorityKey,
  groupingKey,
  prioritySorter,
  listValuesMap,
  supportingValue,
  fallbackPriorityValue,
  collapsed,
}: AddBothDirectionsReferencedComponentsArgs) => {
  addReferencedComponents(
    node,
    componentInterface.getTargets(targetAndSourceNodeId),
    includeOutgoingReferenceTypes,
    ReferenceDirection.OUTGOING,
    outgoingLinkedComponentsPerRefType,
    priorityKey,
    groupingKey,
    prioritySorter,
    listValuesMap,
    supportingValue,
    fallbackPriorityValue,
    collapsed
  );
  addReferencedComponents(
    node,
    componentInterface.getSources(targetAndSourceNodeId),
    includeIncomingReferenceTypes,
    ReferenceDirection.INCOMING,
    incomingLinkedComponentsPerRefType,
    priorityKey,
    groupingKey,
    prioritySorter,
    listValuesMap,
    supportingValue,
    fallbackPriorityValue,
    collapsed
  );
};

export const addAllReferencedComponents = (
  node: TemporaryDict,
  includeIncomingReferenceTypes: GlobalReferenceTypeId[],
  includeOutgoingReferenceTypes: GlobalReferenceTypeId[],
  priorityKey: string,
  groupingKey: string,
  prioritySorter: PrioritySorter,
  listValuesMap: ListValuesMap,
  supportingValue: string,
  fallbackPriorityValue: FallbackPriorityValue = getFallbackPriorityValue(
    listValuesMap,
    supportingValue
  )
) => {
  const { isCollapsed, children, collapsedDescendants, id: nodeId } = node;
  if (!isCollapsed) {
    children.forEach(child =>
      addAllReferencedComponents(
        child,
        includeIncomingReferenceTypes,
        includeOutgoingReferenceTypes,
        priorityKey,
        groupingKey,
        prioritySorter,
        listValuesMap,
        supportingValue,
        fallbackPriorityValue
      )
    );
  }

  const collapsedDescendantNodeIds = collapsedDescendants.map(({ id }) => id);

  const incomingLinkedComponentsPerRefType = new Map();
  const outgoingLinkedComponentsPerRefType = new Map();

  collapsedDescendantNodeIds.forEach(collapsedDescendantNodeId =>
    addBothDirectionsOfReferencedComponents({
      node,
      targetAndSourceNodeId: collapsedDescendantNodeId,
      includeIncomingReferenceTypes,
      includeOutgoingReferenceTypes,
      incomingLinkedComponentsPerRefType,
      outgoingLinkedComponentsPerRefType,
      priorityKey,
      groupingKey,
      prioritySorter,
      listValuesMap,
      supportingValue,
      fallbackPriorityValue,
      collapsed: true,
    })
  );

  addBothDirectionsOfReferencedComponents({
    node,
    targetAndSourceNodeId: nodeId,
    includeIncomingReferenceTypes,
    includeOutgoingReferenceTypes,
    incomingLinkedComponentsPerRefType,
    outgoingLinkedComponentsPerRefType,
    priorityKey,
    groupingKey,
    prioritySorter,
    listValuesMap,
    supportingValue,
    fallbackPriorityValue,
    collapsed: false,
  });
};

const isAlreadyLinkedComponent = (
  linkedComponentsPerRefType: LinkedComponentsPerRefType,
  componentId: ArdoqId,
  refTypeId: GlobalReferenceTypeId | null
): boolean => {
  if (refTypeId) {
    let linkedCompIds = linkedComponentsPerRefType.get(refTypeId);
    if (!linkedCompIds) {
      linkedCompIds = new Set();
      linkedComponentsPerRefType.set(refTypeId, linkedCompIds);
    }
    if (linkedCompIds.has(componentId)) {
      return true;
    }
    linkedCompIds.add(componentId);
  }
  return false;
};

const filterLinkedComponents =
  (
    linkedComponentsPerRefType: LinkedComponentsPerRefType,
    includeReferenceTypes: GlobalReferenceTypeId[]
  ) =>
  ({ referenceId, componentId }: LinkedComponent) => {
    if (
      !(
        componentInterface.isIncludedInContextByFilter(componentId) &&
        referenceInterface.isIncludedInContextByFilter(referenceId)
      )
    ) {
      return false;
    }

    const globalRefTypeId = referenceInterface.getGlobalTypeId(
      referenceId as ArdoqId
    );
    if (
      isAlreadyLinkedComponent(
        linkedComponentsPerRefType,
        componentId,
        globalRefTypeId
      )
    ) {
      return false;
    }

    const showAll = includeReferenceTypes.includes(RefTypeIds.ALL);
    return (
      showAll ||
      !globalRefTypeId ||
      includeReferenceTypes.includes(globalRefTypeId)
    );
  };

const addReferencedComponents = (
  node: TemporaryDict,
  allLinkedComponents: LinkedComponent[],
  includeReferenceTypes: GlobalReferenceTypeId[],
  referencedDirection: ReferenceDirection,
  linkedComponentsPerRefType: LinkedComponentsPerRefType,
  priorityKey: string,
  groupingKey: string,
  prioritySorter: PrioritySorter,
  listValuesMap: ListValuesMap,
  supportingValue: string,
  fallbackPriorityValue: FallbackPriorityValue,
  collapsed: boolean
) => {
  const linkedComponents = allLinkedComponents.filter(
    filterLinkedComponents(linkedComponentsPerRefType, includeReferenceTypes)
  );

  const referencedComponents = linkedComponents.map(
    ({ componentId }) => componentId
  );
  const incomingReferenceLabels = linkedComponents.map(({ referenceId }) =>
    referenceInterface.getDisplayLabel(referenceId)
  );
  const incomingReferenceFieldValues = linkedComponents.map(({ referenceId }) =>
    getReferenceLabelFormattingFieldValue(referenceId)
  );

  const indexOffset = node.children.length;

  const referencedViewModels = referencedComponents.map(
    toViewModel(
      priorityKey,
      groupingKey,
      prioritySorter,
      listValuesMap,
      supportingValue,
      fallbackPriorityValue,
      [],
      [],
      referencedDirection,
      indexOffset,
      incomingReferenceLabels,
      incomingReferenceFieldValues,
      collapsed
    )
  );

  node.children.push(...referencedViewModels);
  node.anyRow.push(...referencedViewModels);
};

const getGroups = (
  children: TemporaryDict[],
  supportingValue: string,
  fallbackPriorityValue: FallbackPriorityValue
) => {
  const supportingColumns = children.filter(
    child => child.priorityValue === supportingValue
  );
  let anyRow = children.filter(child => isUnset(child.priorityValue));
  const priorityRows = children
    .filter(
      child =>
        !(
          child.priorityValue === supportingValue ||
          isUnset(child.priorityValue)
        )
    )
    .reduce<TemporaryDict[][]>((acc, child) => {
      const lastRow = acc[acc.length - 1];
      if (!lastRow || lastRow[0].priorityValue !== child.priorityValue) {
        return [...acc, [child]];
      }
      lastRow.push(child);
      return acc;
    }, []);

  if (priorityRows.length === 0 && anyRow.length > 0) {
    const anyRowWithPriorityRows = anyRow.find(
      child => child.priorityRows.length > 0
    );
    if (anyRowWithPriorityRows) {
      priorityRows.push([
        Object.assign(anyRowWithPriorityRows, fallbackPriorityValue),
      ]);
      anyRow = anyRow.filter(child => child !== anyRowWithPriorityRows);
    }
  }

  return { supportingColumns, priorityRows, anyRow };
};

type FallbackPriorityValue = { priorityValue: string; priority: number };

const getFallbackPriorityValue = (
  listValuesMap: ListValuesMap,
  supportingValue: string
): FallbackPriorityValue => {
  const [priorityValue, priority] = Object.entries(listValuesMap).find(
    ([key]) => key !== supportingValue
  ) || ['unknown', 0];
  return { priorityValue, priority };
};
