import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { connect } from '@ardoq/rxbeach';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import ViewpointModeTimelineView from './ViewpointModeTimelineView';
import TimelineView from 'tabview/timeline/TimelineView';

const TimelineViewController = ({
  isViewpointMode,
}: LoadedGraphWithViewpointMode) =>
  isViewpointMode ? <ViewpointModeTimelineView /> : <TimelineView />;

export default connect(TimelineViewController, isViewpointMode$);
