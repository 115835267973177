import { componentInterface } from '@ardoq/component-interface';
import { getTargetData } from './getTargetData';
import { MATRIX_CELLS_LIMIT } from '../consts';
import { getSourceData } from './getSourceData';
import { getReferences } from './getReferences';
import { getLegendReferenceTypes } from '../utils';
import { ViewError } from '@ardoq/graph';
import { ItemsType, nodeLimitError } from '@ardoq/error-info-box';
import { format } from 'utils/numberUtils';
import { dispatchAction } from '@ardoq/rxbeach';
import { bypassLimitAction } from './actions';
import { ArdoqId } from '@ardoq/api-types';
import {
  DependencyMatrixViewSettings,
  DependencyMatrixViewModel,
} from '../types';
import { PermissionContext } from '@ardoq/access-control';

const getSourceLimit = (targetCount: number, bypassLimit: boolean) =>
  bypassLimit ? Infinity : Math.floor(MATRIX_CELLS_LIMIT / targetCount) || 1;

type BuildViewModelArgs = {
  componentId: ArdoqId;
  workspaceId: ArdoqId;
  workspacesIds: ArdoqId[];
  focusedComponentId: ArdoqId | null;
  viewSettings: DependencyMatrixViewSettings;
  bypassLimit: boolean;
  permissionContext: PermissionContext;
};

export const buildViewModel = ({
  workspaceId,
  workspacesIds,
  componentId,
  focusedComponentId,
  viewSettings,
  bypassLimit,
  permissionContext,
}: BuildViewModelArgs): DependencyMatrixViewModel => {
  const { referenceToWsId, showOnlyConnectedComponents } = viewSettings;
  const currentWorkspaceId =
    workspaceId || componentInterface.getWorkspaceId(componentId);

  const selectedWorkspaceId =
    referenceToWsId && workspacesIds.includes(referenceToWsId)
      ? referenceToWsId
      : currentWorkspaceId;

  // target- and sourceData get all workspace-relevant data regardless of showOnlyConnectedComponents state.
  const targetData = getTargetData(selectedWorkspaceId);
  // need all source data to get all "connected components"
  const sourceLimit = showOnlyConnectedComponents
    ? Infinity
    : getSourceLimit(targetData.allComponentsCount, bypassLimit);
  const sourceData = getSourceData(
    componentId,
    currentWorkspaceId,
    sourceLimit
  );

  // As the matrix is 2-dimensional, we need only refs which are relevant for the current target workspace
  const allTargetWorkspaceComponents = new Set(targetData.componentIds);
  const referencesMap = getReferences(sourceData, allTargetWorkspaceComponents);

  const targetDataToDisplay = { ...targetData };
  const sourceDataToDisplay = { ...sourceData };

  if (showOnlyConnectedComponents) {
    const connectedTargetComponents = new Set<string>();
    const connectedSourceComponents = new Set<string>();

    const allConnectedTargetComponents = new Set<string>();

    for (const sourceComponentId in referencesMap) {
      referencesMap[sourceComponentId].forEach(
        ({ componentId: referencedComponentId }) => {
          if (connectedTargetComponents.has(referencedComponentId)) {
            const shouldAddSource =
              bypassLimit ||
              (!connectedSourceComponents.has(sourceComponentId) &&
                (connectedSourceComponents.size + 1) *
                  connectedTargetComponents.size <=
                  MATRIX_CELLS_LIMIT);

            // add next source, if the resulting matrix is within limit
            if (shouldAddSource) {
              connectedSourceComponents.add(sourceComponentId);
            }
          } else {
            const nextSourceCount =
              connectedSourceComponents.size +
              (connectedSourceComponents.has(sourceComponentId) ? 0 : 1);
            const nextTargetCount = connectedTargetComponents.size + 1;

            // add next source and target if the resulting matrix is within limit
            if (
              bypassLimit ||
              nextSourceCount * nextTargetCount <= MATRIX_CELLS_LIMIT
            ) {
              connectedSourceComponents.add(sourceComponentId);
              connectedTargetComponents.add(referencedComponentId);
            }
          }

          // count distinct all connected target components
          allConnectedTargetComponents.add(referencedComponentId);
        }
      );
    }

    // Display only connected components
    targetDataToDisplay.componentIds = Array.from(
      connectedTargetComponents
    ).sort(componentInterface.compare);
    targetDataToDisplay.allComponentsCount = allConnectedTargetComponents.size;

    sourceDataToDisplay.componentIds = Array.from(connectedSourceComponents);
    sourceDataToDisplay.allComponentsCount = Object.keys(referencesMap).length;
  }

  const legendReferenceTypes = getLegendReferenceTypes(
    workspaceId,
    referencesMap
  );

  // if one of the matrix dimensions is initially absent, empty display is not a result of this viewModifier.
  // if the view modifier removes one of the dimensions - we should communicate "No Connected Components"
  const noConnectedComponents = Boolean(
    showOnlyConnectedComponents &&
      targetData.componentIds.length &&
      sourceData.componentIds.length &&
      (!targetDataToDisplay.componentIds.length ||
        !sourceDataToDisplay.componentIds.length)
  );

  const fullMatrixCellsCount =
    targetDataToDisplay.allComponentsCount *
    sourceDataToDisplay.allComponentsCount;
  const displayedItems =
    targetDataToDisplay.componentIds.length +
    sourceDataToDisplay.componentIds.length;
  const shouldLimitItems =
    fullMatrixCellsCount > MATRIX_CELLS_LIMIT && !bypassLimit;
  const fullMatrixComponentsCount =
    targetDataToDisplay.allComponentsCount +
    sourceDataToDisplay.allComponentsCount;

  const errors: ViewError[] = shouldLimitItems
    ? [
        nodeLimitError({
          itemCount: fullMatrixComponentsCount,
          limit: displayedItems,
          itemsType: ItemsType.COMPONENTS,
          formatNumber: format,
          onProceedAnyway: () => dispatchAction(bypassLimitAction()),
        }),
      ]
    : [];

  return {
    workspaceId,
    workspacesIds,
    componentId,
    focusedComponentId,
    viewSettings: {
      ...viewSettings,
      referenceToWsId: selectedWorkspaceId,
    },
    targetData: targetDataToDisplay,
    sourceData: sourceDataToDisplay,
    references: referencesMap,
    legendReferenceTypes,
    noConnectedComponents,
    errors,
    bypassLimit,
    permissionContext,
  };
};
