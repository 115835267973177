import {
  dispatchAction,
  reducedStream,
  reducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { requestOptionsDataFetch } from 'streams/perspectiveEditorData/actions';
import { allWorkspacesClosed } from 'streams/views/mainContent/actions';
import { MAIN_PANE_RIGHT } from 'streams/views/mainContent/types';
import { closePane } from 'appLayout/actions';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { loadedGraphOperations } from './loadedGraphOperations';
import { distinctUntilKeyChanged, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
import { hideRightPane } from 'appContainer/actions';
import {
  clearLoadedGraphState,
  enterViewpointMode,
  replaceScopeComponentIds,
} from 'streams/traversals/actions';

const defaultState: LoadedGraphWithViewpointMode =
  loadedGraphOperations.getEmpty();

export const loadedGraph$ = toPersistentStream(
  'loadedGraph$',
  reducedStream<LoadedGraphWithViewpointMode>('loadedGraph$', defaultState, [
    reducer(
      replaceScopeComponentIds,
      loadedGraphOperations.replaceScopeComponentIds
    ),
    reducer(allWorkspacesClosed, loadedGraphOperations.clearLoadedGraphState),
    reducer(clearLoadedGraphState, loadedGraphOperations.clearLoadedGraphState),
    reducer(enterViewpointMode, loadedGraphOperations.enterViewpointMode),
  ]).pipe(
    distinctUntilChanged(),
    tap(loadedGraph => (window.ARDOQ.loadedGraph = loadedGraph))
  ),
  defaultState
);

export const isViewpointMode$: Observable<{ isViewpointMode: boolean }> =
  loadedGraph$.pipe(
    distinctUntilKeyChanged('isViewpointMode'),
    map(({ isViewpointMode }) => ({ isViewpointMode })),
    shareReplay({ bufferSize: 1, refCount: true })
  );

const fetchDataRoutine = isViewpointMode$.pipe(
  tap(({ isViewpointMode }) => {
    if (isViewpointMode) {
      dispatchAction(requestOptionsDataFetch());
      dispatchAction(closePane({ location: MAIN_PANE_RIGHT })); // hide the right view pane. there is no right view pane in viewpoint mode.
      dispatchAction(hideRightPane()); // hide the cyber editor. viewpoint mode should always open with the cyber editor closed.
    }
  })
);
fetchDataRoutine.subscribe();
