import * as React from 'react';
import { Root } from 'react-dom/client';
import {
  Class,
  GeneralPath,
  IGroupBoundsCalculator,
  INode,
  INodeInsetsProvider,
  INodeSizeConstraintProvider,
  IRenderContext,
  NodeStyleBase,
  Rect,
  SvgVisual,
  Visual,
} from '@ardoq/yfiles';
import {
  AGGREGATED_COUNT_BADGE_RADIUS,
  CollapsibleGraphGroup,
  disposeCallback,
  ensureRoot,
  estimateCountLabelWidth,
  initializeRoot,
  truncateText,
} from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import CollapsibleGroup from '../CollapsibleGroup';
import { componentInterface } from '@ardoq/component-interface';
import GroupInsetsProvider from './GroupInsetsProvider';
import GroupBoundsCalculator, {
  getGroupLabelDimensions,
} from './GroupBoundsCalculator';
import SparkMD5 from 'spark-md5';
import { createSvgElement } from '@ardoq/dom-utils';
import Context from 'context';
import { getChangedPopover, getCssClassFromDiffType } from 'scope/modelUtil';
import { DiffType } from '@ardoq/data-model';
import {
  isContextNode,
  isPartOfMultiSelection,
} from 'yfilesExtensions/styles/nodeDecorator';
import ComponentGroup from '../ComponentGroup';
import {
  CONTEXT_HIGHLIGHT_PADDING,
  DATA_RENDER_HASH,
} from 'yfilesExtensions/styles/consts';
import { GroupType, ViewIds } from '@ardoq/api-types';
import { classes } from '@ardoq/common-helpers';
import { CSS_CLASS_IGNORE_LINK_TARGET } from 'components/WorkspaceHierarchies/utils/consts';
import { colors } from '@ardoq/design-tokens';
import getNodeOutline from 'yfilesExtensions/getNodeOutline';
import getNodeBounds from 'yfilesExtensions/getNodeBounds';
import { GLOBAL_HANDLER_ID_ATTRIBUTE, MODEL_ID_ATTRIBUTE } from 'consts';
import { selectComponent } from 'streams/components/ComponentActions';
import { dispatchAction } from '@ardoq/rxbeach';
import { EXPANDER_RADIUS, GROUP_PADDING } from '../consts';
import {
  folderStyleGroupLabelStartX,
  getGroupHeaderWidthWithoutLabel,
  measureGroupSubLabel,
} from '../utils';
import { POPOVER_ID_ATTR } from '@ardoq/popovers';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const truncateGroupSubLabel = truncateText(measureGroupSubLabel);

const createRenderDataCache = (node: INode, group: CollapsibleGraphGroup) =>
  SparkMD5.hash(
    `${group.getShape()},${node.layout.width},${node.layout.height},${
      group.collapsed ? group.descendantCount : -1
    },${group.getLabel()},${group.subLabel},${isContextNode(
      node
    )},${group.getCSS({
      useAsBackgroundStyle: true,
    })},${group.getVisualDiffType()},${group.isTransparentized}`
  );

const createElementAsGroupFolder = (
  node: INode,
  group: CollapsibleGraphGroup,
  viewId: ViewIds
) => {
  const isRootGroup = !group.parent;
  const hasBorder = !isRootGroup;
  const isComponent = group.isComponent();
  const isWorkspace = group.type === GroupType.WORKSPACE;
  const borderColor = hasBorder && !isComponent ? colors.grey60 : undefined;
  const visualDiffType = group.getVisualDiffType();
  const visualDiffClass = getCssClassFromDiffType(visualDiffType);

  const isMultiLabelActive = loadedGraph$.state.isViewpointMode;

  const {
    groupLabelHeight,
    otherLabelsHeight,
    legacyFormattingHeight,
    subLabelHeight,
  } = getGroupLabelDimensions(node, false);

  const subLabelIndent = isMultiLabelActive
    ? GROUP_PADDING
    : getGroupHeaderWidthWithoutLabel(group);

  const groupSubLabelTruncated = truncateGroupSubLabel(
    group.subLabel || '',
    node.layout.width - subLabelIndent
  );

  const subLabelOffsetX = isMultiLabelActive
    ? GROUP_PADDING
    : folderStyleGroupLabelStartX(group);

  const subLabelOffestY =
    groupLabelHeight +
    otherLabelsHeight +
    legacyFormattingHeight +
    subLabelHeight;

  return React.createElement(CollapsibleGroup, {
    viewId,
    group,
    borderColor,
    className: isComponent
      ? classes(
          group.getCSS({
            useAsBackgroundStyle: !visualDiffClass, // due to poor design in ardoqModelCSSManager and the liberal sprinkling of css !important, we cannot use the background style when visualDiffClass is present.
          }),
          CSS_CLASS_IGNORE_LINK_TARGET
        )
      : isWorkspace
        ? 'workspace'
        : undefined,
    height: node.layout.height,
    width: node.layout.width,
    subLabelOffestY,
    offsetX: subLabelOffsetX,
    subTitle: groupSubLabelTruncated,
    descendantCount: group.descendantCount,
    visualDiffClass,
  });
};

const createElementAsCollapsedComponent = (
  node: INode,
  group: CollapsibleGraphGroup,
  viewId: ViewIds
) => {
  const visualDiffType = group.getVisualDiffType();
  const visualDiffClass = getCssClassFromDiffType(visualDiffType);

  const isPlaceholder = visualDiffType === DiffType.PLACEHOLDER;
  const { modelId } = group;
  const isContext = modelId === Context.componentId();
  const isSelectedRelated = isPartOfMultiSelection(modelId);
  const templateHref = group.getImage() ? undefined : `#${group.getShape()}`;
  const imageHref = group.getImage();
  const { width, height } = node.layout;

  return React.createElement(ComponentGroup, {
    viewId,
    group,
    templateHref,
    imageHref,
    isPlaceholder,
    isContext,
    isSelectedRelated,
    width,
    height,
    visualDiffClass,
    contextHighlightPadding: CONTEXT_HIGHLIGHT_PADDING,
  });
};

const render = (
  root: Root,
  container: SVGElement,
  node: INode,
  cache: string,
  viewId: ViewIds
) => {
  const { tag: group } = node;
  if (!(group instanceof CollapsibleGraphGroup)) {
    logError(Error('Non-collapsible node in CollapsibleGroupStyle render.'));
    return;
  }
  container.setAttribute(DATA_RENDER_HASH, cache);

  const isComponent = group.isComponent();

  if (group.modelId) {
    container.setAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE, group.modelId);
  } else {
    container.removeAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE);
  }
  container.setAttribute('data-node-id', group.id);
  container.setAttribute(
    'class',
    classes('skipContextUpdate', isComponent && 'component', 'group')
  );

  const tooltipAttributes = Object.entries(
    group.modelId
      ? getChangedPopover(group.modelId, group.getVisualDiffType())
      : {}
  );

  if (tooltipAttributes.length > 0) {
    tooltipAttributes.forEach(([key, value]) =>
      container.setAttribute(key, value)
    );
  } else {
    [POPOVER_ID_ATTR, MODEL_ID_ATTRIBUTE].forEach(key =>
      container.removeAttribute(key)
    );
  }

  const createElement =
    isComponent && group.collapsed
      ? createElementAsCollapsedComponent
      : createElementAsGroupFolder;

  root.render(createElement(node, group, viewId));
};
const stylesByViewId = new Map<ViewIds, CollapsibleGroupStyle>();
export default class CollapsibleGroupStyle extends NodeStyleBase {
  static get(viewId: ViewIds) {
    if (!stylesByViewId.has(viewId)) {
      stylesByViewId.set(viewId, new CollapsibleGroupStyle(viewId));
    }
    return stylesByViewId.get(viewId)!;
  }

  private constructor(private readonly viewId: ViewIds) {
    super();
  }

  createVisual(context: IRenderContext, node: INode) {
    const g = createSvgElement('g');
    g.addEventListener('dblclick', () => {
      const modelId = g.getAttribute(GLOBAL_HANDLER_ID_ATTRIBUTE);
      if (
        !modelId ||
        !componentInterface.isComponent(modelId) ||
        componentInterface.isScenarioRelated(modelId)
      ) {
        return;
      }
      dispatchAction(selectComponent({ cid: modelId }));
    });
    if (node.tag instanceof CollapsibleGraphGroup) {
      const cache = createRenderDataCache(node, node.tag);
      render(initializeRoot(g), g, node, cache, this.viewId);
    } else {
      logError(Error('Unrecognized node tag.'));
    }
    SvgVisual.setTranslate(g, node.layout.x, node.layout.y);
    const result = new SvgVisual(g);
    context.setDisposeCallback(result, disposeCallback);
    return result;
  }

  updateVisual(context: IRenderContext, oldVisual: Visual, node: INode) {
    if (!(oldVisual instanceof SvgVisual)) {
      logError(Error('Invalid visual in CollapsibleGroupStyle updateVisual.'));
      return oldVisual;
    }
    const { tag: group, layout } = node;
    if (!(group instanceof CollapsibleGraphGroup)) {
      logError(
        Error('Non collapsible node in CollapsibleGroupStyle updateVisual.')
      );
      return oldVisual;
    }
    const container = oldVisual.svgElement;
    const oldCache = container.getAttribute(DATA_RENDER_HASH);
    const newCache = createRenderDataCache(node, group);
    if (oldCache !== newCache) {
      render(ensureRoot(container), container, node, newCache, this.viewId);
    }
    SvgVisual.setTranslate(container, layout.x, layout.y);
    return oldVisual;
  }

  lookup(node: INode, type: Class) {
    if (type === INodeInsetsProvider.$class) {
      return GroupInsetsProvider.Instance;
    } else if (type === IGroupBoundsCalculator.$class) {
      return GroupBoundsCalculator.Instance;
    } else if (type === INodeSizeConstraintProvider.$class) {
      return GroupBoundsCalculator.Instance;
    }
    return super.lookup(node, type);
  }

  protected override getOutline(node: INode): GeneralPath | null {
    const { tag: group } = node;

    const isCollapsedComponentGroup =
      group instanceof CollapsibleGraphGroup &&
      group.collapsed &&
      group.isComponent();

    if (!isCollapsedComponentGroup) {
      return super.getOutline(node);
    }

    const { x, y, width, height } = getNodeBounds(
      node,
      isContextNode(node),
      true
    );
    const nodeCenterY = y + height / 2;

    // #region expander rect
    const expanderX = x - EXPANDER_RADIUS;
    const expanderY = nodeCenterY - EXPANDER_RADIUS;
    const expanderDiameter = 2 * EXPANDER_RADIUS;

    const expanderRect = new Rect(
      expanderX,
      expanderY,
      expanderDiameter,
      expanderDiameter
    );
    // #endregion

    // #region descendant count badge rect
    const descendantCountBadgeHeight = 2 * AGGREGATED_COUNT_BADGE_RADIUS;
    const descendantCountBadgeWidth = Math.max(
      estimateCountLabelWidth(group.descendantCount),
      descendantCountBadgeHeight
    );
    const descendantCountBadgeX = x + width - descendantCountBadgeWidth / 2;
    const descendantCountBadgeY = y - descendantCountBadgeHeight / 2;
    const descendantCountBadgeRect = new Rect(
      descendantCountBadgeX,
      descendantCountBadgeY,
      descendantCountBadgeWidth,
      descendantCountBadgeHeight
    );
    // #endregion

    const outline = getNodeOutline(node);
    outline.appendEllipse(expanderRect, false);
    outline.appendRectangle(descendantCountBadgeRect, false);

    return outline;
  }
}
