import { useRef, useState } from 'react';
import { ViewIds } from '@ardoq/api-types';
import { dispatchAction, connectInstance } from '@ardoq/rxbeach';
import { viewModel$ } from './viewModel/viewModel$';
import {
  Timeline,
  TimelineProperties,
  ViewLoadingStatus,
  getLeftSettingsMenuConfig,
  getLegendRows,
  getRightSettingsMenuConfig,
  getToggleCollapseMenuItems,
} from '@ardoq/timeline';
import { TimelineViewProperties } from './types';
import { ViewLegendContainer } from '@ardoq/graph';
import {
  ViewLegend,
  getActiveConditionalFormattingForLegend,
} from '@ardoq/view-legend';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { getActiveDiffMode } from 'scope/activeDiffMode$';
import HeightOffset from 'views/viewLegend/useViewLegendSubscription';
import { componentInterface } from '@ardoq/component-interface';
import EmptyState from './EmptyState';
import UserSettings from 'models/UserSettings';
import { NEVER_SHOW_AGAIN } from 'tabview/consts';
import { useEffectOnce } from '@ardoq/hooks';
import { ErrorInfoBox } from '@ardoq/error-info-box';
import { simpleNotifier } from '@ardoq/dropdown-menu';
import { isPresentationMode } from '../../appConfig';
import { viewHasLegend } from 'views/metaInfoTabs';
import { onViewSettingsUpdate } from 'tabview/onViewSettingsUpdate';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import { noop } from 'lodash';
import {
  ArdoqLoaderSpinner,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';
import { Centered } from '@ardoq/snowflakes';

const VIEW_ID = ViewIds.TIMELINE;

const TimelineView = (props: TimelineViewProperties) => {
  const isLWUI = false;

  const [errorState, setErrorState] = useState({
    isNeverShowAgainSet: false,
    clearedErrors: false,
    clearedHasClones: false,
  });

  useEffectOnce(() => {
    const readNeverShowAgain = async () => {
      const isNeverShowAgainSet =
        (await new UserSettings(ViewIds.TIMELINE).get<boolean>(
          NEVER_SHOW_AGAIN
        )) ?? false;
      if (isNeverShowAgainSet !== errorState.isNeverShowAgainSet) {
        setErrorState({ ...errorState, isNeverShowAgainSet });
      }
    };

    readNeverShowAgain();
  });

  const {
    viewStatus,
    data,
    timeSpanFields,
    milestoneFields,
    viewSettings,
    domain,
    hasGrouping,
    legendComponentTypes,
    errors,
    hasClones,
    collapsibleGroupIds,
    rootCollapsibleGroupIds,
    isViewpointMode,
  } = props;
  const { isLegendActive, selectedMilestoneFields, selectedPhaseFields } =
    viewSettings;

  const openDateRangeViewSetting = useRef(simpleNotifier());
  const openMilestonesViewSetting = useRef(simpleNotifier());

  if (viewStatus === ViewLoadingStatus.LOADING) {
    return (
      <Centered>
        <ArdoqLoaderSpinner color={LoaderColor.GRAY} size={LoaderSize.MEDIUM} />
      </Centered>
    );
  }

  const activeDiffMode = getActiveDiffMode();

  const {
    isInScopeDiffMode,
    trackingFunction,
    addToPresentation,
    getStyleSheets,
  } = getSharedExportFunctions();

  const renderViewErrors = () => (
    <ErrorInfoBox
      errors={errorState.clearedErrors ? [] : errors}
      hasClones={
        !errorState.isNeverShowAgainSet &&
        !errorState.clearedHasClones &&
        hasClones
      }
      clearHasClones={() => {
        if (errorState.clearedHasClones) {
          return;
        }
        setErrorState({ ...errorState, clearedHasClones: true });
      }}
      clearErrors={() => {
        if (errorState.clearedErrors) {
          return;
        }
        setErrorState({ ...errorState, clearedErrors: true });
      }}
      isShowNeverAgainSet={errorState.isNeverShowAgainSet}
      isPresentationMode={isPresentationMode()}
      toggleNeverShowAgain={() => {
        const isNeverShowAgainSet = !errorState.isNeverShowAgainSet;
        new UserSettings(ViewIds.TIMELINE).set(
          NEVER_SHOW_AGAIN,
          isNeverShowAgainSet
        );
        setErrorState({ ...errorState, isNeverShowAgainSet });
      }}
    />
  );

  const renderViewLegend = () => (
    <HeightOffset>
      {heightOffset => (
        <ViewLegendContainer
          heightOffset={heightOffset}
          visible={isLegendActive}
        >
          <ViewLegend
            componentTypes={legendComponentTypes}
            activeConditionalFormatting={getActiveConditionalFormattingForLegend()}
            additionalRows={getLegendRows(props)}
            activeDiffMode={isInScopeDiffMode ? activeDiffMode : null}
          />
        </ViewLegendContainer>
      )}
    </HeightOffset>
  );

  const renderEmptyState = () => (
    <EmptyState
      milestoneFields={milestoneFields}
      timeSpanFields={timeSpanFields}
      selectedMilestoneFields={selectedMilestoneFields}
      selectedPhaseFields={selectedPhaseFields}
      openDateRangeViewSetting={openDateRangeViewSetting.current}
      openMilestonesViewSetting={openMilestonesViewSetting.current}
    />
  );

  const timelineProperties: TimelineProperties = {
    isLWUI,
    isViewpointMode,
    domain,
    data,
    hasGrouping,
    viewSettings,
    collapsibleGroupIds,
    rootCollapsibleGroupIds,
    onComponentDoubleClick: componentId => {
      return isViewpointMode
        ? dispatchAction(openDetailsDrawer([componentId]))
        : noop;
    },
    isComponent: componentInterface.isComponent,
    getRepresentationData: componentInterface.getRepresentationData,
    allMilestoneFields: milestoneFields,
    allTimeSpanFields: timeSpanFields,

    renderViewErrors,
    renderViewLegend,
    renderEmptyState,

    getLeftMenu: ({
      setCollapsedGroupIdsState,
      collapsedGroupIdsState,
      chartDomainRange,
      adjustedGridPrecision,
    }) =>
      getLeftSettingsMenuConfig({
        viewSettings,
        hasGrouping,
        adjustedGridPrecision,
        milestoneFields,
        phaseFields: timeSpanFields,
        onViewSettingsUpdate,
        chartDomainRange,
        toggleCollapseMenuItems: getToggleCollapseMenuItems({
          setCollapsedGroupIdsState,
          collapsedGroupIdsState,
          collapsibleGroupIds,
          rootCollapsibleGroupIds,
        }),
        isLWUI,
        isViewpointMode,
        appSpecificArgs: {
          openDateRangeViewSetting: openDateRangeViewSetting.current,
          openMilestonesViewSetting: openMilestonesViewSetting.current,
          isPresentationMode: isPresentationMode(),
        },
      }),
    getRightMenu: ({
      getContainer,
      windowCenter,
      windowScale,
      setWindow,
      chartDomainRange,
      windowScalingLimitsForGrid,
    }) =>
      getRightSettingsMenuConfig({
        viewSettings,
        getContainer,
        windowCenter,
        windowScale,
        setWindow,
        onViewSettingsUpdate,
        isLWUI,
        isViewpointMode,
        getStyleSheets,
        trackingFunction,
        viewHasLegend,
        windowScalingLimitsForGrid,
        chartDomainRange,
        appSpecificArgs: {
          isInScopeDiffMode,
          addToPresentation,
          isEmptyView: !data.length,
        },
      }),

    appSpecificProperties: {},
  };

  return <Timeline {...timelineProperties} />;
};

const PerformanceTrackedTimelineView = (props: TimelineViewProperties) =>
  WithPerformanceTracking('timeline view render', 1000, {
    WrappedComponent: TimelineView,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: { itemCount: props.data.length },
  });

export default connectInstance(PerformanceTrackedTimelineView, viewModel$);
