import {
  DropdownItem,
  DropdownOptionType,
  isDropdownOption,
} from '@ardoq/dropdown-menu';
import { TOGGLE_LOCK_MENU_ITEM_KEY } from 'contextMenus/consts';

const isToggleLockMenuItem = (dropdownItem: DropdownItem) =>
  isDropdownOption(dropdownItem) &&
  dropdownItem.name === TOGGLE_LOCK_MENU_ITEM_KEY;

const transformComponentMenu = (
  result: DropdownItem[],
  current: DropdownItem
) =>
  isToggleLockMenuItem(current)
    ? [...result, { type: DropdownOptionType.DIVIDER }]
    : [...result, current];

export default transformComponentMenu;
