import { dispatchAction } from '@ardoq/rxbeach';
import {
  SetHighlightArgs,
  layoutBegin,
  layoutComplete,
  relationshipsViewLayoutUpdated,
  relationshipsViewSetHighlight,
  relationshipsViewSetHighlightDisconnectedNodes,
  relationshipsViewSetLinkBundling,
  relationshipsViewUpdateWindowRect,
  relationshipsViewZoomToFit,
  zoomChanged,
} from './actions';
import {
  LayoutType,
  RelationshipsLink,
  RelationshipsLinkVisual,
  RelationshipsNode,
  WindowRect,
} from './types';
import * as React from 'react';
import { EXPANDER_RADIUS, NODE_MARGIN, NODE_RADIUS } from './consts';
import { ZoomTransform, zoomIdentity } from 'd3';
import {
  type LegendComponentType,
  type LegendReferenceType,
  areaOfCircle,
  radiusOfCircle,
  radiusScaler,
  getComponentLabelParts,
} from '@ardoq/graph';
import { geometry } from '@ardoq/math';
import { updateViewSettings } from '@ardoq/view-settings';
import HitContext from './HitContext';
import {
  clearSubscriptions,
  onFirstAction,
  subscribeToAction,
} from 'streams/utils/streamUtils';
import collapseLinks from './collapseLinks';
import { ExcludeFalsy, returnZero, WILDCARD } from '@ardoq/common-helpers';
import { isEqual, noop, sum, throttle } from 'lodash';
import { ViewIds } from '@ardoq/api-types';
import {
  graphViewLegendHighlightComponentType,
  graphViewLegendHighlightConditionalFormatting,
  graphViewLegendHighlightReferenceType,
  graphViewLegendSetSelectedComponentTypes,
  graphViewLegendSetSelectedConditionalFormatting,
  graphViewLegendSetSelectedReferenceTypes,
} from 'tabview/graphViews/graphViewLegend/actions';
import { componentInterface } from '@ardoq/component-interface';
import {
  ancestorsForEach,
  ancestorsIncludes,
  descendantsForEach,
  findDescendant,
  getNodeAABB,
  includeAllDescendants,
} from './util';
import {
  absolutePosition,
  drawNode,
  getChildCountBadgeFontSizeForNode,
  getGroupBadgeTextAndIconMetrics,
  getNodeFontSize,
  getSubLabelHeight,
  isChildCountBadgeToBeDisplayed,
  shouldShowNodeLabel,
} from './visuals/node';
import { addLinkToContext, drawLink, getLinkPoints } from './visuals/link';
import { canvasResolvedImageReady } from 'tabview/canvasRendering/canvasResolvedImages';
import { selectComponent } from 'streams/components/ComponentActions';
import { selectReference } from 'streams/references/ReferenceActions';
import { trackNodeClickOnCanvas } from './tracking';
import {
  PopoverPlacement,
  SimpleTextPopover,
  showPopoverAt,
} from '@ardoq/popovers';
import createDispatcher from './view/createDispatcher';
import { getContextMenuData } from './contextMenu';
import { setContextMenuState } from '../../../contextMenus/contextMenuState$';
import groupDisconnectedChildren from './viewModel$/groupDisconnectedChildren';
import getSelectedNodes from './layout/getSelectedNodes';
import getSelectedLinks from './layout/getSelectedLinks';
import {
  getMatchingFilterIdsFilter,
  getReferenceTypesFilter,
} from './layout/util';
import layoutNodeChildren from './layout/layoutNodeChildren';
import getMinAndMaxDescendants from './layout/getMinAndMaxDescendants';
import truncateLabel, { clearFontMetricsCache } from './layout/truncateLabel';
import applyPreviousPositions from './layout/applyPreviousPositions';
import { trackNodeCountWithPresentationMeta } from 'tracking/events/visualizations';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import { incrementalLayout } from './layout/incrementalLayout';
import { Features, hasFeature } from '@ardoq/features';
import { getGroupNodesWithChangedOpenState } from './getGroupNodesWithChangedOpenState';
import { currentTimestamp } from '@ardoq/date-time';
import nodeLabelY from './visuals/nodeLabelY';
import { shouldShowExpander } from './visuals/util';

/** @deprecated use isInside. */
const isInsideLegacy = (node: RelationshipsNode, window: WindowRect) => {
  const [windowLeft, windowTop, windowRight, windowBottom] = window;
  const nodeLeft = node.x - node.r;
  const nodeRight = node.x + node.r;
  const nodeTop = node.y - node.r;
  const nodeBottom = node.y + node.r;
  return (
    nodeLeft >= windowLeft &&
    nodeRight <= windowRight &&
    nodeTop >= windowTop &&
    nodeBottom <= windowBottom
  );
};

const isInside = (node: RelationshipsNode, window: WindowRect) => {
  const aabb = getNodeAABB(node);
  const slop = NODE_RADIUS;

  return (
    aabb !== null &&
    aabb[0] >= window[0] - slop &&
    aabb[1] >= window[1] - slop &&
    aabb[2] <= window[2] + slop &&
    aabb[3] <= window[3] + slop
  );
};

// Component names should be shown in a popover only when they are truncated, NOT when the names are not rendered in a zoomed out state.
// Group names should be shown in a popover only when they are truncated, OR when the names are not rendered in a zoomed out state.
// https://ardoqcom.atlassian.net/browse/ARD-11738
/**
 * checks if the popover should be displayed.
 *
 * @returns true if the node label was truncated and (large enough to print on-screen or is a group label).
 */
const shouldShowPopover = (
  node: RelationshipsNode,
  viewTransform: ZoomTransform,
  scale: number,
  truncatedLabel?: string
) =>
  truncatedLabel !== node.label &&
  (node.descendantCount ||
    shouldShowNodeLabel(getNodeFontSize(node, viewTransform, scale)));

/** minimum group node radius */
const RADIUS_MIN = 2 * NODE_RADIUS;
const AREA_MIN = areaOfCircle(RADIUS_MIN);
/** maximum group node radius */
const RADIUS_MAX = 10 * RADIUS_MIN;
const TIME_MAX = 100;

const resetSelection = () => {
  dispatchAction(
    graphViewLegendSetSelectedComponentTypes({ componentTypes: [] })
  );
  dispatchAction(
    graphViewLegendSetSelectedReferenceTypes({ referenceTypes: [] })
  );
  dispatchAction(graphViewLegendSetSelectedConditionalFormatting({ ids: [] }));
};

const getSelectedNodesFromFormatting = (
  nodes: RelationshipsNode[],
  ids: string[]
) => nodes.filter(getMatchingFilterIdsFilter(new Set(ids)));
const getSelectedLinksFromFormatting = (
  links: RelationshipsLinkVisual[],
  ids: string[]
) => links.filter(getMatchingFilterIdsFilter(new Set(ids)));

/**
 * counts visible descendants of the node, including the node itself
 */
const getVisibleChildrenCount = (node: RelationshipsNode | null): number =>
  (node?.isSynthetic ? 0 : 1) +
  (node?.open ? sum(node.children?.map(getVisibleChildrenCount)) : 0);

const isHighlightEqual = (a: SetHighlightArgs, b: SetHighlightArgs) =>
  a.highlightedNode?.id === b.highlightedNode?.id &&
  isEqual(a.highlightedLink?.modelIds, b.highlightedLink?.modelIds);

const DIM_ALPHA = 0.28;

const clearContext = (
  context: CanvasRenderingContext2D,
  width: number,
  height: number
) => context.clearRect(0, 0, width, height);

const fixCanvasDpi = (
  canvas: HTMLCanvasElement,
  width = canvas.offsetWidth * devicePixelRatio,
  height = canvas.offsetHeight * devicePixelRatio
) => {
  canvas.setAttribute('width', `${width}`);
  canvas.setAttribute('height', `${height}`);
};

/** this is stupid */
const isAcceptableEntropy = (entropy: number, elapsedMilliseconds: number) =>
  entropy > 20
    ? elapsedMilliseconds > 10000
    : entropy > 1
      ? elapsedMilliseconds > 5000
      : entropy < 0.01 || elapsedMilliseconds > 2000;

const isGroupNode = ({ isSynthetic, children }: RelationshipsNode) =>
  !isSynthetic && children?.length;

type LayoutArgs = {
  canvas: HTMLCanvasElement;
  rootNode: RelationshipsNode | null;
  links: RelationshipsLink[];
  bundleRelationships: boolean;
  highlightDisconnectedNodes: boolean;
  viewInstanceId: string;
  collapsedGroupIds: string[];
  lastClickedNodeId: string | null;
};
export const createLayout = (createLayoutArgs: LayoutArgs) => {
  let {
    canvas,
    links: originalLinks,
    bundleRelationships,
    highlightDisconnectedNodes,
    viewInstanceId,
    collapsedGroupIds,
    lastClickedNodeId,
  } = createLayoutArgs;

  // #region stateful variables. some of these will be faked out until initialize is called.
  let rootNode: RelationshipsNode | null = null;
  let links: RelationshipsLinkVisual[] = [];
  let canvasBounds: Pick<DOMRect, 'x' | 'y' | 'width' | 'height'> = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  let scale = 1 / devicePixelRatio; // world window scale
  let window: WindowRect | null = null; // world clip window (null means don't clip)
  let linkBundling = Number(bundleRelationships);
  let disconnectedNodesHighlighted = highlightDisconnectedNodes;
  let ticking = false;
  let lastScheduled = currentTimestamp();
  let dead = false;
  let currentLayout: LayoutType | null = null;
  let nextLayout: LayoutType = 'packLayout';
  let isGraphFullyInView = true;
  let context: CanvasRenderingContext2D = new HitContext(); // just faking this out for the moment. it will be initialized truly when initialize is called.
  let minDescendants = 0;
  let maxDescendants = 0;
  let lastClickedNode: RelationshipsNode | null = null;
  let lastHoveredNode: RelationshipsNode | null = null;
  let lastClickedLink: RelationshipsLinkVisual | null = null;
  let groupNodeRadius: (value: number) => number = returnZero;

  let legendHighlightedNodes: RelationshipsNode[] | null = null;
  let legendSelectedNodes: RelationshipsNode[] | null = null;
  let legendHighlightedLinks: RelationshipsLinkVisual[] | null = null;
  let legendSelectedLinks: RelationshipsLinkVisual[] | null = null;
  let legendSelectedComponentTypes: LegendComponentType[] | null = null;
  let legendSelectedReferenceTypes: LegendReferenceType[] | null = null;
  let legendSelectedConditionalFormatting: string[] | null = null;
  // #endregion

  const getNodeRadius = ({ descendantCount }: RelationshipsNode) =>
    !descendantCount ? NODE_RADIUS : groupNodeRadius(descendantCount);

  const generateLinkVisuals = () =>
    Array.from(originalLinks.reduce(collapseLinks, new Map()).values());

  const hasAdaptive = hasFeature(Features.ADAPTIVE_RELATIONSHIPSVIEW);

  const tick = () => {
    if (dead) {
      return;
    }

    const then = currentTimestamp();

    for (const node of scheduled) {
      if (hasAdaptive) {
        const radius = node.r;
        const cycles = then + TIME_MAX - currentTimestamp();

        if (cycles <= 0) {
          break;
        }

        if (incrementalLayout(node, getNodeRadius, Math.min(cycles, 30))) {
          deschedule(node, false);
        }

        if (Math.round(node.r) !== Math.round(radius) && node.parent) {
          schedule(node.parent, false);
        }
      } else {
        const {
          entropy,
          minimumEnclosingCircle,
          minimumEnclosingCircleForChildren,
        } = layoutNodeChildren(nextLayout, node, getNodeRadius);

        if (minimumEnclosingCircleForChildren) {
          node.minimumEnclosingCircleForChildren =
            minimumEnclosingCircleForChildren;
        }

        const roundedRadius = Math.round(minimumEnclosingCircle.r);
        if (node.r !== roundedRadius) {
          node.r = roundedRadius;

          if (node.parent) {
            schedule(node.parent, false); // parent needs more work
          }
        }

        if (isAcceptableEntropy(entropy, currentTimestamp() - lastScheduled)) {
          deschedule(node, false); // done with this node
        }

        const now = currentTimestamp();

        if (now - then > TIME_MAX) {
          break;
        }
      }
    }

    ticking = scheduled.size !== 0;
    if (!hasAdaptive && scheduled.size === 0) {
      nextLayout = 'forceLayout';
    }
    redraw();

    if (ticking) {
      requestAnimationFrame(tick);
    } else {
      dispatchAction(layoutComplete({ needsFit: isGraphFullyInView }));
      trackNodeCountWithPresentationMeta(
        ViewIds.RELATIONSHIPS_3,
        getVisibleChildrenCount(rootNode)
      );
    }

    notifyUpdateLayout();
  };

  let focusNodes = new Set<RelationshipsNode>();
  let markedNodes = new Set<RelationshipsNode>();
  let focusLinks = new Set<RelationshipsLinkVisual>();
  let markedLinks = new Set<RelationshipsLinkVisual>();

  // #region schedule and deschedule
  const scheduled = new Set<RelationshipsNode>();
  const schedule = (node: RelationshipsNode, recursive: boolean) => {
    if (recursive) {
      [...includeAllDescendants(node)]
        .filter(({ children, open }) => children && open)
        .forEach(openDescendantGroup => scheduled.add(openDescendantGroup));
    } else {
      scheduled.add(node);
    }

    if (!ticking) {
      ticking = true;
      lastScheduled = currentTimestamp();
      currentLayout = nextLayout;
      dispatchAction(layoutBegin({ layout: currentLayout }));
      requestAnimationFrame(tick);
    }
  };
  const deschedule = (node: RelationshipsNode, recursive: boolean) => {
    scheduled.delete(node);

    if (recursive && node.children && node.open) {
      node.children.forEach(child => deschedule(child, true));
    }
  };
  // #endregion
  const updateFocusVisuals = () => {
    const selectedNode = lastHoveredNode || lastClickedNode;

    // Handle case where lastClickedLink is set
    if (lastClickedLink && !selectedNode) {
      focusNodes = new Set([
        lastClickedLink.source,
        lastClickedLink.target,
        ...(legendHighlightedNodes ?? []),
        ...(legendSelectedNodes ?? []),
      ]);

      focusLinks = new Set([
        lastClickedLink,
        ...(legendHighlightedLinks ?? []),
        ...(legendSelectedLinks ?? []),
      ]);

      markedNodes = new Set<RelationshipsNode>([
        lastClickedLink.source,
        lastClickedLink.target,
      ]);

      markedLinks = new Set([lastClickedLink]);
      return;
    }

    // Handle case where lastClickedNode is set
    const clickRelevantNodes = selectedNode
      ? new Set(includeAllDescendants(selectedNode))
      : null;

    const clickHighlightedElements =
      clickRelevantNodes &&
      links.reduce(
        (state, link) => {
          if (clickRelevantNodes.has(link.sourceProxy)) {
            ancestorsForEach(link.targetProxy, node =>
              state.focusNodes.add(node)
            );
            state.focusLinks.add(link);
          } else if (clickRelevantNodes.has(link.targetProxy)) {
            ancestorsForEach(link.sourceProxy, node =>
              state.focusNodes.add(node)
            );
            state.focusLinks.add(link);
          }
          return state;
        },
        {
          focusNodes: new Set(clickRelevantNodes),
          focusLinks: new Set<RelationshipsLinkVisual>(),
        }
      );

    focusNodes = new Set([
      ...(selectedNode ? includeAllDescendants(selectedNode) : []),
      ...(clickHighlightedElements?.focusNodes ?? []),
      ...(legendHighlightedNodes ?? []),
      ...(legendSelectedNodes ?? []),
    ]);

    markedNodes = new Set<RelationshipsNode>(
      [selectedNode].filter(ExcludeFalsy)
    );

    focusLinks = new Set([
      ...(clickHighlightedElements?.focusLinks ?? []),
      ...(legendHighlightedLinks ?? []),
      ...(legendSelectedLinks ?? []),
    ]);

    markedLinks = clickHighlightedElements?.focusLinks ?? new Set();
  };
  const initialize = (args: LayoutArgs) => {
    scheduled.clear();
    // #region update layout scope variables
    canvas = args.canvas;
    let isSameSet = false;
    if (args.rootNode && rootNode) {
      const { anyPreviousNodesFound, isSameSet: sameSet } =
        applyPreviousPositions(args.rootNode, rootNode);
      if (!anyPreviousNodesFound) {
        nextLayout = 'packLayout'; // if no previous positions were found, this is a brand-new layout.
        isGraphFullyInView = true;
      }

      isSameSet = sameSet;
    } else {
      nextLayout = 'packLayout';
      isGraphFullyInView = true;
    }

    const nodesWithChangedOpenState = getGroupNodesWithChangedOpenState(
      args.rootNode,
      rootNode
    );

    rootNode = args.rootNode;
    originalLinks = args.links;
    bundleRelationships = args.bundleRelationships;
    highlightDisconnectedNodes = args.highlightDisconnectedNodes;
    viewInstanceId = args.viewInstanceId;
    collapsedGroupIds = args.collapsedGroupIds;
    lastClickedNodeId = args.lastClickedNodeId;
    // #endregion

    resetSelection();
    links = generateLinkVisuals();
    canvasBounds = canvas.getBoundingClientRect();
    ticking = false;
    lastScheduled = currentTimestamp();
    dead = false;
    context = canvas.getContext('2d')!;
    if (rootNode) {
      const {
        minDescendants: newMinDescendants,
        maxDescendants: newMaxDescendants,
      } = getMinAndMaxDescendants(rootNode);
      minDescendants = newMinDescendants;
      maxDescendants = newMaxDescendants;
      if (!isSameSet) {
        schedule(rootNode, true);
      } else if (nodesWithChangedOpenState.size) {
        fitOnNextLayoutComplete();
        nodesWithChangedOpenState.forEach(node => schedule(node, false));
      }
    } else {
      minDescendants = 0;
      maxDescendants = 0;
    }
    lastClickedNode = rootNode
      ? (findDescendant(rootNode, ({ id }) => id === lastClickedNodeId) ?? null)
      : null;
    if (lastClickedNode) {
      updateFocusVisuals();
    }

    const descendantCountRatio = maxDescendants / minDescendants;
    const radiusMax = Math.min(
      RADIUS_MAX,
      radiusOfCircle(AREA_MIN * descendantCountRatio)
    ); // don't scale largest group beyond its ratio to the smallest group
    groupNodeRadius = radiusScaler(
      [RADIUS_MIN, radiusMax],
      [minDescendants, maxDescendants]
    );
  };
  initialize(createLayoutArgs);

  const dimCanvas = canvas.cloneNode(false) as HTMLCanvasElement; // would be nice to use OffscreenCanvas here, but it's not yet supported in some browsers.
  const dimContext = dimCanvas.getContext('2d')!;

  const toggleCollapsed = (node: RelationshipsNode) => {
    updateHighlight({ highlightedLink: null, highlightedNode: null });

    const open = !node.open;

    const collapsingParentOfLastClickedNode =
      !open &&
      lastClickedNode &&
      lastClickedNode !== node &&
      ancestorsIncludes(lastClickedNode, node);
    if (collapsingParentOfLastClickedNode) {
      lastClickedNode = null;
      drawFocusVisuals();
    }
    if (!rootNode) {
      collapsedGroupIds = [];
    } else {
      collapsedGroupIds = open
        ? collapsedGroupIds[0] === WILDCARD // user is expanding first group from collapsed-all state. re-initialize collapsedGroupIds as every group except the one that is expanding.
          ? [...includeAllDescendants(rootNode)]
              .filter(current => current.id !== node.id && isGroupNode(current))
              .map(({ id }) => id)
          : collapsedGroupIds.filter(id => id !== node.id) // user is expanding a group. remove group id from collapsedGroupIds
        : [...collapsedGroupIds, node.id]; // user is collapsing a group. set collapsedGroupIds to its previous value plus the new group id
    }
    dispatchAction(
      updateViewSettings({
        viewId: ViewIds.RELATIONSHIPS_3,
        settings: { collapsedGroupIds },
        persistent: true,
      })
    );
    setNodeOpen(node, open);
  };

  /* node rendering *********************************************************/

  const truncatedLabels = new Map<RelationshipsNode, string>();
  const truncatedSubLabels = new Map<RelationshipsNode, string>();

  const getLabel = (node: RelationshipsNode, marked: boolean) => {
    const { label, subLabel, r, descendantCount, open, modelId } = node;
    const previousLabel = label && truncatedLabels.get(node);
    const isExpandedGroup = descendantCount && open;
    const fontSize = getNodeFontSize(node, viewTransform, scale);
    const labelHeight = fontSize / devicePixelRatio;
    const widthForIcon = isExpandedGroup ? labelHeight : 0;
    const subLabelHeight =
      isExpandedGroup && subLabel
        ? getSubLabelHeight(viewTransform, scale) / devicePixelRatio
        : 0;

    /** offset, from bottom of node circle, of a chord with the width equal to the available label width.  */
    const showExpander = shouldShowExpander(node, viewTransform.k);
    const labelY = nodeLabelY(node, showExpander, marked || node.isContext);

    // if it is a group, the label is inside of the node, take the radius.
    // If it is a component, the label is outside, and there is 3*NODE_MARGIN space between the nodes
    const availableRadius = descendantCount ? r : r + 3 * NODE_MARGIN;

    const isComponent = modelId && componentInterface.isComponent(modelId);
    const labelInfo = isViewpointMode
      ? { label, fieldLabel: null, fieldValue: null }
      : isComponent
        ? getComponentLabelParts(modelId)
        : {
            label,
            fieldLabel: null,
            fieldValue: null,
          };

    const labelOffset = labelY - node.y;
    const truncatedLabel =
      previousLabel ||
      (node.label &&
        truncateLabel(
          labelInfo,
          availableRadius,
          scale,
          labelHeight,
          widthForIcon,
          labelOffset
        ));

    if (label && !previousLabel) {
      truncatedLabels.set(node, truncatedLabel);
    }
    const previousSubLabel =
      subLabel && isExpandedGroup && truncatedSubLabels.get(node);
    const subLabelOffset = labelOffset + labelHeight;
    const truncatedSubLabel =
      previousSubLabel ||
      (subLabel &&
        isExpandedGroup &&
        truncateLabel(
          { label: subLabel, fieldLabel: null, fieldValue: null },
          r,
          scale,
          subLabelHeight,
          0,
          subLabelOffset
        ));
    if (truncatedSubLabel && !previousSubLabel) {
      truncatedSubLabels.set(node, truncatedSubLabel);
    }
    return { label: truncatedLabel, subLabel: truncatedSubLabel || null };
  };

  const nodeRender = (
    node: RelationshipsNode,
    transform: ZoomTransform,
    windowSize: [width: number, height: number]
  ) => {
    const dim = (focusNodes.size || focusLinks.size) && !focusNodes.has(node);
    const marked = markedNodes.size > 0 && markedNodes.has(node);
    const { label, subLabel } = getLabel(node, marked);
    context.globalAlpha = dim ? DIM_ALPHA : 1;
    drawNode(
      node,
      label,
      subLabel,
      transform,
      context,
      windowSize,
      scale,
      marked,
      Boolean(node.descendantCount) &&
        node === currentHighlight.highlightedNode,
      disconnectedNodesHighlighted
    );
    context.globalAlpha = 1;
    if (!node.open) {
      return;
    }

    node.children?.forEach(child =>
      nodeRender(child, transform.translate(node.x, node.y), windowSize)
    );
  };

  const setBranchOpen = (node: RelationshipsNode | null, value: boolean) => {
    if (node) {
      const descendantGroups = [...includeAllDescendants(node)];
      descendantGroups
        .filter(isGroupNode)
        .forEach(descendantGroup => setNodeOpen(descendantGroup, value));

      collapsedGroupIds =
        node === rootNode
          ? value
            ? [] // expanding root, clear collapsedGroupIds
            : [WILDCARD] // collapsing root, set collapsedGroupIds to wildcard
          : value
            ? collapsedGroupIds.filter(id => id !== node.id) // expanding non-root branch, remove node from collapsedGroupIds
            : [...collapsedGroupIds, ...descendantGroups.map(({ id }) => id)]; // collapsing non-root branch, add node and descendants to collapsedGroupIds
    }
  };

  const setNodeOpen = (node: RelationshipsNode | null, value: boolean) => {
    if (node && node.children && node.open !== value && !node.isSynthetic) {
      node.open = !!value;

      if (node.open) {
        schedule(node, true); // node and entire descendancy needs relayouting

        /* all of the links to currently hidden descendants for which I proxy need to be reassigned */
      } else {
        schedule(node, false); // node needs relayouting
        node.children.forEach(child => deschedule(child, true)); // kill any simulations that might be happening on the children which are about to become invisible

        /* I need to become a proxy for all of my descendant's links */
      }
      regenerateLinkVisuals();
    }
  };

  const setWindow = (newWindow: WindowRect | null, newScale: number) => {
    destroyPopover();
    window = newWindow;

    if (newScale !== scale) {
      scale = newScale;
      truncatedLabels.clear();
      truncatedSubLabels.clear();
    }
    isGraphFullyInView = hasAdaptive
      ? ticking
        ? isGraphFullyInView // don't update this while the simulation is running.
        : !rootNode || !window || isInside(rootNode, window)
      : !rootNode || !window || isInsideLegacy(rootNode, window);
    redraw();
  };

  if (rootNode) {
    descendantsForEach(rootNode, node => (node.r = getNodeRadius(node)));
  }

  /* payload *********************************************************/

  let viewTransform = zoomIdentity
    .scale(devicePixelRatio)
    .translate(canvas.offsetWidth / 2, canvas.offsetHeight / 2);

  const updateWindow = () => {
    /* prevaricate adjusting everything else */
    canvasBounds = canvas.getBoundingClientRect();
    const l = viewTransform.invert([0, 0]);
    const r = viewTransform.invert([
      canvas.offsetWidth * devicePixelRatio,
      canvas.offsetHeight * devicePixelRatio,
    ]);
    const newWindow: WindowRect = [l[0], l[1], r[0], r[1]];
    setWindow(newWindow, 1.0 / viewTransform.k);
    dispatchAction(
      relationshipsViewUpdateWindowRect({ currentWindowRect: newWindow }),
      viewInstanceId
    );
  };

  const drawFocusVisuals = () => {
    updateFocusVisuals();
    redraw();
  };

  const dispatcher = createDispatcher();
  const regenerateLinkVisuals = dispatcher(
    () => (links = generateLinkVisuals()),
    () => 'regenerateLinkVisuals'
  );
  const redrawImmediate = (currentLinkBundling = linkBundling) => {
    fixCanvasDpi(canvas);
    fixCanvasDpi(dimCanvas, canvas.width, canvas.height);
    clearContext(context, canvas.width, canvas.height);
    clearContext(dimContext, dimCanvas.width, dimCanvas.height);
    if (canvas.width === 0 || canvas.height === 0) {
      return;
    }
    if (rootNode) {
      nodeRender(rootNode, viewTransform, [
        canvas.offsetWidth * devicePixelRatio,
        canvas.offsetHeight * devicePixelRatio,
      ]);
    }
    const highlightLinks = focusLinks.size > 0 || focusNodes.size > 0;
    if (highlightLinks) {
      links
        .filter(link => !focusLinks.has(link))
        .forEach(link =>
          drawLink(
            link,
            window,
            currentLinkBundling,
            viewTransform,
            dimContext,
            markedLinks.has(link),
            hasAdaptive
          )
        );
      context.globalAlpha = DIM_ALPHA;
      context.drawImage(dimCanvas, 0, 0);
      context.globalAlpha = 1;
      focusLinks.forEach(link =>
        drawLink(
          link,
          window,
          currentLinkBundling,
          viewTransform,
          context,
          markedLinks.has(link),
          hasAdaptive
        )
      );
    } else {
      links.forEach(link => {
        drawLink(
          link,
          window,
          currentLinkBundling,
          viewTransform,
          context,
          markedLinks.has(link),
          hasAdaptive
        );
      });
    }
  };
  const redraw = dispatcher(
    () => redrawImmediate(),
    () => true
  );
  const notifyUpdateLayout = dispatcher(
    () => dispatchAction(relationshipsViewLayoutUpdated()),
    () => 'notifyUpdateLayout'
  );
  const deferredRedraw = throttle(redraw, 100, {
    leading: false,
    trailing: true,
  });

  const fitOnNextLayoutComplete = () =>
    onFirstAction(
      layoutComplete,
      () =>
        rootNode &&
        dispatchAction(
          relationshipsViewZoomToFit({ node: rootNode }),
          viewInstanceId
        ),
      viewInstanceId
    );

  let isViewpointMode = false;
  const subscriptions = [
    subscribeToAction(
      zoomChanged,
      ({ transform }) => {
        viewTransform = transform;
        updateWindow();
      },
      viewInstanceId
    ),
    subscribeToAction(
      relationshipsViewSetLinkBundling,
      ({ bundleRelationships: newBundleRelationships }) => {
        const startValue = linkBundling;
        const targetLinkBundling = Number(newBundleRelationships);

        const start = currentTimestamp();
        const durationMilliseconds = 500;
        let elapsed = currentTimestamp() - start;

        const incrementLinkBundlingAndRedraw = () => {
          const currentLinkBundling =
            startValue +
            (elapsed / durationMilliseconds) *
              Math.sign(targetLinkBundling - startValue);
          requestAnimationFrame(() => {
            redrawImmediate(currentLinkBundling);
            elapsed = currentTimestamp() - start;
            if (elapsed < durationMilliseconds) {
              requestAnimationFrame(incrementLinkBundlingAndRedraw);
            } else {
              linkBundling = targetLinkBundling;
              redraw();
            }
          });
        };
        incrementLinkBundlingAndRedraw();
      }
    ),
    subscribeToAction(canvasResolvedImageReady, deferredRedraw, viewInstanceId),
    subscribeToAction(
      graphViewLegendHighlightComponentType,
      ({ componentType }) => {
        const componentTypeIds = new Set(componentType?.ids);
        if (rootNode) {
          legendHighlightedNodes = !componentType
            ? null
            : [...includeAllDescendants(rootNode)].filter(node => {
                if (!node.modelId) {
                  return false;
                }
                const componentTypeModelId = componentInterface.getTypeId(
                  node.modelId
                );
                return (
                  componentTypeModelId &&
                  componentTypeIds.has(componentTypeModelId)
                );
              });
          drawFocusVisuals();
        }
      }
    ),
    subscribeToAction(
      graphViewLegendSetSelectedComponentTypes,
      ({ componentTypes }) => {
        legendSelectedComponentTypes = componentTypes;
        if (!rootNode) {
          return;
        }
        legendSelectedNodes = getSelectedNodes(
          [...includeAllDescendants(rootNode)],
          legendSelectedComponentTypes,
          legendSelectedConditionalFormatting
        );
        lastClickedNode = null;
        drawFocusVisuals();
      }
    ),
    subscribeToAction(
      graphViewLegendHighlightReferenceType,
      ({ referenceType }) => {
        legendHighlightedLinks = !referenceType
          ? null
          : links.filter(getReferenceTypesFilter([referenceType]));
        drawFocusVisuals();
      }
    ),
    subscribeToAction(
      graphViewLegendSetSelectedReferenceTypes,
      ({ referenceTypes }) => {
        legendSelectedReferenceTypes = referenceTypes;
        legendSelectedLinks = getSelectedLinks(
          links,
          legendSelectedReferenceTypes,
          legendSelectedConditionalFormatting
        );
        lastClickedNode = null;
        drawFocusVisuals();
      }
    ),
    subscribeToAction(
      graphViewLegendHighlightConditionalFormatting,
      ({ id }) => {
        const { selectedLinks, selectedNodes } =
          !id || !rootNode
            ? { selectedLinks: [], selectedNodes: [] }
            : {
                selectedLinks: getSelectedLinksFromFormatting(links, [id]),
                selectedNodes: getSelectedNodesFromFormatting(
                  [...includeAllDescendants(rootNode)],
                  [id]
                ),
              };

        legendHighlightedLinks = selectedLinks;
        legendHighlightedNodes = selectedNodes;
        drawFocusVisuals();
      }
    ),
    subscribeToAction(
      graphViewLegendSetSelectedConditionalFormatting,
      ({ ids }) => {
        legendSelectedConditionalFormatting = ids;
        legendSelectedLinks = getSelectedLinks(
          links,
          legendSelectedReferenceTypes,
          legendSelectedConditionalFormatting
        );
        if (!rootNode) {
          return;
        }
        legendSelectedNodes = getSelectedNodes(
          [...includeAllDescendants(rootNode)],
          legendSelectedComponentTypes,
          legendSelectedConditionalFormatting
        );
        lastClickedNode = null;
        drawFocusVisuals();
      }
    ),
    subscribeToAction(
      relationshipsViewSetHighlightDisconnectedNodes,
      ({ highlightDisconnectedNodes: newHighlightDisconnectedNodes }) => {
        if (!rootNode) {
          return;
        }
        disconnectedNodesHighlighted = newHighlightDisconnectedNodes;
        if (!newHighlightDisconnectedNodes) {
          redraw();
          return;
        }

        descendantsForEach(rootNode, node => {
          if (!node.children && !node.links?.length) {
            ancestorsForEach(node, ancestor => {
              if (ancestor.open) {
                return;
              }
              setNodeOpen(ancestor, true);
            });
          }
        });

        const newCollapsedGroupIds = [...includeAllDescendants(rootNode)]
          .filter(({ descendantCount, open }) => descendantCount && !open)
          .map(({ id }) => id);
        if (!isEqual(collapsedGroupIds, newCollapsedGroupIds)) {
          collapsedGroupIds = newCollapsedGroupIds;
          dispatchAction(
            updateViewSettings({
              viewId: ViewIds.RELATIONSHIPS_3,
              settings: { collapsedGroupIds },
              persistent: true,
            })
          );
        }

        if (!scheduled.size) {
          return;
        }
        fitOnNextLayoutComplete();
      }
    ),
    isViewpointMode$.subscribe(
      ({ isViewpointMode: newIsViewpointMode }) =>
        (isViewpointMode = newIsViewpointMode)
    ),
  ];
  new ResizeObserver(redraw).observe(canvas);

  const isHitRect = (
    rect: Pick<DOMRect, 'x' | 'y' | 'width' | 'height'>,
    mouseX: number,
    mouseY: number
  ) => {
    const [scaledX, scaledY] = viewTransform.apply([mouseX, mouseY]);
    const { x, y, height, width } = rect;
    return (
      scaledY > y && scaledY < y + height && scaledX > x && scaledX < x + width
    );
  };

  const isDisconnectedChildrenCountShown = (node: RelationshipsNode) =>
    !node.open &&
    disconnectedNodesHighlighted &&
    groupDisconnectedChildren([], node).length &&
    isChildCountBadgeToBeDisplayed(
      getChildCountBadgeFontSizeForNode(
        node.r * viewTransform.k,
        viewTransform,
        scale
      )
    );

  /**
   * If the disconnected children icon of the node is shown and hit, returns
   * an object with metrics needed for popover display, null otherwise.
   */
  const getDisconnectedChildrenCountIconMetricsIfHit = (
    node: RelationshipsNode,
    mouseX: number,
    mouseY: number
  ) => {
    if (isDisconnectedChildrenCountShown(node)) {
      const { x: nodeX, y: nodeY } = node;
      const [scaledNodeX, scaledNodeY] = viewTransform.apply([nodeX, nodeY]);

      const {
        iconMetrics: { iconX, iconY, iconR },
        quantityOfDisconnectedChildren,
      } = getGroupBadgeTextAndIconMetrics(
        node,
        scaledNodeX,
        scaledNodeY,
        node.r * viewTransform.k,
        node.descendantCount,
        context,
        disconnectedNodesHighlighted,
        viewTransform,
        scale
      );
      const isIconHit = isHitRect(
        {
          x: iconX,
          y: iconY,
          width: iconR * 2,
          height: iconR * 2,
        },
        mouseX,
        mouseY
      );

      return isIconHit
        ? { iconX, iconY, iconR, quantityOfDisconnectedChildren }
        : null;
    }
    return null;
  };

  const hitContext = new HitContext();
  hitContext.lineWidth = 25;
  const findLink = (x: number, y: number) => {
    for (let ii = links.length - 1; ii >= 0; ii--) {
      const link = links[ii];
      const linkPoints = getLinkPoints(link, window, linkBundling, hasAdaptive);
      if (!linkPoints) {
        continue;
      }
      hitContext.beginPath();
      addLinkToContext(
        link,
        linkPoints,
        linkBundling,
        hitContext,
        zoomIdentity
      );
      if (hitContext.isPointInStroke(x, y)) {
        return link;
      }
    }
    return null;
  };

  const findNode = (x: number, y: number) => {
    const doit = (
      node: RelationshipsNode,
      xx: number,
      yy: number
    ): RelationshipsNode | null => {
      let ret: RelationshipsNode | null = null;

      const localX = xx - node.x;
      const localY = yy - node.y;

      const isHit =
        Math.hypot(localX, localY) < node.r ||
        (node.children?.length &&
          !node.isSynthetic &&
          Math.hypot(localX - 0, localY - node.r) < EXPANDER_RADIUS);

      if (isHit) {
        /* within this node; check the descendants */
        if (node.children && node.open) {
          for (let i = 0; i < node.children.length && !ret; ++i) {
            ret = doit(node.children[i], localX, localY);
          }
        }

        if (!ret && !node.isSynthetic) {
          /* within this node but not a descendant */
          ret = node;
        }
      }

      return ret;
    };

    return rootNode ? doit(rootNode, x, y) : null;
  };

  const locateMouse = (event: Pick<MouseEvent, 'clientX' | 'clientY'>) => {
    const mouseX = event.clientX - canvasBounds.x;
    const mouseY = event.clientY - canvasBounds.y;
    const canvasX = mouseX * devicePixelRatio;
    const canvasY = mouseY * devicePixelRatio;
    return viewTransform.invert([canvasX, canvasY]);
  };
  let currentHighlight: SetHighlightArgs = {
    highlightedLink: null,
    highlightedNode: null,
  };
  const updateHighlight = (highlight: SetHighlightArgs) => {
    if (!isHighlightEqual(highlight, currentHighlight)) {
      currentHighlight = highlight;
      dispatchAction(relationshipsViewSetHighlight(highlight), viewInstanceId);
      redraw();
    }
  };
  let destroyPopover = noop;
  const hit = dispatcher(
    (x: number, y: number) => {
      const link = findLink(x, y);
      if (link) {
        canvas.style.cursor = 'pointer';
        updateHighlight({
          highlightedLink: link,
          highlightedNode: null,
        });

        destroyPopover();

        if (link.label) {
          showPopoverAtAbsolutePosition(x, y, link.label, 0, 0);
        }

        return;
      }
      const node = findNode(x, y);
      if (node) {
        canvas.style.cursor = 'pointer';
        updateHighlight({
          highlightedLink: null,
          highlightedNode: node,
        });

        const [nodeX, nodeY] = absolutePosition(node);

        const isBrokenImageHit =
          !node.open &&
          node.isBrokenImage &&
          y > nodeY - node.r / 3 &&
          y < nodeY + node.r / 3 &&
          x > nodeX - node.r / 3 &&
          x < nodeX + node.r / 3;

        const disconnectedChildrenIconMetrics =
          getDisconnectedChildrenCountIconMetricsIfHit(node, x, y);

        if (disconnectedChildrenIconMetrics) {
          const { iconX, iconY, iconR, quantityOfDisconnectedChildren } =
            disconnectedChildrenIconMetrics;

          const [truePopoverX, truePopoverY] = viewTransform.invert([
            iconX,
            iconY,
          ]);

          showPopoverAtAbsolutePosition(
            truePopoverX,
            truePopoverY,
            `Group contains ${quantityOfDisconnectedChildren} disconnected components`,
            iconR / viewTransform.k,
            (iconR * 2) / viewTransform.k
          );
        }

        if (isBrokenImageHit) {
          showPopoverAtAbsolutePosition(
            nodeX,
            nodeY,
            'Image cannot be displayed',
            0,
            node.r / 3
          );
        } else if (
          !disconnectedChildrenIconMetrics &&
          shouldShowPopover(
            node,
            viewTransform,
            scale,
            truncatedLabels.get(node)
          )
        ) {
          // The user is mousing over a node with a truncated label. Show the popover so the user can read the label.
          showPopoverAtAbsolutePosition(nodeX, nodeY, node.label, 0, node.r);
        } else if (!disconnectedChildrenIconMetrics) {
          destroyPopover();
        }

        return;
      }
      destroyPopover();
      canvas.style.cursor = '';
      updateHighlight({
        highlightedLink: null,
        highlightedNode: null,
      });
    },
    () => 'hit'
  );

  const showPopoverAtAbsolutePosition = (
    posX: number,
    posY: number,
    popoverText: string,
    xOffest: number,
    yOffset: number
  ) => {
    const popoverX = Math.max(
      0,
      Math.min(
        canvas.offsetWidth,
        viewTransform.applyX(posX + xOffest) / devicePixelRatio
      )
    );
    const popoverY = Math.max(
      0,
      Math.min(
        canvas.offsetHeight,
        viewTransform.applyY(posY + yOffset) / devicePixelRatio
      )
    );

    const { left: canvasLeft, top: canvasTop } = canvas.getBoundingClientRect();
    const popoverScreenX = canvasLeft + popoverX;
    const popoverScreenY = canvasTop + popoverY;
    destroyPopover = showPopoverAt({
      content: <SimpleTextPopover text={popoverText} />,
      position: [popoverScreenX, popoverScreenY],
      placement: PopoverPlacement.BOTTOM,
    });
  };

  const unsetLayout = () => {
    resetSelection();
    clearContext(context, canvas.width, canvas.height);
    clearContext(dimContext, dimCanvas.width, dimCanvas.height);
  };
  return {
    /* return an object which allows access to the relevant parts of above */
    dispatcher,
    setNodeOpen: (node: RelationshipsNode | null, value: boolean) =>
      setNodeOpen(node ?? rootNode, value),

    setBranchOpen: (node: RelationshipsNode | null, value: boolean) =>
      setBranchOpen(node ?? rootNode, value),

    click: (event: React.MouseEvent) => {
      resetSelection();

      const [x, y] = locateMouse(event);
      const node = findNode(x, y);

      if (node) {
        lastClickedLink = null;

        const [nodeX, nodeY] = absolutePosition(node);
        const expanderHit =
          node.children?.length &&
          geometry.distanceFourArgs(x, y, nodeX, nodeY + node.r) <
            EXPANDER_RADIUS;

        if (expanderHit) {
          toggleCollapsed(node);
          return;
        }

        lastClickedNode = node;
        drawFocusVisuals();
        trackNodeClickOnCanvas({
          viewId: ViewIds.RELATIONSHIPS_3,
          nodeType: node.children?.length ? 'group' : 'component',
        });
        return;
      }

      const link = findLink(x, y);
      if (link) {
        lastClickedNode = null;
        lastClickedLink = link;
        drawFocusVisuals();
        return;
      }

      lastClickedNode = null;
      lastClickedLink = null;
    },
    doubleClick: (event: React.MouseEvent) => {
      const [x, y] = locateMouse(event);

      const link = findLink(x, y);
      if (link?.modelId) {
        updateHighlight({ highlightedLink: null, highlightedNode: null });
        dispatchAction(selectReference({ cid: link.modelId }));
        return;
      }

      const node = findNode(x, y);
      if (node?.modelId) {
        updateHighlight({ highlightedLink: null, highlightedNode: null });
        if (isViewpointMode && componentInterface.isComponent(node.modelId)) {
          dispatchAction(openDetailsDrawer([node.modelId]));
          return;
        }
        dispatchAction(selectComponent({ cid: node.modelId }));
        return;
      }
    },

    mouseMove: (event: React.MouseEvent) => {
      const [x, y] = locateMouse(event);
      hit(x, y);

      const node = findNode(x, y);
      if (lastHoveredNode?.id === node?.id) {
        return;
      }
      lastHoveredNode = node;
      drawFocusVisuals();
    },
    mouseOut: () => {
      destroyPopover();
      updateHighlight({ highlightedLink: null, highlightedNode: null });
    },

    contextMenu: (event: React.MouseEvent) => {
      const [x, y] = locateMouse(event);
      const link = findLink(x, y);
      const node = !link ? findNode(x, y) : null;

      const { options, testId } = getContextMenuData({
        event: event.nativeEvent,
        link,
        node,
        viewInstanceId,
        toggleCollapsed,
        isViewpointMode,
      });

      if (!options?.length) {
        return;
      }

      dispatchAction(
        setContextMenuState({
          testId,
          items: options,
          position: { left: event.clientX, top: event.clientY },
        })
      );
    },

    findNode: (event: Pick<MouseEvent, 'clientX' | 'clientY'>) => {
      const [x, y] = locateMouse(event);
      return findNode(x, y);
    },
    getLastClickedNode: () => lastClickedNode,
    cleanup: () => {
      dead = true;
      clearSubscriptions(subscriptions);
      dimCanvas.remove();
      destroyPopover();
      clearFontMetricsCache();
    },
    redrawImmediate,
    getViewTransform: () => viewTransform,
    unsetLayout,
    initialize,
    optimizeLayout: () => {
      if (!rootNode) {
        return;
      }
      ticking = false;
      nextLayout = 'packLayout';
      fitOnNextLayoutComplete();
      schedule(rootNode, true);
    },
    isOptimizeLayoutAvailable: () => currentLayout === 'forceLayout',
  };
};
