import { useRef } from 'react';
import styled from 'styled-components';
import { ViewIds } from '@ardoq/api-types';
import { viewModel$ } from './viewModel$';
import { dispatchAction, connectInstance } from '@ardoq/rxbeach';
import { defaultLegendOnClick } from 'viewSettings/getRightMenuConfig';
import { getExportsForSvgView } from '@ardoq/export';
import BubbleChart from './BubbleChart';
import { BubbleChartViewProperties } from './types';
import { chartSetWindow } from 'utils/charting/actions';
import { selectComponent } from 'streams/components/ComponentActions';
import { BubbleChartViewContainer } from './atoms';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { componentInterface } from '@ardoq/component-interface';
import { getSharedExportFunctions } from 'tabview/getSharedExportFunctions';
import BubbleChartSettingsBar from './BubbleChartSettingsBar';
import EmptyState from './EmptyState';
import type { CidArgs } from '@ardoq/common-helpers';
import { OBJECT_CONTEXT_MENU_NAME } from '@ardoq/context-menu';

const VIEW_ID = ViewIds.BUBBLE;
const Container = styled.div`
  flex: 1;
  min-height: 100px;
  min-width: 100px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const dispatchSelectComponent = (args: CidArgs) => {
  dispatchAction(selectComponent(args));
};

const BubbleChartView = ({
  viewInstanceId,
  viewSettings,
  numericFields,
  availableIncomingReferenceTypeNames,
  availableOutgoingReferenceTypeNames,
  availableReferenceFields,
  availableReferencedComponentFields,
  isLoadingWorkspace,
  data,
  domain,
  windowCenter,
  windowScale,
  embeddableViewConfig,
  xAxisTitle,
  yAxisTitle,
  hoverState,
  focusState,
}: BubbleChartViewProperties) => {
  const contentPaneRef = useRef<HTMLDivElement>(null);

  // let isLegendActive holds the "active" value of the legend and is temorarely detached from
  // the state due to getFilteredViewSettings$.
  // This value & the viewstate are synchronized as soon an update passes the filter.
  let isLegendActive = viewSettings.isLegendActive ?? false;
  const showLeftSettingsSection =
    !embeddableViewConfig || embeddableViewConfig.showLeftSettingsSection;
  const showRightSettingsSection =
    !embeddableViewConfig || embeddableViewConfig.showRightSettingsSection;

  const isEmptyView =
    !isLoadingWorkspace && (!numericFields.length || !data.length);

  const {
    selectedFieldNameX,
    selectedFieldNameY,
    background,
    showBubblesWithZeroValue,
  } = viewSettings;

  const hasFieldsSelected = Boolean(selectedFieldNameX && selectedFieldNameY);

  const resolvedRadiusFieldName =
    typeof viewSettings.selectedFieldNameRadius === 'string'
      ? viewSettings.selectedFieldNameRadius
      : (viewSettings.selectedFieldNameRadius?.fieldName ?? '');

  return (
    <BubbleChartViewContainer
      className={`tab-pane ${VIEW_ID}Tab active`}
      data-context-menu={OBJECT_CONTEXT_MENU_NAME}
    >
      <BubbleChartSettingsBar
        numericFields={numericFields}
        availableIncomingReferenceTypeNames={
          availableIncomingReferenceTypeNames
        }
        availableOutgoingReferenceTypeNames={
          availableOutgoingReferenceTypeNames
        }
        availableReferenceFields={availableReferenceFields}
        availableReferencedComponentFields={availableReferencedComponentFields}
        showLeftSettingsSection={showLeftSettingsSection}
        showRightSettingsSection={showRightSettingsSection}
        exports={{
          ...getExportsForSvgView({
            container: () => contentPaneRef.current,
            exportedViewMetadata: {
              name: ViewIds.BUBBLE,
            },
            ...getSharedExportFunctions(),
          }),
          isDisabled: isEmptyView,
        }}
        legendOnClick={() => {
          isLegendActive = !isLegendActive;
          defaultLegendOnClick({
            isLegendActive: isLegendActive,
            viewId: VIEW_ID,
          });
        }}
      />
      {isEmptyView ? (
        <EmptyState
          noNumericFields={!numericFields.length}
          hasFieldsSelected={hasFieldsSelected}
          allDescendantsIncluded={viewSettings.includeAllDescendants === 'all'}
        />
      ) : (
        <Container ref={contentPaneRef}>
          <BubbleChart
            data={data}
            background={background}
            domain={domain}
            isLegendActive={viewSettings.isLegendActive}
            windowCenter={windowCenter}
            windowScale={windowScale}
            setWindow={args =>
              dispatchAction(chartSetWindow(args), viewInstanceId)
            }
            xAxisTitle={xAxisTitle}
            yAxisTitle={yAxisTitle}
            selectComponent={dispatchSelectComponent}
            viewInstanceId={viewInstanceId}
            customQuadrantSettings={viewSettings.customQuadrantSettings}
            bubbleAreaScaleFactor={viewSettings.bubbleAreaScaleFactor}
            selectedFieldNameRadius={resolvedRadiusFieldName}
            isComponent={componentInterface.isComponent}
            getRepresentationData={componentInterface.getRepresentationData}
            showBubblesWithZeroValue={
              showBubblesWithZeroValue === undefined || // treat undefined as true: this indicates an unset value from an older user profile or presentation slide. the default value of the setting is true.
              showBubblesWithZeroValue
            }
            hoverState={hoverState}
            focusState={focusState}
          />
        </Container>
      )}
    </BubbleChartViewContainer>
  );
};

const PerformanceTrackedBubbleChart = (props: BubbleChartViewProperties) =>
  WithPerformanceTracking('bubble chart view render', 100, {
    WrappedComponent: BubbleChartView,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: { itemCount: props.data.length },
  });
export default connectInstance(PerformanceTrackedBubbleChart, viewModel$);
