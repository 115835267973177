import { ViewIds } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { getViewpointModeViewModelSourceData$ } from './viewModel/getViewpointModeViewModelSourceData$';
import {
  Timeline,
  timelineViewSettingsOperations,
  connectTimelineComponent,
} from '@ardoq/timeline2024';
import type {
  TimelineProperties,
  TimelineSettingsControlsDependencies,
} from '@ardoq/timeline2024';
import WithPerformanceTracking from 'utils/WithPerformanceTracking';
import { updateViewProperties } from '@ardoq/view-settings';
import { ViewLegendComponent } from './ViewLegendComponent';
import EmptyState from './EmptyState';
import {
  timelineViewLegend$,
  viewSettingsForRenderingData$,
} from './viewModel/viewSettings$';

const VIEW_ID = ViewIds.TIMELINE;

const PerformanceTrackedTimeline = (props: TimelineProperties) =>
  WithPerformanceTracking('timeline view render', 1000, {
    WrappedComponent: Timeline,
    wrappedProps: props,
    viewId: VIEW_ID,
    metadata: {
      itemCount: props.timelineViewRenderingData.renderedItemLabels.length,
    },
  });

const updateViewSettingsDataForViewpointMode = (
  settingsData: TimelineSettingsControlsDependencies
) => {
  dispatchAction(
    updateViewProperties({
      viewId: VIEW_ID,
      viewProperties: settingsData,
    })
  );
};
const viewpointModeSettingsControls = {
  getSettingsControls:
    timelineViewSettingsOperations.getSettingsControlsForViewpointMode,
  updateSettingsControls: updateViewSettingsDataForViewpointMode,
};

const ConnectedTimeline = connectTimelineComponent(
  getViewpointModeViewModelSourceData$,
  viewSettingsForRenderingData$,
  timelineViewLegend$,
  PerformanceTrackedTimeline,
  viewpointModeSettingsControls
);

const ViewpointModeTimelineView = () => {
  /**
   * These components depend on the rendering data state that is internal to package,
   * but they themselves are located in ardoq-front. So this ugly hack is a way to
   * move forward for now.
   */
  const jsxComponentsAsProperties: Pick<
    TimelineProperties,
    'EmptyStateComponent' | 'ViewLegendComponent'
  > = {
    ViewLegendComponent,
    EmptyStateComponent: EmptyState,
  };
  return <ConnectedTimeline {...jsxComponentsAsProperties} />;
};

export default ViewpointModeTimelineView;
