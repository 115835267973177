import { RawGraphItem } from '@ardoq/graph';
import { HierarchicalTreemapNode } from '../types';
import { componentInterface } from '@ardoq/component-interface';
import { GET_CSS_BACKGROUND_OPTIONS } from 'tabview/consts';

type ReduceNodesState = { nodes: HierarchicalTreemapNode[] };

const itemLabel = ({ modelId, label }: RawGraphItem) =>
  label ??
  (modelId ? (componentInterface.getDisplayLabel(modelId) ?? null) : null);

const itemCss = ({ modelId }: RawGraphItem) =>
  modelId
    ? componentInterface.getCssClassNames(modelId, GET_CSS_BACKGROUND_OPTIONS)
    : null;

const reduceNodes = (
  state: ReduceNodesState,
  current: RawGraphItem
): ReduceNodesState => {
  const node: HierarchicalTreemapNode = {
    id: current.id,
    modelId: current.modelId ?? null,
    label: itemLabel(current),
    nodes: current.children?.reduce(reduceNodes, { nodes: [] }).nodes ?? null,
    className: itemCss(current),
  };
  state.nodes.push(node);
  return state;
};

export default reduceNodes;
